import React from 'react';
import $ from "jquery";
import _ from "lodash";
import Swal from 'sweetalert2'
import en from './translations/en.json';

String.prototype.replaceAll = function (search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

export class CommonHelper {

    static empty(data) {
        if (data !== undefined && data !== null) {
            data = data + "";
            data = data.trim();
            if (data.length > 0) {
                return false;
            }
        }
        return true;
    }

    static replaceAll(search, replacement, target) {
        return target.replace(new RegExp(search, 'g'), replacement);
    };

    static showCardLast4Digit(cardNumber, hideString = "*") {
        cardNumber += "";
        let html = "";
        let ca = cardNumber.split(/(.{4})/).filter(x => x.length == 4);
        ca.map((segment, i) => {
            if (i > 0) {
                html += "  ";
            }
            if (i == 0 || ca.length - 1 == i) {
                html += segment;
            } else {
                html += hideString.repeat(4);
            }
        });
        // let cardNL = cardNumber.length;
        // let last4Index = cardNL - 4;
        // if (cardNL > 0 && last4Index > 0) {
        //     let last4 = cardNumber.substr(last4Index);
        //     html += hideString.repeat(last4Index);
        //     html += last4;
        // }
        return html;
    }

    /**
     * get countries json array
     * @return {*[]}
     */
    static countries() {
        return [{
            "name": "Afghanistan",
            "countryCode": "AF",
            "currencySymbol": "Af",
            "currencyCode": "AFN",
            "currencyName": "Afghanistan Afghani"
        }, {
            "name": "Albania",
            "countryCode": "AL",
            "currencySymbol": "Lek",
            "currencyCode": "ALL",
            "currencyName": "Albania Lek"
        }, {
            "name": "Algeria",
            "countryCode": "DZ",
            "currencySymbol": "دج",
            "currencyCode": "DZD",
            "currencyName": "Algerian dinar"
        }, {
            "name": "American Samoa",
            "countryCode": "AS",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Angola",
            "countryCode": "AO",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Anguilla",
            "countryCode": "AI",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Antartica",
            "countryCode": "AQ",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Antigua and Barbuda",
            "countryCode": "AG",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Argentina",
            "countryCode": "AR",
            "currencySymbol": "$",
            "currencyCode": "ARS",
            "currencyName": "Argentina Peso"
        }, {
            "name": "Armenia",
            "countryCode": "AM",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Aruba",
            "countryCode": "AW",
            "currencySymbol": "\u0192",
            "currencyCode": "AWG",
            "currencyName": "Aruba Guilder"
        }, {
            "name": "Ashmore and Cartier Island",
            "countryCode": "AU",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Australia",
            "countryCode": "AU",
            "currencySymbol": "$",
            "currencyCode": "AUD",
            "currencyName": "Australia Dollar"
        }, {
            "name": "Austria",
            "countryCode": "AT",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Azerbaijan",
            "countryCode": "AZ",
            "currencySymbol": "\u043c\u0430\u043d",
            "currencyCode": "AZN",
            "currencyName": "Azerbaijan New Manat"
        }, {
            "name": "Bahamas",
            "countryCode": "BS",
            "currencySymbol": "$",
            "currencyCode": "BSD",
            "currencyName": "Bahamas Dollar"
        }, {
            "name": "Bahrain",
            "countryCode": "BH",
            "currencySymbol": ".د.ب",
            "currencyCode": "BHD",
            "currencyName": "Bahraini dinar"
        }, {
            "name": "Bangladesh",
            "countryCode": "BD",
            "currencySymbol": "\u09f3",
            "currencyCode": "BDT",
            "currencyName": "Bangladeshi taka"
        }, {
            "name": "Barbados",
            "countryCode": "BB",
            "currencySymbol": "$",
            "currencyCode": "BBD",
            "currencyName": "Barbados Dollar"
        }, {
            "name": "Belarus",
            "countryCode": "BY",
            "currencySymbol": "p.",
            "currencyCode": "BYR",
            "currencyName": "Belarus Ruble"
        }, {
            "name": "Belgium",
            "countryCode": "BE",
            "currencySymbol": "€",
            "currencyCode": "EUR",
            "currencyName": "Euro"
        }, {
            "name": "Belize",
            "countryCode": "BZ",
            "currencySymbol": "BZ$",
            "currencyCode": "BZD",
            "currencyName": "Belize Dollar"
        }, {
            "name": "Benin",
            "countryCode": "BJ",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Bermuda",
            "countryCode": "BM",
            "currencySymbol": "$",
            "currencyCode": "BMD",
            "currencyName": "Bermuda Dollar"
        }, {
            "name": "Bhutan",
            "countryCode": "BT",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Bolivia",
            "countryCode": "BO",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Bosnia and Herzegovina",
            "countryCode": "BA",
            "currencySymbol": "KM",
            "currencyCode": "BAM",
            "currencyName": "Bosnia and Herzegovina Convertible Marka"
        }, {
            "name": "Botswana",
            "countryCode": "BW",
            "currencySymbol": "P",
            "currencyCode": "BWP",
            "currencyName": "Botswana Pula"
        }, {
            "name": "Brazil",
            "countryCode": "BR",
            "currencySymbol": "R$",
            "currencyCode": "BRL",
            "currencyName": "Brazil Real"
        }, {
            "name": "British Virgin Islands",
            "countryCode": "VG",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Brunei",
            "countryCode": "BN",
            "currencySymbol": "$",
            "currencyCode": "BND",
            "currencyName": "Brunei Dollar"
        }, {
            "name": "Bulgaria",
            "countryCode": "BG",
            "currencySymbol": "\u043b\u0432",
            "currencyCode": "BGN",
            "currencyName": "Bulgaria Lev"
        }, {
            "name": "Burkina Faso",
            "countryCode": "BF",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Burma",
            "countryCode": "BU",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Burundi",
            "countryCode": "BI",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Cambodia",
            "countryCode": "KH",
            "currencySymbol": "\u17db",
            "currencyCode": "KHR",
            "currencyName": "Cambodia Riel"
        }, {
            "name": "Cameroon",
            "countryCode": "CM",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Canada",
            "countryCode": "CA",
            "currencySymbol": "$",
            "currencyCode": "CAD",
            "currencyName": "Canada Dollar"
        }, {
            "name": "Cape Verde",
            "countryCode": "CV",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Cayman Islands",
            "countryCode": "KY",
            "currencySymbol": "$",
            "currencyCode": "KYD",
            "currencyName": "Cayman Islands Dollar"
        }, {
            "name": "Central African Republic",
            "countryCode": "CF",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Chad",
            "countryCode": "TD",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Chile",
            "countryCode": "CL",
            "currencySymbol": "$",
            "currencyCode": "CLP",
            "currencyName": "Chile Peso"
        }, {
            "name": "China",
            "countryCode": "CN",
            "currencySymbol": "\u00a5",
            "currencyCode": "CNY",
            "currencyName": "China Yuan Renminbi"
        }, {
            "name": "Christmas Island",
            "countryCode": "CX",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Clipperton Island",
            "countryCode": "CP",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Cocos (Keeling) Islands",
            "countryCode": "CC",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Colombia",
            "countryCode": "CO",
            "currencySymbol": "$",
            "currencyCode": "COP",
            "currencyName": "Colombia Peso"
        }, {
            "name": "Comoros",
            "countryCode": "KM",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Congo, Democratic Republic of the",
            "countryCode": "CD",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Congo, Republic of the",
            "countryCode": "CG",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Cook Islands",
            "countryCode": "CK",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Costa Rica",
            "countryCode": "CR",
            "currencySymbol": "\u20a1",
            "currencyCode": "CRC",
            "currencyName": "Costa Rica Colon"
        }, {
            "name": "Cote d'Ivoire",
            "countryCode": "CI",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Croatia",
            "countryCode": "HR",
            "currencySymbol": "kn",
            "currencyCode": "HRK",
            "currencyName": "Croatia Kuna"
        }, {
            "name": "Cuba",
            "countryCode": "CU",
            "currencySymbol": "\u20cc",
            "currencyCode": "CUP",
            "currencyName": "Cuba Peso"
        }, {
            "name": "Cyprus",
            "countryCode": "CY",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Czeck Republic",
            "countryCode": "CZ",
            "currencySymbol": "K\u010d",
            "currencyCode": "CZK",
            "currencyName": "Czech Republic Koruna"
        }, {
            "name": "Denmark",
            "countryCode": "DK",
            "currencySymbol": "kr",
            "currencyCode": "DKK",
            "currencyName": "Denmark Krone"
        }, {
            "name": "Djibouti",
            "countryCode": "DJ",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Dominica",
            "countryCode": "DM",
            "currencySymbol": "RD$",
            "currencyCode": "DOP",
            "currencyName": "Dominican Republic Peso"
        }, {
            "name": "Dominican Republic",
            "countryCode": "DO",
            "currencySymbol": "RD$",
            "currencyCode": "DOP",
            "currencyName": "Dominican Republic Peso"
        }, {
            "name": "Ecuador",
            "countryCode": "EC",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Egypt",
            "countryCode": "EG",
            "currencySymbol": "\u00a3",
            "currencyCode": "EGP",
            "currencyName": "Egypt Pound"
        }, {
            "name": "El Salvador",
            "countryCode": "SV",
            "currencySymbol": "$",
            "currencyCode": "SVC",
            "currencyName": "El Salvador Colon"
        }, {
            "name": "Equatorial Guinea",
            "countryCode": "GQ",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Eritrea",
            "countryCode": "ER",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Estonia",
            "countryCode": "EE",
            "currencySymbol": "",
            "currencyCode": "EEK",
            "currencyName": "Estonia Kroon"
        }, {
            "name": "Ethiopia",
            "countryCode": "ET",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Europa Island",
            "countryCode": "EU",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Falkland Islands (Malvinas)",
            "countryCode": "FK",
            "currencySymbol": "\u00a3",
            "currencyCode": "FKP",
            "currencyName": "Falkland Islands (Malvinas) Pound"
        }, {
            "name": "Faroe Islands",
            "countryCode": "FO",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Fiji",
            "countryCode": "FJ",
            "currencySymbol": "$",
            "currencyCode": "FJD",
            "currencyName": "Fiji Dollar"
        }, {
            "name": "Finland",
            "countryCode": "FI",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "France",
            "countryCode": "FR",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "French Guiana",
            "countryCode": "GF",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "French Polynesia",
            "countryCode": "PF",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "French Southern and Antarctic Lands",
            "countryCode": "TF",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Gabon",
            "countryCode": "GA",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Gambia, The",
            "countryCode": "GM",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Gaza Strip",
            "countryCode": "PS",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Georgia",
            "countryCode": "GE",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Germany",
            "countryCode": "DE",
            "currencySymbol": "€",
            "currencyCode": "EUR",
            "currencyName": "Euro"
        }, {
            "name": "Ghana",
            "countryCode": "GH",
            "currencySymbol": "",
            "currencyCode": "GHC",
            "currencyName": "Ghana Cedis"
        }, {
            "name": "Gibraltar",
            "countryCode": "GI",
            "currencySymbol": "\u00a3",
            "currencyCode": "GIP",
            "currencyName": "Gibraltar Pound"
        }, {
            "name": "Glorioso Islands",
            "countryCode": "GO",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Greece",
            "countryCode": "GR",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Greenland",
            "countryCode": "GL",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Grenada",
            "countryCode": "GD",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Guadeloupe",
            "countryCode": "GP",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Guam",
            "countryCode": "GU",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Guatemala",
            "countryCode": "GT",
            "currencySymbol": "Q",
            "currencyCode": "GTQ",
            "currencyName": "Guatemala Quetzal"
        }, {
            "name": "Guernsey",
            "countryCode": "GG",
            "currencySymbol": "",
            "currencyCode": "GGP",
            "currencyName": "Guernsey Pound"
        }, {
            "name": "Guinea",
            "countryCode": "GN",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Guinea-Bissau",
            "countryCode": "GW",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Guyana",
            "countryCode": "GY",
            "currencySymbol": "$",
            "currencyCode": "GYD",
            "currencyName": "Guyana Dollar"
        }, {
            "name": "Haiti",
            "countryCode": "HT",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Heard Island and McDonald Islands",
            "countryCode": "HM",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Holy See (Vatican City)",
            "countryCode": "VA",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Honduras",
            "countryCode": "HN",
            "currencySymbol": "L",
            "currencyCode": "HNL",
            "currencyName": "Honduras Lempira"
        }, {
            "name": "Hong Kong",
            "countryCode": "HK",
            "currencySymbol": "$",
            "currencyCode": "HKD",
            "currencyName": "Hong Kong Dollar"
        }, {
            "name": "Howland Island",
            "countryCode": "UM",
            "currencySymbol": "",
            "currencyCode": "",
            "currencyName": ""
        }, {
            "name": "Hungary",
            "countryCode": "HU",
            "currencySymbol": "Ft",
            "currencyCode": "HUF",
            "currencyName": "Hungary Forint"
        }, {
            "name": "Iceland",
            "countryCode": "IS",
            "currencySymbol": "kr",
            "currencyCode": "ISK",
            "currencyName": "Iceland Krona"
        }, {
            "name": "India",
            "countryCode": "IN",
            "currencySymbol": "\u20b9",
            "currencyCode": "INR",
            "currencyName": "India Rupee"
        }, {
            "name": "Indonesia",
            "countryCode": "ID",
            "currencySymbol": "Rp",
            "currencyCode": "IDR",
            "currencyName": "Indonesia Rupiah"
        },
            //     {
            //     "name": "Isle of Man",
            //     "countryCode": "IM",
            //     "currencySymbol": "",
            //     "currencyCode": "IMP",
            //     "currencyName": "Isle of Man Pound"
            // },
            {
                "name": "Iran",
                "countryCode": "IR",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Iraq",
                "countryCode": "IQ",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Ireland",
                "countryCode": "IE",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Ireland, Northern",
                "countryCode": "GB",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Israel",
                "countryCode": "IL",
                "currencySymbol": "\u20aa",
                "currencyCode": "ILS",
                "currencyName": "Israel Shekel"
            }, {
                "name": "Italy",
                "countryCode": "IT",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Jamaica",
                "countryCode": "JM",
                "currencySymbol": "J$",
                "currencyCode": "JMD",
                "currencyName": "Jamaica Dollar"
            }, {
                "name": "Jan Mayen",
                "countryCode": "SJ",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Japan",
                "countryCode": "JP",
                "currencySymbol": "\u00a5",
                "currencyCode": "JPY",
                "currencyName": "Japan Yen"
            }, {
                "name": "Jarvis Island",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Jersey",
                "countryCode": "JE",
                "currencySymbol": "\u00a3",
                "currencyCode": "JEP",
                "currencyName": "Jersey Pound"
            }, {
                "name": "Johnston Atoll",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Jordan",
                "countryCode": "JO",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Juan de Nova Island",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Kazakhstan",
                "countryCode": "KZ",
                "currencySymbol": "\u043b\u0432",
                "currencyCode": "KZT",
                "currencyName": "Kazakhstan Tenge"
            }, {
                "name": "Kenya",
                "countryCode": "KE",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Kiribati",
                "countryCode": "KI",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Korea, North",
                "countryCode": "KP",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Korea, South",
                "countryCode": "KR",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Kuwait",
                "countryCode": "KW",
                "currencySymbol": "د.ك",
                "currencyCode": "KWD",
                "currencyName": "Kuwaiti Dinar"
            }, {
                "name": "Kyrgyzstan",
                "countryCode": "KG",
                "currencySymbol": "\u043b\u0432",
                "currencyCode": "KGS",
                "currencyName": "Kyrgyzstan Som"
            }, {
                "name": "Laos",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Latvia",
                "countryCode": "LV",
                "currencySymbol": "Ls",
                "currencyCode": "LVL",
                "currencyName": "Latvia Lat"
            }, {
                "name": "Lebanon",
                "countryCode": "LB",
                "currencySymbol": "\u00a3",
                "currencyCode": "LBP",
                "currencyName": "Lebanon Pound"
            }, {
                "name": "Lesotho",
                "countryCode": "LS",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Liberia",
                "countryCode": "LR",
                "currencySymbol": "$",
                "currencyCode": "LRD",
                "currencyName": "Liberia Dollar"
            }, {
                "name": "Libya",
                "countryCode": "LY",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Liechtenstein",
                "countryCode": "LI",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Lithuania",
                "countryCode": "LT",
                "currencySymbol": "Lt",
                "currencyCode": "LTL",
                "currencyName": "Lithuania Litas"
            }, {
                "name": "Luxembourg",
                "countryCode": "LU",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Macau",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Macedonia, Former Yugoslav Republic of",
                "countryCode": "MK",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Madagascar",
                "countryCode": "MG",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Malawi",
                "countryCode": "MW",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Malaysia",
                "countryCode": "MY",
                "currencySymbol": "RM",
                "currencyCode": "MYR",
                "currencyName": "Malaysia Ringgit"
            }, {
                "name": "Maldives",
                "countryCode": "MV",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Mali",
                "countryCode": "ML",
                "currencySymbol": "S",
                "currencyCode": "SOS",
                "currencyName": "Somalia Shilling"
            }, {
                "name": "Malta",
                "countryCode": "MT",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Man, Isle of",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Marshall Islands",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Martinique",
                "countryCode": "MQ",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Mauritania",
                "countryCode": "MR",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Mauritius",
                "countryCode": "MU",
                "currencySymbol": "\u20a8",
                "currencyCode": "MUR",
                "currencyName": "Mauritius Rupee"
            }, {
                "name": "Mayotte",
                "countryCode": "YT",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Mexico",
                "countryCode": "MX",
                "currencySymbol": "$",
                "currencyCode": "MXN",
                "currencyName": "Mexico Peso"
            }, {
                "name": "Micronesia, Federated States of",
                "countryCode": "FM",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Midway Islands",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Moldova",
                "countryCode": "MD",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Monaco",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Mongolia",
                "countryCode": "MN",
                "currencySymbol": "\u20ae",
                "currencyCode": "MNT",
                "currencyName": "Mongolia Tughrik"
            }, {
                "name": "Montserrat",
                "countryCode": "MC",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Morocco",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Mozambique",
                "countryCode": "MZ",
                "currencySymbol": "MT",
                "currencyCode": "MZN",
                "currencyName": "Mozambique Metical"
            }, {
                "name": "Namibia",
                "countryCode": "NA",
                "currencySymbol": "$",
                "currencyCode": "NAD",
                "currencyName": "Namibia Dollar"
            }, {
                "name": "Nauru",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Nepal",
                "countryCode": "NP",
                "currencySymbol": "\u20a8",
                "currencyCode": "NPR",
                "currencyName": "Nepal Rupee"
            }, {
                "name": "Netherlands",
                "countryCode": "NL",
                "currencySymbol": "\u0192",
                "currencyCode": "ANG",
                "currencyName": "Netherlands Antilles Guilder"
            }, {
                "name": "Netherlands Antilles",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "New Caledonia",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "New Zealand",
                "countryCode": "NZ",
                "currencySymbol": "$",
                "currencyCode": "NZD",
                "currencyName": "New Zealand Dollar"
            }, {
                "name": "Nicaragua",
                "countryCode": "NI",
                "currencySymbol": "C$",
                "currencyCode": "NIO",
                "currencyName": "Nicaragua Cordoba"
            }, {
                "name": "Niger",
                "countryCode": "NE",
                "currencySymbol": "\u20a6",
                "currencyCode": "NGN",
                "currencyName": "Nigeria Naira"
            }, {
                "name": "Nigeria",
                "countryCode": "NG",
                "currencySymbol": "\u20a6",
                "currencyCode": "NGN",
                "currencyName": "Nigeria Naira"
            }, {
                "name": "Niue",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Norfolk Island",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Northern Mariana Islands",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Norway",
                "countryCode": "NO",
                "currencySymbol": "kr",
                "currencyCode": "NOK",
                "currencyName": "Norway Krone"
            }, {
                "name": "Oman",
                "countryCode": "OM",
                "currencySymbol": "\ufdfc",
                "currencyCode": "OMR",
                "currencyName": "Oman Rial"
            }, {
                "name": "Pakistan",
                "countryCode": "PK",
                "currencySymbol": "\u20a8",
                "currencyCode": "PKR",
                "currencyName": "Pakistan Rupee"
            }, {
                "name": "Palau",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Panama",
                "countryCode": "PA",
                "currencySymbol": "B\/.",
                "currencyCode": "PAB",
                "currencyName": "Panama Balboa"
            }, {
                "name": "Papua New Guinea",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Paraguay",
                "countryCode": "PY",
                "currencySymbol": "Gs",
                "currencyCode": "PYG",
                "currencyName": "Paraguay Guarani"
            }, {
                "name": "Peru",
                "countryCode": "PE",
                "currencySymbol": "S\/.",
                "currencyCode": "PEN",
                "currencyName": "Peru Nuevo Sol"
            }, {
                "name": "Philippines",
                "countryCode": "PH",
                "currencySymbol": "\u20b1",
                "currencyCode": "PHP",
                "currencyName": "Philippines Peso"
            }, {
                "name": "Pitcaim Islands",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Poland",
                "countryCode": "PL",
                "currencySymbol": "z\u0142",
                "currencyCode": "PLN",
                "currencyName": "Poland Zloty"
            }, {
                "name": "Portugal",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Puerto Rico",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Qatar",
                "countryCode": "QA",
                "currencySymbol": "\ufdfc",
                "currencyCode": "QAR",
                "currencyName": "Qatar Riyal"
            }, {
                "name": "Reunion",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Romania",
                "countryCode": "RO",
                "currencySymbol": "lei",
                "currencyCode": "RON",
                "currencyName": "Romania New Leu"
            }, {
                "name": "Russia",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Rwanda",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Saint Helena",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Saint Kitts and Nevis",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Saint Lucia",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Saint Pierre and Miquelon",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Saint Vincent and the Grenadines",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Samoa",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "San Marino",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Sao Tome and Principe",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Saudi Arabia",
                "countryCode": "SA",
                "currencySymbol": "\ufdfc",
                "currencyCode": "SAR",
                "currencyName": "Saudi Arabia Riyal"
            }, {
                "name": "Scotland",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Senegal",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            },
            // {
            //     "name": "Serbia",
            //     "countryCode": "RS",
            //     "currencySymbol": "\u0414\u0438\u043d.",
            //     "currencyCode": "RSD",
            //     "currencyName": "Serbia Dinar"
            // },
            {
                "name": "Seychelles",
                "countryCode": "SC",
                "currencySymbol": "\u20a8",
                "currencyCode": "SCR",
                "currencyName": "Seychelles Rupee"
            },
            {
                "name": "Sierra Leone",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Singapore",
                "countryCode": "SG",
                "currencySymbol": "$",
                "currencyCode": "SGD",
                "currencyName": "Singapore Dollar"
            }, {
                "name": "Slovakia",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Slovenia",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Solomon Islands",
                "countryCode": "SB",
                "currencySymbol": "$",
                "currencyCode": "SBD",
                "currencyName": "Solomon Islands Dollar"
            }, {
                "name": "Somalia",
                "countryCode": "SO",
                "currencySymbol": "S",
                "currencyCode": "SOS",
                "currencyName": "Somalia Shilling"
            }, {
                "name": "South Africa",
                "countryCode": "ZA",
                "currencySymbol": "R",
                "currencyCode": "ZAR",
                "currencyName": "South Africa Rand"
            }, {
                "name": "South Georgia and South Sandwich Islands",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Spain",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Spratly Islands",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Sri Lanka",
                "countryCode": "LK",
                "currencySymbol": "\u20a8",
                "currencyCode": "LKR",
                "currencyName": "Sri Lanka Rupee"
            }, {
                "name": "Sudan",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Suriname",
                "countryCode": "SR",
                "currencySymbol": "$",
                "currencyCode": "SRD",
                "currencyName": "Suriname Dollar"
            }, {
                "name": "Svalbard",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Swaziland",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Sweden",
                "countryCode": "SE",
                "currencySymbol": "kr",
                "currencyCode": "SEK",
                "currencyName": "Sweden Krona"
            }, {
                "name": "Switzerland",
                "countryCode": "CH",
                "currencySymbol": "CHF",
                "currencyCode": "CHF",
                "currencyName": "Switzerland Franc"
            }, {
                "name": "Syria",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Taiwan",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Tajikistan",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Tanzania",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Thailand",
                "countryCode": "TH",
                "currencySymbol": "\u0e3f",
                "currencyCode": "THB",
                "currencyName": "Thailand Baht"
            }, {
                "name": "Tobago",
                "countryCode": "TT",
                "currencySymbol": "$",
                "currencyCode": "TTD",
                "currencyName": "Tobago Dollar"
            }, {
                "name": "Toga",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Tokelau",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Tonga",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Trinidad",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Tunisia",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Turkey",
                "countryCode": "TR",
                "currencySymbol": "\u20a4",
                "currencyCode": "TRY",
                "currencyName": "Turkey Lira"
            }, {
                "name": "Turkmenistan",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Tuvalu",
                "countryCode": "TV",
                "currencySymbol": "",
                "currencyCode": "TVD",
                "currencyName": "Tuvalu Dollar"
            }, {
                "name": "Uganda",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Ukraine",
                "countryCode": "UA",
                "currencySymbol": "\u20b4",
                "currencyCode": "UAH",
                "currencyName": "Ukraine Hryvna"
            }, {
                "name": "United Arab Emirates",
                "countryCode": "AE",
                "currencySymbol": "د.إ",
                "currencyCode": "AED",
                "currencyName": "United Arab Emirates Dirham"
            }, {
                "name": "United Kingdom",
                "countryCode": "GB",
                "currencySymbol": "\u00a3",
                "currencyCode": "GBP",
                "currencyName": "United Kingdom Pound"
            }, {
                "name": "Uruguay",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "United States",
                "countryCode": "US",
                "currencySymbol": "$",
                "currencyCode": "USD",
                "currencyName": "United States Dollar"
            }, {
                "name": "Uzbekistan",
                "countryCode": "UZ",
                "currencySymbol": "\u043b\u0432",
                "currencyCode": "UZS",
                "currencyName": "Uzbekistan Som"
            }, {
                "name": "Vanuatu",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Venezuela",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Viet Nam",
                "countryCode": "VN",
                "currencySymbol": "\u20ab",
                "currencyCode": "VND",
                "currencyName": "Viet Nam Dong"
            }, {
                "name": "Virgin Islands",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Wales",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Wallis and Futuna",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "West Bank",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Western Sahara",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Yemen",
                "countryCode": "YE",
                "currencySymbol": "\ufdfc",
                "currencyCode": "YER",
                "currencyName": "Yemen Rial"
            }, {
                "name": "Yugoslavia",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Zambia",
                "countryCode": "",
                "currencySymbol": "",
                "currencyCode": "",
                "currencyName": ""
            }, {
                "name": "Zimbabwe",
                "countryCode": "ZW",
                "currencySymbol": "",
                "currencyCode": "ZWD",
                "currencyName": "Zimbabwe Dollar"
            }];
    }

    static states() {
        let s_a = [];
        s_a[0] = "";
        s_a[1] = "Badakhshan|Badghis|Baghlan|Balkh|Bamian|Farah|Faryab|Ghazni|Ghowr|Helmand|Herat|Jowzjan|Kabol|Kandahar|Kapisa|Konar|Kondoz|Laghman|Lowgar|Nangarhar|Nimruz|Oruzgan|Paktia|Paktika|Parvan|Samangan|Sar-e Pol|Takhar|Vardak|Zabol";
        s_a[2] = "Berat|Bulqize|Delvine|Devoll (Bilisht)|Diber (Peshkopi)|Durres|Elbasan|Fier|Gjirokaster|Gramsh|Has (Krume)|Kavaje|Kolonje (Erseke)|Korce|Kruje|Kucove|Kukes|Kurbin|Lezhe|Librazhd|Lushnje|Malesi e Madhe (Koplik)|Mallakaster (Ballsh)|Mat (Burrel)|Mirdite (Rreshen)|Peqin|Permet|Pogradec|Puke|Sarande|Shkoder|Skrapar (Corovode)|Tepelene|Tirane (Tirana)|Tirane (Tirana)|Tropoje (Bajram Curri)|Vlore";
        s_a[3] = "Adrar|Ain Defla|Ain Temouchent|Alger|Annaba|Batna|Bechar|Bejaia|Biskra|Blida|Bordj Bou Arreridj|Bouira|Boumerdes|Chlef|Constantine|Djelfa|El Bayadh|El Oued|El Tarf|Ghardaia|Guelma|Illizi|Jijel|Khenchela|Laghouat|M'Sila|Mascara|Medea|Mila|Mostaganem|Naama|Oran|Ouargla|Oum el Bouaghi|Relizane|Saida|Setif|Sidi Bel Abbes|Skikda|Souk Ahras|Tamanghasset|Tebessa|Tiaret|Tindouf|Tipaza|Tissemsilt|Tizi Ouzou|Tlemcen";
        s_a[4] = "Eastern|Manu'a|Rose Island|Swains Island|Western";
        s_a[5] = "Andorra la Vella|Bengo|Benguela|Bie|Cabinda|Canillo|Cuando Cubango|Cuanza Norte|Cuanza Sul|Cunene|Encamp|Escaldes-Engordany|Huambo|Huila|La Massana|Luanda|Lunda Norte|Lunda Sul|Malanje|Moxico|Namibe|Ordino|Sant Julia de Loria|Uige|Zaire";
        s_a[6] = "Anguilla";
        s_a[7] = "Antartica";
        s_a[8] = "Barbuda|Redonda|Saint George|Saint John|Saint Mary|Saint Paul|Saint Peter|Saint Philip";
        s_a[9] = "Antartica e Islas del Atlantico Sur|Buenos Aires|Buenos Aires Capital Federal|Catamarca|Chaco|Chubut|Cordoba|Corrientes|Entre Rios|Formosa|Jujuy|La Pampa|La Rioja|Mendoza|Misiones|Neuquen|Rio Negro|Salta|San Juan|San Luis|Santa Cruz|Santa Fe|Santiago del Estero|Tierra del Fuego|Tucuman";
        s_a[10] = "Aragatsotn|Ararat|Armavir|Geghark'unik'|Kotayk'|Lorri|Shirak|Syunik'|Tavush|Vayots' Dzor|Yerevan";
        s_a[11] = "Aruba";
        s_a[12] = "Ashmore and Cartier Island";
        s_a[13] = "Australian Capital Territory|New South Wales|Northern Territory|Queensland|South Australia|Tasmania|Victoria|Western Australia";
        s_a[14] = "Burgenland|Kaernten|Niederoesterreich|Oberoesterreich|Salzburg|Steiermark|Tirol|Vorarlberg|Wien";
        s_a[15] = "Abseron Rayonu|Agcabadi Rayonu|Agdam Rayonu|Agdas Rayonu|Agstafa Rayonu|Agsu Rayonu|Ali Bayramli Sahari|Astara Rayonu|Baki Sahari|Balakan Rayonu|Barda Rayonu|Beylaqan Rayonu|Bilasuvar Rayonu|Cabrayil Rayonu|Calilabad Rayonu|Daskasan Rayonu|Davaci Rayonu|Fuzuli Rayonu|Gadabay Rayonu|Ganca Sahari|Goranboy Rayonu|Goycay Rayonu|Haciqabul Rayonu|Imisli Rayonu|Ismayilli Rayonu|Kalbacar Rayonu|Kurdamir Rayonu|Lacin Rayonu|Lankaran Rayonu|Lankaran Sahari|Lerik Rayonu|Masalli Rayonu|Mingacevir Sahari|Naftalan Sahari|Naxcivan Muxtar Respublikasi|Neftcala Rayonu|Oguz Rayonu|Qabala Rayonu|Qax Rayonu|Qazax Rayonu|Qobustan Rayonu|Quba Rayonu|Qubadli Rayonu|Qusar Rayonu|Saatli Rayonu|Sabirabad Rayonu|Saki Rayonu|Saki Sahari|Salyan Rayonu|Samaxi Rayonu|Samkir Rayonu|Samux Rayonu|Siyazan Rayonu|Sumqayit Sahari|Susa Rayonu|Susa Sahari|Tartar Rayonu|Tovuz Rayonu|Ucar Rayonu|Xacmaz Rayonu|Xankandi Sahari|Xanlar Rayonu|Xizi Rayonu|Xocali Rayonu|Xocavand Rayonu|Yardimli Rayonu|Yevlax Rayonu|Yevlax Sahari|Zangilan Rayonu|Zaqatala Rayonu|Zardab Rayonu";
        s_a[16] = "Acklins and Crooked Islands|Bimini|Cat Island|Exuma|Freeport|Fresh Creek|Governor's Harbour|Green Turtle Cay|Harbour Island|High Rock|Inagua|Kemps Bay|Long Island|Marsh Harbour|Mayaguana|New Providence|Nicholls Town and Berry Islands|Ragged Island|Rock Sound|San Salvador and Rum Cay|Sandy Point";
        s_a[17] = "Al Hadd|Al Manamah|Al Mintaqah al Gharbiyah|Al Mintaqah al Wusta|Al Mintaqah ash Shamaliyah|Al Muharraq|Ar Rifa' wa al Mintaqah al Janubiyah|Jidd Hafs|Juzur Hawar|Madinat 'Isa|Madinat Hamad|Sitrah";
        s_a[18] = "Barguna|Barisal|Bhola|Jhalokati|Patuakhali|Pirojpur|Bandarban|Brahmanbaria|Chandpur|Chittagong|Comilla|Cox's Bazar|Feni|Khagrachari|Lakshmipur|Noakhali|Rangamati|Dhaka|Faridpur|Gazipur|Gopalganj|Jamalpur|Kishoreganj|Madaripur|Manikganj|Munshiganj|Mymensingh|Narayanganj|Narsingdi|Netrokona|Rajbari|Shariatpur|Sherpur|Tangail|Bagerhat|Chuadanga|Jessore|Jhenaidah|Khulna|Kushtia|Magura|Meherpur|Narail|Satkhira|Bogra|Dinajpur|Gaibandha|Jaipurhat|Kurigram|Lalmonirhat|Naogaon|Natore|Nawabganj|Nilphamari|Pabna|Panchagarh|Rajshahi|Rangpur|Sirajganj|Thakurgaon|Habiganj|Maulvi bazar|Sunamganj|Sylhet";
        s_a[19] = "Bridgetown|Christ Church|Saint Andrew|Saint George|Saint James|Saint John|Saint Joseph|Saint Lucy|Saint Michael|Saint Peter|Saint Philip|Saint Thomas";
        s_a[20] = "Brestskaya (Brest)|Homyel'skaya (Homyel')|Horad Minsk|Hrodzyenskaya (Hrodna)|Mahilyowskaya (Mahilyow)|Minskaya|Vitsyebskaya (Vitsyebsk)";
        s_a[21] = "Antwerpen|Brabant Wallon|Brussels Capitol Region|Hainaut|Liege|Limburg|Luxembourg|Namur|Oost-Vlaanderen|Vlaams Brabant|West-Vlaanderen";
        s_a[22] = "Belize|Cayo|Corozal|Orange Walk|Stann Creek|Toledo";
        s_a[23] = "Alibori|Atakora|Atlantique|Borgou|Collines|Couffo|Donga|Littoral|Mono|Oueme|Plateau|Zou";
        s_a[24] = "Devonshire|Hamilton|Hamilton|Paget|Pembroke|Saint George|Saint Georges|Sandys|Smiths|Southampton|Warwick";
        s_a[25] = "Bumthang|Chhukha|Chirang|Daga|Geylegphug|Ha|Lhuntshi|Mongar|Paro|Pemagatsel|Punakha|Samchi|Samdrup Jongkhar|Shemgang|Tashigang|Thimphu|Tongsa|Wangdi Phodrang";
        s_a[26] = "Beni|Chuquisaca|Cochabamba|La Paz|Oruro|Pando|Potosi|Santa Cruz|Tarija";
        s_a[27] = "Federation of Bosnia and Herzegovina|Republika Srpska";
        s_a[28] = "Central|Chobe|Francistown|Gaborone|Ghanzi|Kgalagadi|Kgatleng|Kweneng|Lobatse|Ngamiland|North-East|Selebi-Pikwe|South-East|Southern";
        s_a[29] = "Acre|Alagoas|Amapa|Amazonas|Bahia|Ceara|Distrito Federal|Espirito Santo|Goias|Maranhao|Mato Grosso|Mato Grosso do Sul|Minas Gerais|Para|Paraiba|Parana|Pernambuco|Piaui|Rio de Janeiro|Rio Grande do Norte|Rio Grande do Sul|Rondonia|Roraima|Santa Catarina|Sao Paulo|Sergipe|Tocantins";
        s_a[30] = "Anegada|Jost Van Dyke|Tortola|Virgin Gorda";
        s_a[31] = "Belait|Brunei and Muara|Temburong|Tutong";
        s_a[32] = "Blagoevgrad|Burgas|Dobrich|Gabrovo|Khaskovo|Kurdzhali|Kyustendil|Lovech|Montana|Pazardzhik|Pernik|Pleven|Plovdiv|Razgrad|Ruse|Shumen|Silistra|Sliven|Smolyan|Sofiya|Sofiya-Grad|Stara Zagora|Turgovishte|Varna|Veliko Turnovo|Vidin|Vratsa|Yambol";
        s_a[33] = "Bale|Bam|Banwa|Bazega|Bougouriba|Boulgou|Boulkiemde|Comoe|Ganzourgou|Gnagna|Gourma|Houet|Ioba|Kadiogo|Kenedougou|Komandjari|Kompienga|Kossi|Koupelogo|Kouritenga|Kourweogo|Leraba|Loroum|Mouhoun|Nahouri|Namentenga|Naumbiel|Nayala|Oubritenga|Oudalan|Passore|Poni|Samentenga|Sanguie|Seno|Sissili|Soum|Sourou|Tapoa|Tuy|Yagha|Yatenga|Ziro|Zondomo|Zoundweogo";
        s_a[34] = "Ayeyarwady|Bago|Chin State|Kachin State|Kayah State|Kayin State|Magway|Mandalay|Mon State|Rakhine State|Sagaing|Shan State|Tanintharyi|Yangon";
        s_a[35] = "Bubanza|Bujumbura|Bururi|Cankuzo|Cibitoke|Gitega|Karuzi|Kayanza|Kirundo|Makamba|Muramvya|Muyinga|Mwaro|Ngozi|Rutana|Ruyigi";
        s_a[36] = "Banteay Mean Cheay|Batdambang|Kampong Cham|Kampong Chhnang|Kampong Spoe|Kampong Thum|Kampot|Kandal|Kaoh Kong|Keb|Kracheh|Mondol Kiri|Otdar Mean Cheay|Pailin|Phnum Penh|Pouthisat|Preah Seihanu (Sihanoukville)|Preah Vihear|Prey Veng|Rotanah Kiri|Siem Reab|Stoeng Treng|Svay Rieng|Takev";
        s_a[37] = "Adamaoua|Centre|Est|Extreme-Nord|Littoral|Nord|Nord-Ouest|Ouest|Sud|Sud-Ouest";
        s_a[38] = "Alberta|British Columbia|Manitoba|New Brunswick|Newfoundland|Northwest Territories|Nova Scotia|Nunavut|Ontario|Prince Edward Island|Quebec|Saskatchewan|Yukon Territory";
        s_a[39] = "Boa Vista|Brava|Maio|Mosteiros|Paul|Porto Novo|Praia|Ribeira Grande|Sal|Santa Catarina|Santa Cruz|Sao Domingos|Sao Filipe|Sao Nicolau|Sao Vicente|Tarrafal";
        s_a[40] = "Creek|Eastern|Midland|South Town|Spot Bay|Stake Bay|West End|Western";
        s_a[41] = "Bamingui-Bangoran|Bangui|Basse-Kotto|Gribingui|Haut-Mbomou|Haute-Kotto|Haute-Sangha|Kemo-Gribingui|Lobaye|Mbomou|Nana-Mambere|Ombella-Mpoko|Ouaka|Ouham|Ouham-Pende|Sangha|Vakaga";
        s_a[42] = "Batha|Biltine|Borkou-Ennedi-Tibesti|Chari-Baguirmi|Guera|Kanem|Lac|Logone Occidental|Logone Oriental|Mayo-Kebbi|Moyen-Chari|Ouaddai|Salamat|Tandjile";
        s_a[43] = "Aisen del General Carlos Ibanez del Campo|Antofagasta|Araucania|Atacama|Bio-Bio|Coquimbo|Libertador General Bernardo O'Higgins|Los Lagos|Magallanes y de la Antartica Chilena|Maule|Region Metropolitana (Santiago)|Tarapaca|Valparaiso";
        s_a[44] = "Anhui|Beijing|Chongqing|Fujian|Gansu|Guangdong|Guangxi|Guizhou|Hainan|Hebei|Heilongjiang|Henan|Hubei|Hunan|Jiangsu|Jiangxi|Jilin|Liaoning|Nei Mongol|Ningxia|Qinghai|Shaanxi|Shandong|Shanghai|Shanxi|Sichuan|Tianjin|Xinjiang|Xizang (Tibet)|Yunnan|Zhejiang";
        s_a[45] = "Christmas Island";
        s_a[46] = "Clipperton Island";
        s_a[47] = "Direction Island|Home Island|Horsburgh Island|North Keeling Island|South Island|West Island";
        s_a[48] = "Amazonas|Antioquia|Arauca|Atlantico|Bolivar|Boyaca|Caldas|Caqueta|Casanare|Cauca|Cesar|Choco|Cordoba|Cundinamarca|Distrito Capital de Santa Fe de Bogota|Guainia|Guaviare|Huila|La Guajira|Magdalena|Meta|Narino|Norte de Santander|Putumayo|Quindio|Risaralda|San Andres y Providencia|Santander|Sucre|Tolima|Valle del Cauca|Vaupes|Vichada";
// <!-- -->
        s_a[49] = "Anjouan (Nzwani)|Domoni|Fomboni|Grande Comore (Njazidja)|Moheli (Mwali)|Moroni|Moutsamoudou";
        s_a[50] = "Bandundu|Bas-Congo|Equateur|Kasai-Occidental|Kasai-Oriental|Katanga|Kinshasa|Maniema|Nord-Kivu|Orientale|Sud-Kivu";
        s_a[51] = "Bouenza|Brazzaville|Cuvette|Kouilou|Lekoumou|Likouala|Niari|Plateaux|Pool|Sangha";
        s_a[52] = "Aitutaki|Atiu|Avarua|Mangaia|Manihiki|Manuae|Mauke|Mitiaro|Nassau Island|Palmerston|Penrhyn|Pukapuka|Rakahanga|Rarotonga|Suwarrow|Takutea";
        s_a[53] = "Alajuela|Cartago|Guanacaste|Heredia|Limon|Puntarenas|San Jose";
        s_a[54] = "Abengourou|Abidjan|Aboisso|Adiake'|Adzope|Agboville|Agnibilekrou|Ale'pe'|Bangolo|Beoumi|Biankouma|Bocanda|Bondoukou|Bongouanou|Bouafle|Bouake|Bouna|Boundiali|Dabakala|Dabon|Daloa|Danane|Daoukro|Dimbokro|Divo|Duekoue|Ferkessedougou|Gagnoa|Grand Bassam|Grand-Lahou|Guiglo|Issia|Jacqueville|Katiola|Korhogo|Lakota|Man|Mankono|Mbahiakro|Odienne|Oume|Sakassou|San-Pedro|Sassandra|Seguela|Sinfra|Soubre|Tabou|Tanda|Tiassale|Tiebissou|Tingrela|Touba|Toulepleu|Toumodi|Vavoua|Yamoussoukro|Zuenoula";
        s_a[55] = "Bjelovarsko-Bilogorska Zupanija|Brodsko-Posavska Zupanija|Dubrovacko-Neretvanska Zupanija|Istarska Zupanija|Karlovacka Zupanija|Koprivnicko-Krizevacka Zupanija|Krapinsko-Zagorska Zupanija|Licko-Senjska Zupanija|Medimurska Zupanija|Osjecko-Baranjska Zupanija|Pozesko-Slavonska Zupanija|Primorsko-Goranska Zupanija|Sibensko-Kninska Zupanija|Sisacko-Moslavacka Zupanija|Splitsko-Dalmatinska Zupanija|Varazdinska Zupanija|Viroviticko-Podravska Zupanija|Vukovarsko-Srijemska Zupanija|Zadarska Zupanija|Zagreb|Zagrebacka Zupanija";
        s_a[56] = "Camaguey|Ciego de Avila|Cienfuegos|Ciudad de La Habana|Granma|Guantanamo|Holguin|Isla de la Juventud|La Habana|Las Tunas|Matanzas|Pinar del Rio|Sancti Spiritus|Santiago de Cuba|Villa Clara";
        s_a[57] = "Famagusta|Kyrenia|Larnaca|Limassol|Nicosia|Paphos";
        s_a[58] = "Brnensky|Budejovicky|Jihlavsky|Karlovarsky|Kralovehradecky|Liberecky|Olomoucky|Ostravsky|Pardubicky|Plzensky|Praha|Stredocesky|Ustecky|Zlinsky";
        s_a[59] = "Arhus|Bornholm|Fredericksberg|Frederiksborg|Fyn|Kobenhavn|Kobenhavns|Nordjylland|Ribe|Ringkobing|Roskilde|Sonderjylland|Storstrom|Vejle|Vestsjalland|Viborg";
        s_a[60] = "'Ali Sabih|Dikhil|Djibouti|Obock|Tadjoura";
        s_a[61] = "Saint Andrew|Saint David|Saint George|Saint John|Saint Joseph|Saint Luke|Saint Mark|Saint Patrick|Saint Paul|Saint Peter";
        s_a[62] = "Azua|Baoruco|Barahona|Dajabon|Distrito Nacional|Duarte|El Seibo|Elias Pina|Espaillat|Hato Mayor|Independencia|La Altagracia|La Romana|La Vega|Maria Trinidad Sanchez|Monsenor Nouel|Monte Cristi|Monte Plata|Pedernales|Peravia|Puerto Plata|Salcedo|Samana|San Cristobal|San Juan|San Pedro de Macoris|Sanchez Ramirez|Santiago|Santiago Rodriguez|Valverde";
// <!-- -->
        s_a[63] = "Azuay|Bolivar|Canar|Carchi|Chimborazo|Cotopaxi|El Oro|Esmeraldas|Galapagos|Guayas|Imbabura|Loja|Los Rios|Manabi|Morona-Santiago|Napo|Orellana|Pastaza|Pichincha|Sucumbios|Tungurahua|Zamora-Chinchipe";
        s_a[64] = "Ad Daqahliyah|Al Bahr al Ahmar|Al Buhayrah|Al Fayyum|Al Gharbiyah|Al Iskandariyah|Al Isma'iliyah|Al Jizah|Al Minufiyah|Al Minya|Al Qahirah|Al Qalyubiyah|Al Wadi al Jadid|As Suways|Ash Sharqiyah|Aswan|Asyut|Bani Suwayf|Bur Sa'id|Dumyat|Janub Sina'|Kafr ash Shaykh|Matruh|Qina|Shamal Sina'|Suhaj";
        s_a[65] = "Ahuachapan|Cabanas|Chalatenango|Cuscatlan|La Libertad|La Paz|La Union|Morazan|San Miguel|San Salvador|San Vicente|Santa Ana|Sonsonate|Usulutan";
        s_a[66] = "Annobon|Bioko Norte|Bioko Sur|Centro Sur|Kie-Ntem|Litoral|Wele-Nzas";
        s_a[67] = "Akale Guzay|Barka|Denkel|Hamasen|Sahil|Semhar|Senhit|Seraye";
        s_a[68] = "Harjumaa (Tallinn)|Hiiumaa (Kardla)|Ida-Virumaa (Johvi)|Jarvamaa (Paide)|Jogevamaa (Jogeva)|Laane-Virumaa (Rakvere)|Laanemaa (Haapsalu)|Parnumaa (Parnu)|Polvamaa (Polva)|Raplamaa (Rapla)|Saaremaa (Kuessaare)|Tartumaa (Tartu)|Valgamaa (Valga)|Viljandimaa (Viljandi)|Vorumaa (Voru)"
        s_a[69] = "Adis Abeba (Addis Ababa)|Afar|Amara|Dire Dawa|Gambela Hizboch|Hareri Hizb|Oromiya|Sumale|Tigray|YeDebub Biheroch Bihereseboch na Hizboch";
        s_a[70] = "Europa Island";
        s_a[71] = "Falkland Islands (Islas Malvinas)"
        s_a[72] = "Bordoy|Eysturoy|Mykines|Sandoy|Skuvoy|Streymoy|Suduroy|Tvoroyri|Vagar";
        s_a[73] = "Central|Eastern|Northern|Rotuma|Western";
        s_a[74] = "Aland|Etela-Suomen Laani|Ita-Suomen Laani|Lansi-Suomen Laani|Lappi|Oulun Laani";
        s_a[75] = "Alsace|Aquitaine|Auvergne|Basse-Normandie|Bourgogne|Bretagne|Centre|Champagne-Ardenne|Corse|Franche-Comte|Haute-Normandie|Ile-de-France|Languedoc-Roussillon|Limousin|Lorraine|Midi-Pyrenees|Nord-Pas-de-Calais|Pays de la Loire|Picardie|Poitou-Charentes|Provence-Alpes-Cote d'Azur|Rhone-Alpes";
        s_a[76] = "French Guiana";
        s_a[77] = "Archipel des Marquises|Archipel des Tuamotu|Archipel des Tubuai|Iles du Vent|Iles Sous-le-Vent";
        s_a[78] = "Adelie Land|Ile Crozet|Iles Kerguelen|Iles Saint-Paul et Amsterdam";
        s_a[79] = "Estuaire|Haut-Ogooue|Moyen-Ogooue|Ngounie|Nyanga|Ogooue-Ivindo|Ogooue-Lolo|Ogooue-Maritime|Woleu-Ntem";
        s_a[80] = "Banjul|Central River|Lower River|North Bank|Upper River|Western";
        s_a[81] = "Gaza Strip";
        s_a[82] = "Abashis|Abkhazia or Ap'khazet'is Avtonomiuri Respublika (Sokhumi)|Adigenis|Ajaria or Acharis Avtonomiuri Respublika (Bat'umi)|Akhalgoris|Akhalk'alak'is|Akhalts'ikhis|Akhmetis|Ambrolauris|Aspindzis|Baghdat'is|Bolnisis|Borjomis|Ch'khorotsqus|Ch'okhatauris|Chiat'ura|Dedop'listsqaros|Dmanisis|Dushet'is|Gardabanis|Gori|Goris|Gurjaanis|Javis|K'arelis|K'ut'aisi|Kaspis|Kharagaulis|Khashuris|Khobis|Khonis|Lagodekhis|Lanch'khut'is|Lentekhis|Marneulis|Martvilis|Mestiis|Mts'khet'is|Ninotsmindis|Onis|Ozurget'is|P'ot'i|Qazbegis|Qvarlis|Rust'avi|Sach'kheris|Sagarejos|Samtrediis|Senakis|Sighnaghis|T'bilisi|T'elavis|T'erjolis|T'et'ritsqaros|T'ianet'is|Tqibuli|Ts'ageris|Tsalenjikhis|Tsalkis|Tsqaltubo|Vanis|Zestap'onis|Zugdidi|Zugdidis";
        s_a[83] = "Baden-Wuerttemberg|Bayern|Berlin|Brandenburg|Bremen|Hamburg|Hessen|Mecklenburg-Vorpommern|Niedersachsen|Nordrhein-Westfalen|Rheinland-Pfalz|Saarland|Sachsen|Sachsen-Anhalt|Schleswig-Holstein|Thueringen";
        s_a[84] = "Ashanti|Brong-Ahafo|Central|Eastern|Greater Accra|Northern|Upper East|Upper West|Volta|Western";
        s_a[85] = "Gibraltar";
        s_a[86] = "Ile du Lys|Ile Glorieuse";
        s_a[87] = "Aitolia kai Akarnania|Akhaia|Argolis|Arkadhia|Arta|Attiki|Ayion Oros (Mt. Athos)|Dhodhekanisos|Drama|Evritania|Evros|Evvoia|Florina|Fokis|Fthiotis|Grevena|Ilia|Imathia|Ioannina|Irakleion|Kardhitsa|Kastoria|Kavala|Kefallinia|Kerkyra|Khalkidhiki|Khania|Khios|Kikladhes|Kilkis|Korinthia|Kozani|Lakonia|Larisa|Lasithi|Lesvos|Levkas|Magnisia|Messinia|Pella|Pieria|Preveza|Rethimni|Rodhopi|Samos|Serrai|Thesprotia|Thessaloniki|Trikala|Voiotia|Xanthi|Zakinthos";
        s_a[88] = "Avannaa (Nordgronland)|Kitaa (Vestgronland)|Tunu (Ostgronland)"
        s_a[89] = "Carriacou and Petit Martinique|Saint Andrew|Saint David|Saint George|Saint John|Saint Mark|Saint Patrick";
        s_a[90] = "Basse-Terre|Grande-Terre|Iles de la Petite Terre|Iles des Saintes|Marie-Galante";
        s_a[91] = "Guam";
        s_a[92] = "Alta Verapaz|Baja Verapaz|Chimaltenango|Chiquimula|El Progreso|Escuintla|Guatemala|Huehuetenango|Izabal|Jalapa|Jutiapa|Peten|Quetzaltenango|Quiche|Retalhuleu|Sacatepequez|San Marcos|Santa Rosa|Solola|Suchitepequez|Totonicapan|Zacapa";
        s_a[93] = "Castel|Forest|St. Andrew|St. Martin|St. Peter Port|St. Pierre du Bois|St. Sampson|St. Saviour|Torteval|Vale";
        s_a[94] = "Beyla|Boffa|Boke|Conakry|Coyah|Dabola|Dalaba|Dinguiraye|Dubreka|Faranah|Forecariah|Fria|Gaoual|Gueckedou|Kankan|Kerouane|Kindia|Kissidougou|Koubia|Koundara|Kouroussa|Labe|Lelouma|Lola|Macenta|Mali|Mamou|Mandiana|Nzerekore|Pita|Siguiri|Telimele|Tougue|Yomou";
        s_a[95] = "Bafata|Biombo|Bissau|Bolama-Bijagos|Cacheu|Gabu|Oio|Quinara|Tombali";
        s_a[96] = "Barima-Waini|Cuyuni-Mazaruni|Demerara-Mahaica|East Berbice-Corentyne|Essequibo Islands-West Demerara|Mahaica-Berbice|Pomeroon-Supenaam|Potaro-Siparuni|Upper Demerara-Berbice|Upper Takutu-Upper Essequibo";
        s_a[97] = "Artibonite|Centre|Grand'Anse|Nord|Nord-Est|Nord-Ouest|Ouest|Sud|Sud-Est";
        s_a[98] = "Heard Island and McDonald Islands";
        s_a[99] = "Holy See (Vatican City)"
        s_a[100] = "Atlantida|Choluteca|Colon|Comayagua|Copan|Cortes|El Paraiso|Francisco Morazan|Gracias a Dios|Intibuca|Islas de la Bahia|La Paz|Lempira|Ocotepeque|Olancho|Santa Barbara|Valle|Yoro";
        s_a[101] = "Hong Kong";
        s_a[102] = "Howland Island";
        s_a[103] = "Bacs-Kiskun|Baranya|Bekes|Bekescsaba|Borsod-Abauj-Zemplen|Budapest|Csongrad|Debrecen|Dunaujvaros|Eger|Fejer|Gyor|Gyor-Moson-Sopron|Hajdu-Bihar|Heves|Hodmezovasarhely|Jasz-Nagykun-Szolnok|Kaposvar|Kecskemet|Komarom-Esztergom|Miskolc|Nagykanizsa|Nograd|Nyiregyhaza|Pecs|Pest|Somogy|Sopron|Szabolcs-Szatmar-Bereg|Szeged|Szekesfehervar|Szolnok|Szombathely|Tatabanya|Tolna|Vas|Veszprem|Veszprem|Zala|Zalaegerszeg";
        s_a[104] = "Akranes|Akureyri|Arnessysla|Austur-Bardhastrandarsysla|Austur-Hunavatnssysla|Austur-Skaftafellssysla|Borgarfjardharsysla|Dalasysla|Eyjafjardharsysla|Gullbringusysla|Hafnarfjordhur|Husavik|Isafjordhur|Keflavik|Kjosarsysla|Kopavogur|Myrasysla|Neskaupstadhur|Nordhur-Isafjardharsysla|Nordhur-Mulasys-la|Nordhur-Thingeyjarsysla|Olafsfjordhur|Rangarvallasysla|Reykjavik|Saudharkrokur|Seydhisfjordhur|Siglufjordhur|Skagafjardharsysla|Snaefellsnes-og Hnappadalssysla|Strandasysla|Sudhur-Mulasysla|Sudhur-Thingeyjarsysla|Vesttmannaeyjar|Vestur-Bardhastrandarsysla|Vestur-Hunavatnssysla|Vestur-Isafjardharsysla|Vestur-Skaftafellssysla";
        s_a[105] = "Andaman and Nicobar Islands|Andhra Pradesh|Arunachal Pradesh|Assam|Bihar|Chandigarh|Chhattisgarh|Dadra and Nagar Haveli|Daman and Diu|Delhi|Goa|Gujarat|Haryana|Himachal Pradesh|Jammu and Kashmir|Jharkhand|Karnataka|Kerala|Lakshadweep|Madhya Pradesh|Maharashtra|Manipur|Meghalaya|Mizoram|Nagaland|Orissa|Pondicherry|Punjab|Rajasthan|Sikkim|Tamil Nadu|Tripura|Uttar Pradesh|Uttaranchal|West Bengal";
        s_a[106] = "Aceh|Bali|Banten|Bengkulu|East Timor|Gorontalo|Irian Jaya|Jakarta Raya|Jambi|Jawa Barat|Jawa Tengah|Jawa Timur|Kalimantan Barat|Kalimantan Selatan|Kalimantan Tengah|Kalimantan Timur|Kepulauan Bangka Belitung|Lampung|Maluku|Maluku Utara|Nusa Tenggara Barat|Nusa Tenggara Timur|Riau|Sulawesi Selatan|Sulawesi Tengah|Sulawesi Tenggara|Sulawesi Utara|Sumatera Barat|Sumatera Selatan|Sumatera Utara|Yogyakarta";
        s_a[107] = "Ardabil|Azarbayjan-e Gharbi|Azarbayjan-e Sharqi|Bushehr|Chahar Mahall va Bakhtiari|Esfahan|Fars|Gilan|Golestan|Hamadan|Hormozgan|Ilam|Kerman|Kermanshah|Khorasan|Khuzestan|Kohgiluyeh va Buyer Ahmad|Kordestan|Lorestan|Markazi|Mazandaran|Qazvin|Qom|Semnan|Sistan va Baluchestan|Tehran|Yazd|Zanjan";
        s_a[108] = "Al Anbar|Al Basrah|Al Muthanna|Al Qadisiyah|An Najaf|Arbil|As Sulaymaniyah|At Ta'mim|Babil|Baghdad|Dahuk|Dhi Qar|Diyala|Karbala'|Maysan|Ninawa|Salah ad Din|Wasit";
        s_a[109] = "Carlow|Cavan|Clare|Cork|Donegal|Dublin|Galway|Kerry|Kildare|Kilkenny|Laois|Leitrim|Limerick|Longford|Louth|Mayo|Meath|Monaghan|Offaly|Roscommon|Sligo|Tipperary|Waterford|Westmeath|Wexford|Wicklow";
        s_a[110] = "Antrim|Ards|Armagh|Ballymena|Ballymoney|Banbridge|Belfast|Carrickfergus|Castlereagh|Coleraine|Cookstown|Craigavon|Derry|Down|Dungannon|Fermanagh|Larne|Limavady|Lisburn|Magherafelt|Moyle|Newry and Mourne|Newtownabbey|North Down|Omagh|Strabane";
        s_a[111] = "Central|Haifa|Jerusalem|Northern|Southern|Tel Aviv";
        s_a[112] = "Abruzzo|Basilicata|Calabria|Campania|Emilia-Romagna|Friuli-Venezia Giulia|Lazio|Liguria|Lombardia|Marche|Molise|Piemonte|Puglia|Sardegna|Sicilia|Toscana|Trentino-Alto Adige|Umbria|Valle d'Aosta|Veneto";
        s_a[113] = "Clarendon|Hanover|Kingston|Manchester|Portland|Saint Andrew|Saint Ann|Saint Catherine|Saint Elizabeth|Saint James|Saint Mary|Saint Thomas|Trelawny|Westmoreland";
        s_a[114] = "Jan Mayen";
        s_a[115] = "Aichi|Akita|Aomori|Chiba|Ehime|Fukui|Fukuoka|Fukushima|Gifu|Gumma|Hiroshima|Hokkaido|Hyogo|Ibaraki|Ishikawa|Iwate|Kagawa|Kagoshima|Kanagawa|Kochi|Kumamoto|Kyoto|Mie|Miyagi|Miyazaki|Nagano|Nagasaki|Nara|Niigata|Oita|Okayama|Okinawa|Osaka|Saga|Saitama|Shiga|Shimane|Shizuoka|Tochigi|Tokushima|Tokyo|Tottori|Toyama|Wakayama|Yamagata|Yamaguchi|Yamanashi";
        s_a[116] = "Jarvis Island";
        s_a[117] = "Jersey";
        s_a[118] = "Johnston Atoll";
        s_a[119] = "'Amman|Ajlun|Al 'Aqabah|Al Balqa'|Al Karak|Al Mafraq|At Tafilah|Az Zarqa'|Irbid|Jarash|Ma'an|Madaba";
        s_a[120] = "Juan de Nova Island";
        s_a[121] = "Almaty|Aqmola|Aqtobe|Astana|Atyrau|Batys Qazaqstan|Bayqongyr|Mangghystau|Ongtustik Qazaqstan|Pavlodar|Qaraghandy|Qostanay|Qyzylorda|Shyghys Qazaqstan|Soltustik Qazaqstan|Zhambyl";
        s_a[122] = "Central|Coast|Eastern|Nairobi Area|North Eastern|Nyanza|Rift Valley|Western";
        s_a[123] = "Abaiang|Abemama|Aranuka|Arorae|Banaba|Banaba|Beru|Butaritari|Central Gilberts|Gilbert Islands|Kanton|Kiritimati|Kuria|Line Islands|Line Islands|Maiana|Makin|Marakei|Nikunau|Nonouti|Northern Gilberts|Onotoa|Phoenix Islands|Southern Gilberts|Tabiteuea|Tabuaeran|Tamana|Tarawa|Tarawa|Teraina";
        s_a[124] = "Chagang-do (Chagang Province)|Hamgyong-bukto (North Hamgyong Province)|Hamgyong-namdo (South Hamgyong Province)|Hwanghae-bukto (North Hwanghae Province)|Hwanghae-namdo (South Hwanghae Province)|Kaesong-si (Kaesong City)|Kangwon-do (Kangwon Province)|Namp'o-si (Namp'o City)|P'yongan-bukto (North P'yongan Province)|P'yongan-namdo (South P'yongan Province)|P'yongyang-si (P'yongyang City)|Yanggang-do (Yanggang Province)"
        s_a[125] = "Ch'ungch'ong-bukto|Ch'ungch'ong-namdo|Cheju-do|Cholla-bukto|Cholla-namdo|Inch'on-gwangyoksi|Kangwon-do|Kwangju-gwangyoksi|Kyonggi-do|Kyongsang-bukto|Kyongsang-namdo|Pusan-gwangyoksi|Soul-t'ukpyolsi|Taegu-gwangyoksi|Taejon-gwangyoksi|Ulsan-gwangyoksi";
        s_a[126] = "Al 'Asimah|Al Ahmadi|Al Farwaniyah|Al Jahra'|Hawalli";
        s_a[127] = "Batken Oblasty|Bishkek Shaary|Chuy Oblasty (Bishkek)|Jalal-Abad Oblasty|Naryn Oblasty|Osh Oblasty|Talas Oblasty|Ysyk-Kol Oblasty (Karakol)"
        s_a[128] = "Attapu|Bokeo|Bolikhamxai|Champasak|Houaphan|Khammouan|Louangnamtha|Louangphabang|Oudomxai|Phongsali|Salavan|Savannakhet|Viangchan|Viangchan|Xaignabouli|Xaisomboun|Xekong|Xiangkhoang";
        s_a[129] = "Aizkraukles Rajons|Aluksnes Rajons|Balvu Rajons|Bauskas Rajons|Cesu Rajons|Daugavpils|Daugavpils Rajons|Dobeles Rajons|Gulbenes Rajons|Jekabpils Rajons|Jelgava|Jelgavas Rajons|Jurmala|Kraslavas Rajons|Kuldigas Rajons|Leipaja|Liepajas Rajons|Limbazu Rajons|Ludzas Rajons|Madonas Rajons|Ogres Rajons|Preilu Rajons|Rezekne|Rezeknes Rajons|Riga|Rigas Rajons|Saldus Rajons|Talsu Rajons|Tukuma Rajons|Valkas Rajons|Valmieras Rajons|Ventspils|Ventspils Rajons";
        s_a[130] = "Beyrouth|Ech Chimal|Ej Jnoub|El Bekaa|Jabal Loubnane";
        s_a[131] = "Berea|Butha-Buthe|Leribe|Mafeteng|Maseru|Mohales Hoek|Mokhotlong|Qacha's Nek|Quthing|Thaba-Tseka";
        s_a[132] = "Bomi|Bong|Grand Bassa|Grand Cape Mount|Grand Gedeh|Grand Kru|Lofa|Margibi|Maryland|Montserrado|Nimba|River Cess|Sinoe";
        s_a[133] = "Ajdabiya|Al 'Aziziyah|Al Fatih|Al Jabal al Akhdar|Al Jufrah|Al Khums|Al Kufrah|An Nuqat al Khams|Ash Shati'|Awbari|Az Zawiyah|Banghazi|Darnah|Ghadamis|Gharyan|Misratah|Murzuq|Sabha|Sawfajjin|Surt|Tarabulus|Tarhunah|Tubruq|Yafran|Zlitan";
        s_a[134] = "Balzers|Eschen|Gamprin|Mauren|Planken|Ruggell|Schaan|Schellenberg|Triesen|Triesenberg|Vaduz";
        s_a[135] = "Akmenes Rajonas|Alytaus Rajonas|Alytus|Anyksciu Rajonas|Birstonas|Birzu Rajonas|Druskininkai|Ignalinos Rajonas|Jonavos Rajonas|Joniskio Rajonas|Jurbarko Rajonas|Kaisiadoriu Rajonas|Kaunas|Kauno Rajonas|Kedainiu Rajonas|Kelmes Rajonas|Klaipeda|Klaipedos Rajonas|Kretingos Rajonas|Kupiskio Rajonas|Lazdiju Rajonas|Marijampole|Marijampoles Rajonas|Mazeikiu Rajonas|Moletu Rajonas|Neringa Pakruojo Rajonas|Palanga|Panevezio Rajonas|Panevezys|Pasvalio Rajonas|Plunges Rajonas|Prienu Rajonas|Radviliskio Rajonas|Raseiniu Rajonas|Rokiskio Rajonas|Sakiu Rajonas|Salcininku Rajonas|Siauliai|Siauliu Rajonas|Silales Rajonas|Silutes Rajonas|Sirvintu Rajonas|Skuodo Rajonas|Svencioniu Rajonas|Taurages Rajonas|Telsiu Rajonas|Traku Rajonas|Ukmerges Rajonas|Utenos Rajonas|Varenos Rajonas|Vilkaviskio Rajonas|Vilniaus Rajonas|Vilnius|Zarasu Rajonas";
        s_a[136] = "Diekirch|Grevenmacher|Luxembourg";
        s_a[137] = "Macau";
        s_a[138] = "Aracinovo|Bac|Belcista|Berovo|Bistrica|Bitola|Blatec|Bogdanci|Bogomila|Bogovinje|Bosilovo|Brvenica|Cair (Skopje)|Capari|Caska|Cegrane|Centar (Skopje)|Centar Zupa|Cesinovo|Cucer-Sandevo|Debar|Delcevo|Delogozdi|Demir Hisar|Demir Kapija|Dobrusevo|Dolna Banjica|Dolneni|Dorce Petrov (Skopje)|Drugovo|Dzepciste|Gazi Baba (Skopje)|Gevgelija|Gostivar|Gradsko|Ilinden|Izvor|Jegunovce|Kamenjane|Karbinci|Karpos (Skopje)|Kavadarci|Kicevo|Kisela Voda (Skopje)|Klecevce|Kocani|Konce|Kondovo|Konopiste|Kosel|Kratovo|Kriva Palanka|Krivogastani|Krusevo|Kuklis|Kukurecani|Kumanovo|Labunista|Lipkovo|Lozovo|Lukovo|Makedonska Kamenica|Makedonski Brod|Mavrovi Anovi|Meseista|Miravci|Mogila|Murtino|Negotino|Negotino-Poloska|Novaci|Novo Selo|Oblesevo|Ohrid|Orasac|Orizari|Oslomej|Pehcevo|Petrovec|Plasnia|Podares|Prilep|Probistip|Radovis|Rankovce|Resen|Rosoman|Rostusa|Samokov|Saraj|Sipkovica|Sopiste|Sopotnika|Srbinovo|Star Dojran|Staravina|Staro Nagoricane|Stip|Struga|Strumica|Studenicani|Suto Orizari (Skopje)|Sveti Nikole|Tearce|Tetovo|Topolcani|Valandovo|Vasilevo|Veles|Velesta|Vevcani|Vinica|Vitoliste|Vranestica|Vrapciste|Vratnica|Vrutok|Zajas|Zelenikovo|Zileno|Zitose|Zletovo|Zrnovci";
        s_a[139] = "Antananarivo|Antsiranana|Fianarantsoa|Mahajanga|Toamasina|Toliara";
        s_a[140] = "Balaka|Blantyre|Chikwawa|Chiradzulu|Chitipa|Dedza|Dowa|Karonga|Kasungu|Likoma|Lilongwe|Machinga (Kasupe)|Mangochi|Mchinji|Mulanje|Mwanza|Mzimba|Nkhata Bay|Nkhotakota|Nsanje|Ntcheu|Ntchisi|Phalombe|Rumphi|Salima|Thyolo|Zomba";
        s_a[141] = "Johor|Kedah|Kelantan|Labuan|Melaka|Negeri Sembilan|Pahang|Perak|Perlis|Pulau Pinang|Sabah|Sarawak|Selangor|Terengganu|Wilayah Persekutuan";
        s_a[142] = "Alifu|Baa|Dhaalu|Faafu|Gaafu Alifu|Gaafu Dhaalu|Gnaviyani|Haa Alifu|Haa Dhaalu|Kaafu|Laamu|Lhaviyani|Maale|Meemu|Noonu|Raa|Seenu|Shaviyani|Thaa|Vaavu";
        s_a[143] = "Gao|Kayes|Kidal|Koulikoro|Mopti|Segou|Sikasso|Tombouctou";
        s_a[144] = "Valletta";
        s_a[145] = "Man, Isle of";
        s_a[146] = "Ailinginae|Ailinglaplap|Ailuk|Arno|Aur|Bikar|Bikini|Bokak|Ebon|Enewetak|Erikub|Jabat|Jaluit|Jemo|Kili|Kwajalein|Lae|Lib|Likiep|Majuro|Maloelap|Mejit|Mili|Namorik|Namu|Rongelap|Rongrik|Toke|Ujae|Ujelang|Utirik|Wotho|Wotje";
        s_a[147] = "Martinique";
        s_a[148] = "Adrar|Assaba|Brakna|Dakhlet Nouadhibou|Gorgol|Guidimaka|Hodh Ech Chargui|Hodh El Gharbi|Inchiri|Nouakchott|Tagant|Tiris Zemmour|Trarza";
        s_a[149] = "Agalega Islands|Black River|Cargados Carajos Shoals|Flacq|Grand Port|Moka|Pamplemousses|Plaines Wilhems|Port Louis|Riviere du Rempart|Rodrigues|Savanne";
        s_a[150] = "Mayotte";
        s_a[151] = "Aguascalientes|Baja California|Baja California Sur|Campeche|Chiapas|Chihuahua|Coahuila de Zaragoza|Colima|Distrito Federal|Durango|Guanajuato|Guerrero|Hidalgo|Jalisco|Mexico|Michoacan de Ocampo|Morelos|Nayarit|Nuevo Leon|Oaxaca|Puebla|Queretaro de Arteaga|Quintana Roo|San Luis Potosi|Sinaloa|Sonora|Tabasco|Tamaulipas|Tlaxcala|Veracruz-Llave|Yucatan|Zacatecas";
        s_a[152] = "Chuuk (Truk)|Kosrae|Pohnpei|Yap";
        s_a[153] = "Midway Islands";
        s_a[154] = "Balti|Cahul|Chisinau|Chisinau|Dubasari|Edinet|Gagauzia|Lapusna|Orhei|Soroca|Tighina|Ungheni";
        s_a[155] = "Fontvieille|La Condamine|Monaco-Ville|Monte-Carlo";
        s_a[156] = "Arhangay|Bayan-Olgiy|Bayanhongor|Bulgan|Darhan|Dornod|Dornogovi|Dundgovi|Dzavhan|Erdenet|Govi-Altay|Hentiy|Hovd|Hovsgol|Omnogovi|Ovorhangay|Selenge|Suhbaatar|Tov|Ulaanbaatar|Uvs";
        s_a[157] = "Saint Anthony|Saint Georges|Saint Peter's";
        s_a[158] = "Agadir|Al Hoceima|Azilal|Ben Slimane|Beni Mellal|Boulemane|Casablanca|Chaouen|El Jadida|El Kelaa des Srarhna|Er Rachidia|Essaouira|Fes|Figuig|Guelmim|Ifrane|Kenitra|Khemisset|Khenifra|Khouribga|Laayoune|Larache|Marrakech|Meknes|Nador|Ouarzazate|Oujda|Rabat-Sale|Safi|Settat|Sidi Kacem|Tan-Tan|Tanger|Taounate|Taroudannt|Tata|Taza|Tetouan|Tiznit";
        s_a[159] = "Cabo Delgado|Gaza|Inhambane|Manica|Maputo|Nampula|Niassa|Sofala|Tete|Zambezia";
        s_a[160] = "Caprivi|Erongo|Hardap|Karas|Khomas|Kunene|Ohangwena|Okavango|Omaheke|Omusati|Oshana|Oshikoto|Otjozondjupa";
        s_a[161] = "Aiwo|Anabar|Anetan|Anibare|Baiti|Boe|Buada|Denigomodu|Ewa|Ijuw|Meneng|Nibok|Uaboe|Yaren";
        s_a[162] = "Bagmati|Bheri|Dhawalagiri|Gandaki|Janakpur|Karnali|Kosi|Lumbini|Mahakali|Mechi|Narayani|Rapti|Sagarmatha|Seti";
        s_a[163] = "Drenthe|Flevoland|Friesland|Gelderland|Groningen|Limburg|Noord-Brabant|Noord-Holland|Overijssel|Utrecht|Zeeland|Zuid-Holland";
        s_a[164] = "Netherlands Antilles";
        s_a[165] = "Iles Loyaute|Nord|Sud";
        s_a[166] = "Akaroa|Amuri|Ashburton|Bay of Islands|Bruce|Buller|Chatham Islands|Cheviot|Clifton|Clutha|Cook|Dannevirke|Egmont|Eketahuna|Ellesmere|Eltham|Eyre|Featherston|Franklin|Golden Bay|Great Barrier Island|Grey|Hauraki Plains|Hawera|Hawke's Bay|Heathcote|Hikurangi|Hobson|Hokianga|Horowhenua|Hurunui|Hutt|Inangahua|Inglewood|Kaikoura|Kairanga|Kiwitea|Lake|Mackenzie|Malvern|Manaia|Manawatu|Mangonui|Maniototo|Marlborough|Masterton|Matamata|Mount Herbert|Ohinemuri|Opotiki|Oroua|Otamatea|Otorohanga|Oxford|Pahiatua|Paparua|Patea|Piako|Pohangina|Raglan|Rangiora|Rangitikei|Rodney|Rotorua|Runanga|Saint Kilda|Silverpeaks|Southland|Stewart Island|Stratford|Strathallan|Taranaki|Taumarunui|Taupo|Tauranga|Thames-Coromandel|Tuapeka|Vincent|Waiapu|Waiheke|Waihemo|Waikato|Waikohu|Waimairi|Waimarino|Waimate|Waimate West|Waimea|Waipa|Waipawa|Waipukurau|Wairarapa South|Wairewa|Wairoa|Waitaki|Waitomo|Waitotara|Wallace|Wanganui|Waverley|Westland|Whakatane|Whangarei|Whangaroa|Woodville";
        s_a[167] = "Atlantico Norte|Atlantico Sur|Boaco|Carazo|Chinandega|Chontales|Esteli|Granada|Jinotega|Leon|Madriz|Managua|Masaya|Matagalpa|Nueva Segovia|Rio San Juan|Rivas";
        s_a[168] = "Agadez|Diffa|Dosso|Maradi|Niamey|Tahoua|Tillaberi|Zinder";
        s_a[169] = "Abia|Abuja Federal Capital Territory|Adamawa|Akwa Ibom|Anambra|Bauchi|Bayelsa|Benue|Borno|Cross River|Delta|Ebonyi|Edo|Ekiti|Enugu|Gombe|Imo|Jigawa|Kaduna|Kano|Katsina|Kebbi|Kogi|Kwara|Lagos|Nassarawa|Niger|Ogun|Ondo|Osun|Oyo|Plateau|Rivers|Sokoto|Taraba|Yobe|Zamfara";
        s_a[170] = "Niue";
        s_a[171] = "Norfolk Island";
        s_a[172] = "Northern Islands|Rota|Saipan|Tinian";
        s_a[173] = "Akershus|Aust-Agder|Buskerud|Finnmark|Hedmark|Hordaland|More og Romsdal|Nord-Trondelag|Nordland|Oppland|Oslo|Ostfold|Rogaland|Sogn og Fjordane|Sor-Trondelag|Telemark|Troms|Vest-Agder|Vestfold";
        s_a[174] = "Ad Dakhiliyah|Al Batinah|Al Wusta|Ash Sharqiyah|Az Zahirah|Masqat|Musandam|Zufar";
        s_a[175] = "Balochistan|Federally Administered Tribal Areas|Islamabad Capital Territory|North-West Frontier Province|Punjab|Sindh";
        s_a[176] = "Aimeliik|Airai|Angaur|Hatobohei|Kayangel|Koror|Melekeok|Ngaraard|Ngarchelong|Ngardmau|Ngatpang|Ngchesar|Ngeremlengui|Ngiwal|Palau Island|Peleliu|Sonsoral|Tobi";
        s_a[177] = "Bocas del Toro|Chiriqui|Cocle|Colon|Darien|Herrera|Los Santos|Panama|San Blas|Veraguas";
        s_a[178] = "Bougainville|Central|Chimbu|East New Britain|East Sepik|Eastern Highlands|Enga|Gulf|Madang|Manus|Milne Bay|Morobe|National Capital|New Ireland|Northern|Sandaun|Southern Highlands|West New Britain|Western|Western Highlands";
        s_a[179] = "Alto Paraguay|Alto Parana|Amambay|Asuncion (city)|Boqueron|Caaguazu|Caazapa|Canindeyu|Central|Concepcion|Cordillera|Guaira|Itapua|Misiones|Neembucu|Paraguari|Presidente Hayes|San Pedro";
        s_a[180] = "Amazonas|Ancash|Apurimac|Arequipa|Ayacucho|Cajamarca|Callao|Cusco|Huancavelica|Huanuco|Ica|Junin|La Libertad|Lambayeque|Lima|Loreto|Madre de Dios|Moquegua|Pasco|Piura|Puno|San Martin|Tacna|Tumbes|Ucayali";
        s_a[181] = "Abra|Agusan del Norte|Agusan del Sur|Aklan|Albay|Angeles|Antique|Aurora|Bacolod|Bago|Baguio|Bais|Basilan|Basilan City|Bataan|Batanes|Batangas|Batangas City|Benguet|Bohol|Bukidnon|Bulacan|Butuan|Cabanatuan|Cadiz|Cagayan|Cagayan de Oro|Calbayog|Caloocan|Camarines Norte|Camarines Sur|Camiguin|Canlaon|Capiz|Catanduanes|Cavite|Cavite City|Cebu|Cebu City|Cotabato|Dagupan|Danao|Dapitan|Davao City Davao|Davao del Sur|Davao Oriental|Dipolog|Dumaguete|Eastern Samar|General Santos|Gingoog|Ifugao|Iligan|Ilocos Norte|Ilocos Sur|Iloilo|Iloilo City|Iriga|Isabela|Kalinga-Apayao|La Carlota|La Union|Laguna|Lanao del Norte|Lanao del Sur|Laoag|Lapu-Lapu|Legaspi|Leyte|Lipa|Lucena|Maguindanao|Mandaue|Manila|Marawi|Marinduque|Masbate|Mindoro Occidental|Mindoro Oriental|Misamis Occidental|Misamis Oriental|Mountain|Naga|Negros Occidental|Negros Oriental|North Cotabato|Northern Samar|Nueva Ecija|Nueva Vizcaya|Olongapo|Ormoc|Oroquieta|Ozamis|Pagadian|Palawan|Palayan|Pampanga|Pangasinan|Pasay|Puerto Princesa|Quezon|Quezon City|Quirino|Rizal|Romblon|Roxas|Samar|San Carlos (in Negros Occidental)|San Carlos (in Pangasinan)|San Jose|San Pablo|Silay|Siquijor|Sorsogon|South Cotabato|Southern Leyte|Sultan Kudarat|Sulu|Surigao|Surigao del Norte|Surigao del Sur|Tacloban|Tagaytay|Tagbilaran|Tangub|Tarlac|Tawitawi|Toledo|Trece Martires|Zambales|Zamboanga|Zamboanga del Norte|Zamboanga del Sur";
        s_a[182] = "Pitcaim Islands";
        s_a[183] = "Dolnoslaskie|Kujawsko-Pomorskie|Lodzkie|Lubelskie|Lubuskie|Malopolskie|Mazowieckie|Opolskie|Podkarpackie|Podlaskie|Pomorskie|Slaskie|Swietokrzyskie|Warminsko-Mazurskie|Wielkopolskie|Zachodniopomorskie";
        s_a[184] = "Acores (Azores)|Aveiro|Beja|Braga|Braganca|Castelo Branco|Coimbra|Evora|Faro|Guarda|Leiria|Lisboa|Madeira|Portalegre|Porto|Santarem|Setubal|Viana do Castelo|Vila Real|Viseu";
        s_a[185] = "Adjuntas|Aguada|Aguadilla|Aguas Buenas|Aibonito|Anasco|Arecibo|Arroyo|Barceloneta|Barranquitas|Bayamon|Cabo Rojo|Caguas|Camuy|Canovanas|Carolina|Catano|Cayey|Ceiba|Ciales|Cidra|Coamo|Comerio|Corozal|Culebra|Dorado|Fajardo|Florida|Guanica|Guayama|Guayanilla|Guaynabo|Gurabo|Hatillo|Hormigueros|Humacao|Isabela|Jayuya|Juana Diaz|Juncos|Lajas|Lares|Las Marias|Las Piedras|Loiza|Luquillo|Manati|Maricao|Maunabo|Mayaguez|Moca|Morovis|Naguabo|Naranjito|Orocovis|Patillas|Penuelas|Ponce|Quebradillas|Rincon|Rio Grande|Sabana Grande|Salinas|San German|San Juan|San Lorenzo|San Sebastian|Santa Isabel|Toa Alta|Toa Baja|Trujillo Alto|Utuado|Vega Alta|Vega Baja|Vieques|Villalba|Yabucoa|Yauco";
        s_a[186] = "Ad Dawhah|Al Ghuwayriyah|Al Jumayliyah|Al Khawr|Al Wakrah|Ar Rayyan|Jarayan al Batinah|Madinat ash Shamal|Umm Salal";
        s_a[187] = "Reunion";
        s_a[188] = "Alba|Arad|Arges|Bacau|Bihor|Bistrita-Nasaud|Botosani|Braila|Brasov|Bucuresti|Buzau|Calarasi|Caras-Severin|Cluj|Constanta|Covasna|Dimbovita|Dolj|Galati|Giurgiu|Gorj|Harghita|Hunedoara|Ialomita|Iasi|Maramures|Mehedinti|Mures|Neamt|Olt|Prahova|Salaj|Satu Mare|Sibiu|Suceava|Teleorman|Timis|Tulcea|Vaslui|Vilcea|Vrancea";
        s_a[189] = "Adygeya (Maykop)|Aginskiy Buryatskiy (Aginskoye)|Altay (Gorno-Altaysk)|Altayskiy (Barnaul)|Amurskaya (Blagoveshchensk)|Arkhangel'skaya|Astrakhanskaya|Bashkortostan (Ufa)|Belgorodskaya|Bryanskaya|Buryatiya (Ulan-Ude)|Chechnya (Groznyy)|Chelyabinskaya|Chitinskaya|Chukotskiy (Anadyr')|Chuvashiya (Cheboksary)|Dagestan (Makhachkala)|Evenkiyskiy (Tura)|Ingushetiya (Nazran')|Irkutskaya|Ivanovskaya|Kabardino-Balkariya (Nal'chik)|Kaliningradskaya|Kalmykiya (Elista)|Kaluzhskaya|Kamchatskaya (Petropavlovsk-Kamchatskiy)|Karachayevo-Cherkesiya (Cherkessk)|Kareliya (Petrozavodsk)|Kemerovskaya|Khabarovskiy|Khakasiya (Abakan)|Khanty-Mansiyskiy (Khanty-Mansiysk)|Kirovskaya|Komi (Syktyvkar)|Komi-Permyatskiy (Kudymkar)|Koryakskiy (Palana)|Kostromskaya|Krasnodarskiy|Krasnoyarskiy|Kurganskaya|Kurskaya|Leningradskaya|Lipetskaya|Magadanskaya|Mariy-El (Yoshkar-Ola)|Mordoviya (Saransk)|Moskovskaya|Moskva (Moscow)|Murmanskaya|Nenetskiy (Nar'yan-Mar)|Nizhegorodskaya|Novgorodskaya|Novosibirskaya|Omskaya|Orenburgskaya|Orlovskaya (Orel)|Penzenskaya|Permskaya|Primorskiy (Vladivostok)|Pskovskaya|Rostovskaya|Ryazanskaya|Sakha (Yakutsk)|Sakhalinskaya (Yuzhno-Sakhalinsk)|Samarskaya|Sankt-Peterburg (Saint Petersburg)|Saratovskaya|Severnaya Osetiya-Alaniya [North Ossetia] (Vladikavkaz)|Smolenskaya|Stavropol'skiy|Sverdlovskaya (Yekaterinburg)|Tambovskaya|Tatarstan (Kazan')|Taymyrskiy (Dudinka)|Tomskaya|Tul'skaya|Tverskaya|Tyumenskaya|Tyva (Kyzyl)|Udmurtiya (Izhevsk)|Ul'yanovskaya|Ust'-Ordynskiy Buryatskiy (Ust'-Ordynskiy)|Vladimirskaya|Volgogradskaya|Vologodskaya|Voronezhskaya|Yamalo-Nenetskiy (Salekhard)|Yaroslavskaya|Yevreyskaya";
        s_a[190] = "Butare|Byumba|Cyangugu|Gikongoro|Gisenyi|Gitarama|Kibungo|Kibuye|Kigali Rurale|Kigali-ville|Ruhengeri|Umutara";
        s_a[191] = "Ascension|Saint Helena|Tristan da Cunha";
        s_a[192] = "Christ Church Nichola Town|Saint Anne Sandy Point|Saint George Basseterre|Saint George Gingerland|Saint James Windward|Saint John Capisterre|Saint John Figtree|Saint Mary Cayon|Saint Paul Capisterre|Saint Paul Charlestown|Saint Peter Basseterre|Saint Thomas Lowland|Saint Thomas Middle Island|Trinity Palmetto Point";
        s_a[193] = "Anse-la-Raye|Castries|Choiseul|Dauphin|Dennery|Gros Islet|Laborie|Micoud|Praslin|Soufriere|Vieux Fort";
        s_a[194] = "Miquelon|Saint Pierre";
        s_a[195] = "Charlotte|Grenadines|Saint Andrew|Saint David|Saint George|Saint Patrick";
        s_a[196] = "A'ana|Aiga-i-le-Tai|Atua|Fa'asaleleaga|Gaga'emauga|Gagaifomauga|Palauli|Satupa'itea|Tuamasaga|Va'a-o-Fonoti|Vaisigano";
        s_a[197] = "Acquaviva|Borgo Maggiore|Chiesanuova|Domagnano|Faetano|Fiorentino|Monte Giardino|San Marino|Serravalle";
        s_a[198] = "Principe|Sao Tome";
        s_a[199] = "'Asir|Al Bahah|Al Hudud ash Shamaliyah|Al Jawf|Al Madinah|Al Qasim|Ar Riyad|Ash Sharqiyah (Eastern Province)|Ha'il|Jizan|Makkah|Najran|Tabuk";
        s_a[200] = "Aberdeen City|Aberdeenshire|Angus|Argyll and Bute|City of Edinburgh|Clackmannanshire|Dumfries and Galloway|Dundee City|East Ayrshire|East Dunbartonshire|East Lothian|East Renfrewshire|Eilean Siar (Western Isles)|Falkirk|Fife|Glasgow City|Highland|Inverclyde|Midlothian|Moray|North Ayrshire|North Lanarkshire|Orkney Islands|Perth and Kinross|Renfrewshire|Shetland Islands|South Ayrshire|South Lanarkshire|Stirling|The Scottish Borders|West Dunbartonshire|West Lothian";
        s_a[201] = "Dakar|Diourbel|Fatick|Kaolack|Kolda|Louga|Saint-Louis|Tambacounda|Thies|Ziguinchor";
        s_a[202] = "Anse aux Pins|Anse Boileau|Anse Etoile|Anse Louis|Anse Royale|Baie Lazare|Baie Sainte Anne|Beau Vallon|Bel Air|Bel Ombre|Cascade|Glacis|Grand' Anse (on Mahe)|Grand' Anse (on Praslin)|La Digue|La Riviere Anglaise|Mont Buxton|Mont Fleuri|Plaisance|Pointe La Rue|Port Glaud|Saint Louis|Takamaka";
        s_a[203] = "Eastern|Northern|Southern|Western";
        s_a[204] = "Singapore";
        s_a[205] = "Banskobystricky|Bratislavsky|Kosicky|Nitriansky|Presovsky|Trenciansky|Trnavsky|Zilinsky";
        s_a[206] = "Ajdovscina|Beltinci|Bled|Bohinj|Borovnica|Bovec|Brda|Brezice|Brezovica|Cankova-Tisina|Celje|Cerklje na Gorenjskem|Cerknica|Cerkno|Crensovci|Crna na Koroskem|Crnomelj|Destrnik-Trnovska Vas|Divaca|Dobrepolje|Dobrova-Horjul-Polhov Gradec|Dol pri Ljubljani|Domzale|Dornava|Dravograd|Duplek|Gorenja Vas-Poljane|Gorisnica|Gornja Radgona|Gornji Grad|Gornji Petrovci|Grosuplje|Hodos Salovci|Hrastnik|Hrpelje-Kozina|Idrija|Ig|Ilirska Bistrica|Ivancna Gorica|Izola|Jesenice|Jursinci|Kamnik|Kanal|Kidricevo|Kobarid|Kobilje|Kocevje|Komen|Koper|Kozje|Kranj|Kranjska Gora|Krsko|Kungota|Kuzma|Lasko|Lenart|Lendava|Litija|Ljubljana|Ljubno|Ljutomer|Logatec|Loska Dolina|Loski Potok|Luce|Lukovica|Majsperk|Maribor|Medvode|Menges|Metlika|Mezica|Miren-Kostanjevica|Mislinja|Moravce|Moravske Toplice|Mozirje|Murska Sobota|Muta|Naklo|Nazarje|Nova Gorica|Novo Mesto|Odranci|Ormoz|Osilnica|Pesnica|Piran|Pivka|Podcetrtek|Podvelka-Ribnica|Postojna|Preddvor|Ptuj|Puconci|Race-Fram|Radece|Radenci|Radlje ob Dravi|Radovljica|Ravne-Prevalje|Ribnica|Rogasevci|Rogaska Slatina|Rogatec|Ruse|Semic|Sencur|Sentilj|Sentjernej|Sentjur pri Celju|Sevnica|Sezana|Skocjan|Skofja Loka|Skofljica|Slovenj Gradec|Slovenska Bistrica|Slovenske Konjice|Smarje pri Jelsah|Smartno ob Paki|Sostanj|Starse|Store|Sveti Jurij|Tolmin|Trbovlje|Trebnje|Trzic|Turnisce|Velenje|Velike Lasce|Videm|Vipava|Vitanje|Vodice|Vojnik|Vrhnika|Vuzenica|Zagorje ob Savi|Zalec|Zavrc|Zelezniki|Ziri|Zrece";
        s_a[207] = "Bellona|Central|Choiseul (Lauru)|Guadalcanal|Honiara|Isabel|Makira|Malaita|Rennell|Temotu|Western";
        s_a[208] = "Awdal|Bakool|Banaadir|Bari|Bay|Galguduud|Gedo|Hiiraan|Jubbada Dhexe|Jubbada Hoose|Mudug|Nugaal|Sanaag|Shabeellaha Dhexe|Shabeellaha Hoose|Sool|Togdheer|Woqooyi Galbeed";
        s_a[209] = "Eastern Cape|Free State|Gauteng|KwaZulu-Natal|Mpumalanga|North-West|Northern Cape|Northern Province|Western Cape";
        s_a[210] = "Bird Island|Bristol Island|Clerke Rocks|Montagu Island|Saunders Island|South Georgia|Southern Thule|Traversay Islands";
        s_a[211] = "Andalucia|Aragon|Asturias|Baleares (Balearic Islands)|Canarias (Canary Islands)|Cantabria|Castilla y Leon|Castilla-La Mancha|Cataluna|Ceuta|Communidad Valencian|Extremadura|Galicia|Islas Chafarinas|La Rioja|Madrid|Melilla|Murcia|Navarra|Pais Vasco (Basque Country)|Penon de Alhucemas|Penon de Velez de la Gomera";
        s_a[212] = "Spratly Islands";
        s_a[213] = "Central|Eastern|North Central|North Eastern|North Western|Northern|Sabaragamuwa|Southern|Uva|Western";
        s_a[214] = "A'ali an Nil|Al Bahr al Ahmar|Al Buhayrat|Al Jazirah|Al Khartum|Al Qadarif|Al Wahdah|An Nil al Abyad|An Nil al Azraq|Ash Shamaliyah|Bahr al Jabal|Gharb al Istiwa'iyah|Gharb Bahr al Ghazal|Gharb Darfur|Gharb Kurdufan|Janub Darfur|Janub Kurdufan|Junqali|Kassala|Nahr an Nil|Shamal Bahr al Ghazal|Shamal Darfur|Shamal Kurdufan|Sharq al Istiwa'iyah|Sinnar|Warab";
        s_a[215] = "Brokopondo|Commewijne|Coronie|Marowijne|Nickerie|Para|Paramaribo|Saramacca|Sipaliwini|Wanica";
        s_a[216] = "Barentsoya|Bjornoya|Edgeoya|Hopen|Kvitoya|Nordaustandet|Prins Karls Forland|Spitsbergen";
        s_a[217] = "Hhohho|Lubombo|Manzini|Shiselweni";
        s_a[218] = "Blekinge|Dalarnas|Gavleborgs|Gotlands|Hallands|Jamtlands|Jonkopings|Kalmar|Kronobergs|Norrbottens|Orebro|Ostergotlands|Skane|Sodermanlands|Stockholms|Uppsala|Varmlands|Vasterbottens|Vasternorrlands|Vastmanlands|Vastra Gotalands";
        s_a[219] = "Aargau|Ausser-Rhoden|Basel-Landschaft|Basel-Stadt|Bern|Fribourg|Geneve|Glarus|Graubunden|Inner-Rhoden|Jura|Luzern|Neuchatel|Nidwalden|Obwalden|Sankt Gallen|Schaffhausen|Schwyz|Solothurn|Thurgau|Ticino|Uri|Valais|Vaud|Zug|Zurich";
        s_a[220] = "Al Hasakah|Al Ladhiqiyah|Al Qunaytirah|Ar Raqqah|As Suwayda'|Dar'a|Dayr az Zawr|Dimashq|Halab|Hamah|Hims|Idlib|Rif Dimashq|Tartus";
        s_a[221] = "Chang-hua|Chi-lung|Chia-i|Chia-i|Chung-hsing-hsin-ts'un|Hsin-chu|Hsin-chu|Hua-lien|I-lan|Kao-hsiung|Kao-hsiung|Miao-li|Nan-t'ou|P'eng-hu|P'ing-tung|T'ai-chung|T'ai-chung|T'ai-nan|T'ai-nan|T'ai-pei|T'ai-pei|T'ai-tung|T'ao-yuan|Yun-lin";
        s_a[222] = "Viloyati Khatlon|Viloyati Leninobod|Viloyati Mukhtori Kuhistoni Badakhshon";
        s_a[223] = "Arusha|Dar es Salaam|Dodoma|Iringa|Kagera|Kigoma|Kilimanjaro|Lindi|Mara|Mbeya|Morogoro|Mtwara|Mwanza|Pemba North|Pemba South|Pwani|Rukwa|Ruvuma|Shinyanga|Singida|Tabora|Tanga|Zanzibar Central/South|Zanzibar North|Zanzibar Urban/West";
        s_a[224] = "Amnat Charoen|Ang Thong|Buriram|Chachoengsao|Chai Nat|Chaiyaphum|Chanthaburi|Chiang Mai|Chiang Rai|Chon Buri|Chumphon|Kalasin|Kamphaeng Phet|Kanchanaburi|Khon Kaen|Krabi|Krung Thep Mahanakhon (Bangkok)|Lampang|Lamphun|Loei|Lop Buri|Mae Hong Son|Maha Sarakham|Mukdahan|Nakhon Nayok|Nakhon Pathom|Nakhon Phanom|Nakhon Ratchasima|Nakhon Sawan|Nakhon Si Thammarat|Nan|Narathiwat|Nong Bua Lamphu|Nong Khai|Nonthaburi|Pathum Thani|Pattani|Phangnga|Phatthalung|Phayao|Phetchabun|Phetchaburi|Phichit|Phitsanulok|Phra Nakhon Si Ayutthaya|Phrae|Phuket|Prachin Buri|Prachuap Khiri Khan|Ranong|Ratchaburi|Rayong|Roi Et|Sa Kaeo|Sakon Nakhon|Samut Prakan|Samut Sakhon|Samut Songkhram|Sara Buri|Satun|Sing Buri|Sisaket|Songkhla|Sukhothai|Suphan Buri|Surat Thani|Surin|Tak|Trang|Trat|Ubon Ratchathani|Udon Thani|Uthai Thani|Uttaradit|Yala|Yasothon";
        s_a[225] = "Tobago";
        s_a[226] = "De La Kara|Des Plateaux|Des Savanes|Du Centre|Maritime";
        s_a[227] = "Atafu|Fakaofo|Nukunonu";
        s_a[228] = "Ha'apai|Tongatapu|Vava'u";
        s_a[229] = "Arima|Caroni|Mayaro|Nariva|Port-of-Spain|Saint Andrew|Saint David|Saint George|Saint Patrick|San Fernando|Victoria";
        s_a[230] = "Ariana|Beja|Ben Arous|Bizerte|El Kef|Gabes|Gafsa|Jendouba|Kairouan|Kasserine|Kebili|Mahdia|Medenine|Monastir|Nabeul|Sfax|Sidi Bou Zid|Siliana|Sousse|Tataouine|Tozeur|Tunis|Zaghouan";
        s_a[231] = "Adana|Adiyaman|Afyon|Agri|Aksaray|Amasya|Ankara|Antalya|Ardahan|Artvin|Aydin|Balikesir|Bartin|Batman|Bayburt|Bilecik|Bingol|Bitlis|Bolu|Burdur|Bursa|Canakkale|Cankiri|Corum|Denizli|Diyarbakir|Duzce|Edirne|Elazig|Erzincan|Erzurum|Eskisehir|Gaziantep|Giresun|Gumushane|Hakkari|Hatay|Icel|Igdir|Isparta|Istanbul|Izmir|Kahramanmaras|Karabuk|Karaman|Kars|Kastamonu|Kayseri|Kilis|Kirikkale|Kirklareli|Kirsehir|Kocaeli|Konya|Kutahya|Malatya|Manisa|Mardin|Mugla|Mus|Nevsehir|Nigde|Ordu|Osmaniye|Rize|Sakarya|Samsun|Sanliurfa|Siirt|Sinop|Sirnak|Sivas|Tekirdag|Tokat|Trabzon|Tunceli|Usak|Van|Yalova|Yozgat|Zonguldak";
        s_a[232] = "Ahal Welayaty|Balkan Welayaty|Dashhowuz Welayaty|Lebap Welayaty|Mary Welayaty";
        s_a[233] = "Tuvalu";
        s_a[234] = "Adjumani|Apac|Arua|Bugiri|Bundibugyo|Bushenyi|Busia|Gulu|Hoima|Iganga|Jinja|Kabale|Kabarole|Kalangala|Kampala|Kamuli|Kapchorwa|Kasese|Katakwi|Kibale|Kiboga|Kisoro|Kitgum|Kotido|Kumi|Lira|Luwero|Masaka|Masindi|Mbale|Mbarara|Moroto|Moyo|Mpigi|Mubende|Mukono|Nakasongola|Nebbi|Ntungamo|Pallisa|Rakai|Rukungiri|Sembabule|Soroti|Tororo";
        s_a[235] = "Avtonomna Respublika Krym (Simferopol')|Cherkas'ka (Cherkasy)|Chernihivs'ka (Chernihiv)|Chernivets'ka (Chernivtsi)|Dnipropetrovs'ka (Dnipropetrovs'k)|Donets'ka (Donets'k)|Ivano-Frankivs'ka (Ivano-Frankivs'k)|Kharkivs'ka (Kharkiv)|Khersons'ka (Kherson)|Khmel'nyts'ka (Khmel'nyts'kyy)|Kirovohrads'ka (Kirovohrad)|Kyyiv|Kyyivs'ka (Kiev)|L'vivs'ka (L'viv)|Luhans'ka (Luhans'k)|Mykolayivs'ka (Mykolayiv)|Odes'ka (Odesa)|Poltavs'ka (Poltava)|Rivnens'ka (Rivne)|Sevastopol'|Sums'ka (Sumy)|Ternopil's'ka (Ternopil')|Vinnyts'ka (Vinnytsya)|Volyns'ka (Luts'k)|Zakarpats'ka (Uzhhorod)|Zaporiz'ka (Zaporizhzhya)|Zhytomyrs'ka (Zhytomyr)"
        s_a[236] = "'Ajman|Abu Zaby (Abu Dhabi)|Al Fujayrah|Ash Shariqah (Sharjah)|Dubayy (Dubai)|Ra's al Khaymah|Umm al Qaywayn";
        s_a[237] = "Barking and Dagenham|Barnet|Barnsley|Bath and North East Somerset|Bedfordshire|Bexley|Birmingham|Blackburn with Darwen|Blackpool|Bolton|Bournemouth|Bracknell Forest|Bradford|Brent|Brighton and Hove|Bromley|Buckinghamshire|Bury|Calderdale|Cambridgeshire|Camden|Cheshire|City of Bristol|City of Kingston upon Hull|City of London|Cornwall|Coventry|Croydon|Cumbria|Darlington|Derby|Derbyshire|Devon|Doncaster|Dorset|Dudley|Durham|Ealing|East Riding of Yorkshire|East Sussex|Enfield|Essex|Gateshead|Gloucestershire|Greenwich|Hackney|Halton|Hammersmith and Fulham|Hampshire|Haringey|Harrow|Hartlepool|Havering|Herefordshire|Hertfordshire|Hillingdon|Hounslow|Isle of Wight|Islington|Kensington and Chelsea|Kent|Kingston upon Thames|Kirklees|Knowsley|Lambeth|Lancashire|Leeds|Leicester|Leicestershire|Lewisham|Lincolnshire|Liverpool|Luton|Manchester|Medway|Merton|Middlesbrough|Milton Keynes|Newcastle upon Tyne|Newham|Norfolk|North East Lincolnshire|North Lincolnshire|North Somerset|North Tyneside|North Yorkshire|Northamptonshire|Northumberland|Nottingham|Nottinghamshire|Oldham|Oxfordshire|Peterborough|Plymouth|Poole|Portsmouth|Reading|Redbridge|Redcar and Cleveland|Richmond upon Thames|Rochdale|Rotherham|Rutland|Salford|Sandwell|Sefton|Sheffield|Shropshire|Slough|Solihull|Somerset|South Gloucestershire|South Tyneside|Southampton|Southend-on-Sea|Southwark|St. Helens|Staffordshire|Stockport|Stockton-on-Tees|Stoke-on-Trent|Suffolk|Sunderland|Surrey|Sutton|Swindon|Tameside|Telford and Wrekin|Thurrock|Torbay|Tower Hamlets|Trafford|Wakefield|Walsall|Waltham Forest|Wandsworth|Warrington|Warwickshire|West Berkshire|West Sussex|Westminster|Wigan|Wiltshire|Windsor and Maidenhead|Wirral|Wokingham|Wolverhampton|Worcestershire|York";
        s_a[238] = "Artigas|Canelones|Cerro Largo|Colonia|Durazno|Flores|Florida|Lavalleja|Maldonado|Montevideo|Paysandu|Rio Negro|Rivera|Rocha|Salto|San Jose|Soriano|Tacuarembo|Treinta y Tres";
        s_a[239] = "Alabama|Alaska|Arizona|Arkansas|California|Colorado|Connecticut|Delaware|District of Columbia|Florida|Georgia|Hawaii|Idaho|Illinois|Indiana|Iowa|Kansas|Kentucky|Louisiana|Maine|Maryland|Massachusetts|Michigan|Minnesota|Mississippi|Missouri|Montana|Nebraska|Nevada|New Hampshire|New Jersey|New Mexico|New York|North Carolina|North Dakota|Ohio|Oklahoma|Oregon|Pennsylvania|Rhode Island|South Carolina|South Dakota|Tennessee|Texas|Utah|Vermont|Virginia|Washington|West Virginia|Wisconsin|Wyoming";
        s_a[240] = "Andijon Wiloyati|Bukhoro Wiloyati|Farghona Wiloyati|Jizzakh Wiloyati|Khorazm Wiloyati (Urganch)|Namangan Wiloyati|Nawoiy Wiloyati|Qashqadaryo Wiloyati (Qarshi)|Qoraqalpoghiston (Nukus)|Samarqand Wiloyati|Sirdaryo Wiloyati (Guliston)|Surkhondaryo Wiloyati (Termiz)|Toshkent Shahri|Toshkent Wiloyati";
        s_a[241] = "Malampa|Penama|Sanma|Shefa|Tafea|Torba";
        s_a[242] = "Amazonas|Anzoategui|Apure|Aragua|Barinas|Bolivar|Carabobo|Cojedes|Delta Amacuro|Dependencias Federales|Distrito Federal|Falcon|Guarico|Lara|Merida|Miranda|Monagas|Nueva Esparta|Portuguesa|Sucre|Tachira|Trujillo|Vargas|Yaracuy|Zulia";
        s_a[243] = "An Giang|Ba Ria-Vung Tau|Bac Giang|Bac Kan|Bac Lieu|Bac Ninh|Ben Tre|Binh Dinh|Binh Duong|Binh Phuoc|Binh Thuan|Ca Mau|Can Tho|Cao Bang|Da Nang|Dac Lak|Dong Nai|Dong Thap|Gia Lai|Ha Giang|Ha Nam|Ha Noi|Ha Tay|Ha Tinh|Hai Duong|Hai Phong|Ho Chi Minh|Hoa Binh|Hung Yen|Khanh Hoa|Kien Giang|Kon Tum|Lai Chau|Lam Dong|Lang Son|Lao Cai|Long An|Nam Dinh|Nghe An|Ninh Binh|Ninh Thuan|Phu Tho|Phu Yen|Quang Binh|Quang Nam|Quang Ngai|Quang Ninh|Quang Tri|Soc Trang|Son La|Tay Ninh|Thai Binh|Thai Nguyen|Thanh Hoa|Thua Thien-Hue|Tien Giang|Tra Vinh|Tuyen Quang|Vinh Long|Vinh Phuc|Yen Bai";
        s_a[244] = "Saint Croix|Saint John|Saint Thomas";
        s_a[245] = "Blaenau Gwent|Bridgend|Caerphilly|Cardiff|Carmarthenshire|Ceredigion|Conwy|Denbighshire|Flintshire|Gwynedd|Isle of Anglesey|Merthyr Tydfil|Monmouthshire|Neath Port Talbot|Newport|Pembrokeshire|Powys|Rhondda Cynon Taff|Swansea|The Vale of Glamorgan|Torfaen|Wrexham";
        s_a[246] = "Alo|Sigave|Wallis";
        s_a[247] = "West Bank";
        s_a[248] = "Western Sahara";
        s_a[249] = "'Adan|'Ataq|Abyan|Al Bayda'|Al Hudaydah|Al Jawf|Al Mahrah|Al Mahwit|Dhamar|Hadhramawt|Hajjah|Ibb|Lahij|Ma'rib|Sa'dah|San'a'|Ta'izz";
        s_a[250] = "Kosovo|Montenegro|Serbia|Vojvodina";
        s_a[251] = "Central|Copperbelt|Eastern|Luapula|Lusaka|North-Western|Northern|Southern|Western";
        s_a[252] = "Bulawayo|Harare|ManicalandMashonaland Central|Mashonaland East|Mashonaland West|Masvingo|Matabeleland North|Matabeleland South|Midlands";
        return s_a;
    }

    static getState(indexOrName) {
        let states = this.states();
        let n = indexOrName + 1;
        if (states[n]) {
            return states[n].split('|').sort();
        }
        return [];
    }

    static getTimezoneList(){
        return [
          {
            "title": "(GMT +00:00) Africa/Abidjan",
            "timezone": "Africa/Abidjan"
          },
          {
            "title": "(GMT +00:00) Africa/Accra",
            "timezone": "Africa/Accra"
          },
          {
            "title": "(GMT +03:00) Africa/Addis_Ababa",
            "timezone": "Africa/Addis_Ababa"
          },
          {
            "title": "(GMT +01:00) Africa/Algiers",
            "timezone": "Africa/Algiers"
          },
          {
            "title": "(GMT +03:00) Africa/Asmara",
            "timezone": "Africa/Asmara"
          },
          {
            "title": "(GMT +00:00) Africa/Bamako",
            "timezone": "Africa/Bamako"
          },
          {
            "title": "(GMT +01:00) Africa/Bangui",
            "timezone": "Africa/Bangui"
          },
          {
            "title": "(GMT +00:00) Africa/Banjul",
            "timezone": "Africa/Banjul"
          },
          {
            "title": "(GMT +00:00) Africa/Bissau",
            "timezone": "Africa/Bissau"
          },
          {
            "title": "(GMT +02:00) Africa/Blantyre",
            "timezone": "Africa/Blantyre"
          },
          {
            "title": "(GMT +01:00) Africa/Brazzaville",
            "timezone": "Africa/Brazzaville"
          },
          {
            "title": "(GMT +02:00) Africa/Bujumbura",
            "timezone": "Africa/Bujumbura"
          },
          {
            "title": "(GMT +02:00) Africa/Cairo",
            "timezone": "Africa/Cairo"
          },
          {
            "title": "(GMT +01:00) Africa/Casablanca",
            "timezone": "Africa/Casablanca"
          },
          {
            "title": "(GMT +01:00) Africa/Ceuta",
            "timezone": "Africa/Ceuta"
          },
          {
            "title": "(GMT +00:00) Africa/Conakry",
            "timezone": "Africa/Conakry"
          },
          {
            "title": "(GMT +00:00) Africa/Dakar",
            "timezone": "Africa/Dakar"
          },
          {
            "title": "(GMT +03:00) Africa/Dar_es_Salaam",
            "timezone": "Africa/Dar_es_Salaam"
          },
          {
            "title": "(GMT +03:00) Africa/Djibouti",
            "timezone": "Africa/Djibouti"
          },
          {
            "title": "(GMT +01:00) Africa/Douala",
            "timezone": "Africa/Douala"
          },
          {
            "title": "(GMT +01:00) Africa/El_Aaiun",
            "timezone": "Africa/El_Aaiun"
          },
          {
            "title": "(GMT +00:00) Africa/Freetown",
            "timezone": "Africa/Freetown"
          },
          {
            "title": "(GMT +02:00) Africa/Gaborone",
            "timezone": "Africa/Gaborone"
          },
          {
            "title": "(GMT +02:00) Africa/Harare",
            "timezone": "Africa/Harare"
          },
          {
            "title": "(GMT +02:00) Africa/Johannesburg",
            "timezone": "Africa/Johannesburg"
          },
          {
            "title": "(GMT +02:00) Africa/Juba",
            "timezone": "Africa/Juba"
          },
          {
            "title": "(GMT +03:00) Africa/Kampala",
            "timezone": "Africa/Kampala"
          },
          {
            "title": "(GMT +02:00) Africa/Khartoum",
            "timezone": "Africa/Khartoum"
          },
          {
            "title": "(GMT +02:00) Africa/Kigali",
            "timezone": "Africa/Kigali"
          },
          {
            "title": "(GMT +01:00) Africa/Kinshasa",
            "timezone": "Africa/Kinshasa"
          },
          {
            "title": "(GMT +01:00) Africa/Lagos",
            "timezone": "Africa/Lagos"
          },
          {
            "title": "(GMT +01:00) Africa/Libreville",
            "timezone": "Africa/Libreville"
          },
          {
            "title": "(GMT +00:00) Africa/Lome",
            "timezone": "Africa/Lome"
          },
          {
            "title": "(GMT +01:00) Africa/Luanda",
            "timezone": "Africa/Luanda"
          },
          {
            "title": "(GMT +02:00) Africa/Lubumbashi",
            "timezone": "Africa/Lubumbashi"
          },
          {
            "title": "(GMT +02:00) Africa/Lusaka",
            "timezone": "Africa/Lusaka"
          },
          {
            "title": "(GMT +01:00) Africa/Malabo",
            "timezone": "Africa/Malabo"
          },
          {
            "title": "(GMT +02:00) Africa/Maputo",
            "timezone": "Africa/Maputo"
          },
          {
            "title": "(GMT +02:00) Africa/Maseru",
            "timezone": "Africa/Maseru"
          },
          {
            "title": "(GMT +02:00) Africa/Mbabane",
            "timezone": "Africa/Mbabane"
          },
          {
            "title": "(GMT +03:00) Africa/Mogadishu",
            "timezone": "Africa/Mogadishu"
          },
          {
            "title": "(GMT +00:00) Africa/Monrovia",
            "timezone": "Africa/Monrovia"
          },
          {
            "title": "(GMT +03:00) Africa/Nairobi",
            "timezone": "Africa/Nairobi"
          },
          {
            "title": "(GMT +01:00) Africa/Ndjamena",
            "timezone": "Africa/Ndjamena"
          },
          {
            "title": "(GMT +01:00) Africa/Niamey",
            "timezone": "Africa/Niamey"
          },
          {
            "title": "(GMT +00:00) Africa/Nouakchott",
            "timezone": "Africa/Nouakchott"
          },
          {
            "title": "(GMT +00:00) Africa/Ouagadougou",
            "timezone": "Africa/Ouagadougou"
          },
          {
            "title": "(GMT +01:00) Africa/Porto-Novo",
            "timezone": "Africa/Porto-Novo"
          },
          {
            "title": "(GMT +00:00) Africa/Sao_Tome",
            "timezone": "Africa/Sao_Tome"
          },
          {
            "title": "(GMT +02:00) Africa/Tripoli",
            "timezone": "Africa/Tripoli"
          },
          {
            "title": "(GMT +01:00) Africa/Tunis",
            "timezone": "Africa/Tunis"
          },
          {
            "title": "(GMT +02:00) Africa/Windhoek",
            "timezone": "Africa/Windhoek"
          },
          {
            "title": "(GMT -10:00) America/Adak",
            "timezone": "America/Adak"
          },
          {
            "title": "(GMT -09:00) America/Anchorage",
            "timezone": "America/Anchorage"
          },
          {
            "title": "(GMT -04:00) America/Anguilla",
            "timezone": "America/Anguilla"
          },
          {
            "title": "(GMT -04:00) America/Antigua",
            "timezone": "America/Antigua"
          },
          {
            "title": "(GMT -03:00) America/Araguaina",
            "timezone": "America/Araguaina"
          },
          {
            "title": "(GMT -03:00) America/Argentina/Buenos_Aires",
            "timezone": "America/Argentina/Buenos_Aires"
          },
          {
            "title": "(GMT -03:00) America/Argentina/Catamarca",
            "timezone": "America/Argentina/Catamarca"
          },
          {
            "title": "(GMT -03:00) America/Argentina/Cordoba",
            "timezone": "America/Argentina/Cordoba"
          },
          {
            "title": "(GMT -03:00) America/Argentina/Jujuy",
            "timezone": "America/Argentina/Jujuy"
          },
          {
            "title": "(GMT -03:00) America/Argentina/La_Rioja",
            "timezone": "America/Argentina/La_Rioja"
          },
          {
            "title": "(GMT -03:00) America/Argentina/Mendoza",
            "timezone": "America/Argentina/Mendoza"
          },
          {
            "title": "(GMT -03:00) America/Argentina/Rio_Gallegos",
            "timezone": "America/Argentina/Rio_Gallegos"
          },
          {
            "title": "(GMT -03:00) America/Argentina/Salta",
            "timezone": "America/Argentina/Salta"
          },
          {
            "title": "(GMT -03:00) America/Argentina/San_Juan",
            "timezone": "America/Argentina/San_Juan"
          },
          {
            "title": "(GMT -03:00) America/Argentina/San_Luis",
            "timezone": "America/Argentina/San_Luis"
          },
          {
            "title": "(GMT -03:00) America/Argentina/Tucuman",
            "timezone": "America/Argentina/Tucuman"
          },
          {
            "title": "(GMT -03:00) America/Argentina/Ushuaia",
            "timezone": "America/Argentina/Ushuaia"
          },
          {
            "title": "(GMT -04:00) America/Aruba",
            "timezone": "America/Aruba"
          },
          {
            "title": "(GMT -03:00) America/Asuncion",
            "timezone": "America/Asuncion"
          },
          {
            "title": "(GMT -05:00) America/Atikokan",
            "timezone": "America/Atikokan"
          },
          {
            "title": "(GMT -03:00) America/Bahia",
            "timezone": "America/Bahia"
          },
          {
            "title": "(GMT -06:00) America/Bahia_Banderas",
            "timezone": "America/Bahia_Banderas"
          },
          {
            "title": "(GMT -04:00) America/Barbados",
            "timezone": "America/Barbados"
          },
          {
            "title": "(GMT -03:00) America/Belem",
            "timezone": "America/Belem"
          },
          {
            "title": "(GMT -06:00) America/Belize",
            "timezone": "America/Belize"
          },
          {
            "title": "(GMT -04:00) America/Blanc-Sablon",
            "timezone": "America/Blanc-Sablon"
          },
          {
            "title": "(GMT -04:00) America/Boa_Vista",
            "timezone": "America/Boa_Vista"
          },
          {
            "title": "(GMT -05:00) America/Bogota",
            "timezone": "America/Bogota"
          },
          {
            "title": "(GMT -07:00) America/Boise",
            "timezone": "America/Boise"
          },
          {
            "title": "(GMT -07:00) America/Cambridge_Bay",
            "timezone": "America/Cambridge_Bay"
          },
          {
            "title": "(GMT -04:00) America/Campo_Grande",
            "timezone": "America/Campo_Grande"
          },
          {
            "title": "(GMT -05:00) America/Cancun",
            "timezone": "America/Cancun"
          },
          {
            "title": "(GMT -04:00) America/Caracas",
            "timezone": "America/Caracas"
          },
          {
            "title": "(GMT -03:00) America/Cayenne",
            "timezone": "America/Cayenne"
          },
          {
            "title": "(GMT -05:00) America/Cayman",
            "timezone": "America/Cayman"
          },
          {
            "title": "(GMT -06:00) America/Chicago",
            "timezone": "America/Chicago"
          },
          {
            "title": "(GMT -07:00) America/Chihuahua",
            "timezone": "America/Chihuahua"
          },
          {
            "title": "(GMT -06:00) America/Costa_Rica",
            "timezone": "America/Costa_Rica"
          },
          {
            "title": "(GMT -07:00) America/Creston",
            "timezone": "America/Creston"
          },
          {
            "title": "(GMT -04:00) America/Cuiaba",
            "timezone": "America/Cuiaba"
          },
          {
            "title": "(GMT -04:00) America/Curacao",
            "timezone": "America/Curacao"
          },
          {
            "title": "(GMT +00:00) America/Danmarkshavn",
            "timezone": "America/Danmarkshavn"
          },
          {
            "title": "(GMT -07:00) America/Dawson",
            "timezone": "America/Dawson"
          },
          {
            "title": "(GMT -07:00) America/Dawson_Creek",
            "timezone": "America/Dawson_Creek"
          },
          {
            "title": "(GMT -07:00) America/Denver",
            "timezone": "America/Denver"
          },
          {
            "title": "(GMT -05:00) America/Detroit",
            "timezone": "America/Detroit"
          },
          {
            "title": "(GMT -04:00) America/Dominica",
            "timezone": "America/Dominica"
          },
          {
            "title": "(GMT -07:00) America/Edmonton",
            "timezone": "America/Edmonton"
          },
          {
            "title": "(GMT -05:00) America/Eirunepe",
            "timezone": "America/Eirunepe"
          },
          {
            "title": "(GMT -06:00) America/El_Salvador",
            "timezone": "America/El_Salvador"
          },
          {
            "title": "(GMT -07:00) America/Fort_Nelson",
            "timezone": "America/Fort_Nelson"
          },
          {
            "title": "(GMT -03:00) America/Fortaleza",
            "timezone": "America/Fortaleza"
          },
          {
            "title": "(GMT -04:00) America/Glace_Bay",
            "timezone": "America/Glace_Bay"
          },
          {
            "title": "(GMT -04:00) America/Goose_Bay",
            "timezone": "America/Goose_Bay"
          },
          {
            "title": "(GMT -05:00) America/Grand_Turk",
            "timezone": "America/Grand_Turk"
          },
          {
            "title": "(GMT -04:00) America/Grenada",
            "timezone": "America/Grenada"
          },
          {
            "title": "(GMT -04:00) America/Guadeloupe",
            "timezone": "America/Guadeloupe"
          },
          {
            "title": "(GMT -06:00) America/Guatemala",
            "timezone": "America/Guatemala"
          },
          {
            "title": "(GMT -05:00) America/Guayaquil",
            "timezone": "America/Guayaquil"
          },
          {
            "title": "(GMT -04:00) America/Guyana",
            "timezone": "America/Guyana"
          },
          {
            "title": "(GMT -04:00) America/Halifax",
            "timezone": "America/Halifax"
          },
          {
            "title": "(GMT -05:00) America/Havana",
            "timezone": "America/Havana"
          },
          {
            "title": "(GMT -07:00) America/Hermosillo",
            "timezone": "America/Hermosillo"
          },
          {
            "title": "(GMT -05:00) America/Indiana/Indianapolis",
            "timezone": "America/Indiana/Indianapolis"
          },
          {
            "title": "(GMT -06:00) America/Indiana/Knox",
            "timezone": "America/Indiana/Knox"
          },
          {
            "title": "(GMT -05:00) America/Indiana/Marengo",
            "timezone": "America/Indiana/Marengo"
          },
          {
            "title": "(GMT -05:00) America/Indiana/Petersburg",
            "timezone": "America/Indiana/Petersburg"
          },
          {
            "title": "(GMT -06:00) America/Indiana/Tell_City",
            "timezone": "America/Indiana/Tell_City"
          },
          {
            "title": "(GMT -05:00) America/Indiana/Vevay",
            "timezone": "America/Indiana/Vevay"
          },
          {
            "title": "(GMT -05:00) America/Indiana/Vincennes",
            "timezone": "America/Indiana/Vincennes"
          },
          {
            "title": "(GMT -05:00) America/Indiana/Winamac",
            "timezone": "America/Indiana/Winamac"
          },
          {
            "title": "(GMT -07:00) America/Inuvik",
            "timezone": "America/Inuvik"
          },
          {
            "title": "(GMT -05:00) America/Iqaluit",
            "timezone": "America/Iqaluit"
          },
          {
            "title": "(GMT -05:00) America/Jamaica",
            "timezone": "America/Jamaica"
          },
          {
            "title": "(GMT -09:00) America/Juneau",
            "timezone": "America/Juneau"
          },
          {
            "title": "(GMT -05:00) America/Kentucky/Louisville",
            "timezone": "America/Kentucky/Louisville"
          },
          {
            "title": "(GMT -05:00) America/Kentucky/Monticello",
            "timezone": "America/Kentucky/Monticello"
          },
          {
            "title": "(GMT -04:00) America/Kralendijk",
            "timezone": "America/Kralendijk"
          },
          {
            "title": "(GMT -04:00) America/La_Paz",
            "timezone": "America/La_Paz"
          },
          {
            "title": "(GMT -05:00) America/Lima",
            "timezone": "America/Lima"
          },
          {
            "title": "(GMT -08:00) America/Los_Angeles",
            "timezone": "America/Los_Angeles"
          },
          {
            "title": "(GMT -04:00) America/Lower_Princes",
            "timezone": "America/Lower_Princes"
          },
          {
            "title": "(GMT -03:00) America/Maceio",
            "timezone": "America/Maceio"
          },
          {
            "title": "(GMT -06:00) America/Managua",
            "timezone": "America/Managua"
          },
          {
            "title": "(GMT -04:00) America/Manaus",
            "timezone": "America/Manaus"
          },
          {
            "title": "(GMT -04:00) America/Marigot",
            "timezone": "America/Marigot"
          },
          {
            "title": "(GMT -04:00) America/Martinique",
            "timezone": "America/Martinique"
          },
          {
            "title": "(GMT -06:00) America/Matamoros",
            "timezone": "America/Matamoros"
          },
          {
            "title": "(GMT -07:00) America/Mazatlan",
            "timezone": "America/Mazatlan"
          },
          {
            "title": "(GMT -06:00) America/Menominee",
            "timezone": "America/Menominee"
          },
          {
            "title": "(GMT -06:00) America/Merida",
            "timezone": "America/Merida"
          },
          {
            "title": "(GMT -09:00) America/Metlakatla",
            "timezone": "America/Metlakatla"
          },
          {
            "title": "(GMT -06:00) America/Mexico_City",
            "timezone": "America/Mexico_City"
          },
          {
            "title": "(GMT -03:00) America/Miquelon",
            "timezone": "America/Miquelon"
          },
          {
            "title": "(GMT -04:00) America/Moncton",
            "timezone": "America/Moncton"
          },
          {
            "title": "(GMT -06:00) America/Monterrey",
            "timezone": "America/Monterrey"
          },
          {
            "title": "(GMT -03:00) America/Montevideo",
            "timezone": "America/Montevideo"
          },
          {
            "title": "(GMT -04:00) America/Montserrat",
            "timezone": "America/Montserrat"
          },
          {
            "title": "(GMT -05:00) America/Nassau",
            "timezone": "America/Nassau"
          },
          {
            "title": "(GMT -05:00) America/New_York",
            "timezone": "America/New_York"
          },
          {
            "title": "(GMT -05:00) America/Nipigon",
            "timezone": "America/Nipigon"
          },
          {
            "title": "(GMT -09:00) America/Nome",
            "timezone": "America/Nome"
          },
          {
            "title": "(GMT -02:00) America/Noronha",
            "timezone": "America/Noronha"
          },
          {
            "title": "(GMT -06:00) America/North_Dakota/Beulah",
            "timezone": "America/North_Dakota/Beulah"
          },
          {
            "title": "(GMT -06:00) America/North_Dakota/Center",
            "timezone": "America/North_Dakota/Center"
          },
          {
            "title": "(GMT -06:00) America/North_Dakota/New_Salem",
            "timezone": "America/North_Dakota/New_Salem"
          },
          {
            "title": "(GMT -03:00) America/Nuuk",
            "timezone": "America/Nuuk"
          },
          {
            "title": "(GMT -07:00) America/Ojinaga",
            "timezone": "America/Ojinaga"
          },
          {
            "title": "(GMT -05:00) America/Panama",
            "timezone": "America/Panama"
          },
          {
            "title": "(GMT -05:00) America/Pangnirtung",
            "timezone": "America/Pangnirtung"
          },
          {
            "title": "(GMT -03:00) America/Paramaribo",
            "timezone": "America/Paramaribo"
          },
          {
            "title": "(GMT -07:00) America/Phoenix",
            "timezone": "America/Phoenix"
          },
          {
            "title": "(GMT -05:00) America/Port-au-Prince",
            "timezone": "America/Port-au-Prince"
          },
          {
            "title": "(GMT -04:00) America/Port_of_Spain",
            "timezone": "America/Port_of_Spain"
          },
          {
            "title": "(GMT -04:00) America/Porto_Velho",
            "timezone": "America/Porto_Velho"
          },
          {
            "title": "(GMT -04:00) America/Puerto_Rico",
            "timezone": "America/Puerto_Rico"
          },
          {
            "title": "(GMT -03:00) America/Punta_Arenas",
            "timezone": "America/Punta_Arenas"
          },
          {
            "title": "(GMT -06:00) America/Rainy_River",
            "timezone": "America/Rainy_River"
          },
          {
            "title": "(GMT -06:00) America/Rankin_Inlet",
            "timezone": "America/Rankin_Inlet"
          },
          {
            "title": "(GMT -03:00) America/Recife",
            "timezone": "America/Recife"
          },
          {
            "title": "(GMT -06:00) America/Regina",
            "timezone": "America/Regina"
          },
          {
            "title": "(GMT -06:00) America/Resolute",
            "timezone": "America/Resolute"
          },
          {
            "title": "(GMT -05:00) America/Rio_Branco",
            "timezone": "America/Rio_Branco"
          },
          {
            "title": "(GMT -03:00) America/Santarem",
            "timezone": "America/Santarem"
          },
          {
            "title": "(GMT -03:00) America/Santiago",
            "timezone": "America/Santiago"
          },
          {
            "title": "(GMT -04:00) America/Santo_Domingo",
            "timezone": "America/Santo_Domingo"
          },
          {
            "title": "(GMT -03:00) America/Sao_Paulo",
            "timezone": "America/Sao_Paulo"
          },
          {
            "title": "(GMT -01:00) America/Scoresbysund",
            "timezone": "America/Scoresbysund"
          },
          {
            "title": "(GMT -09:00) America/Sitka",
            "timezone": "America/Sitka"
          },
          {
            "title": "(GMT -04:00) America/St_Barthelemy",
            "timezone": "America/St_Barthelemy"
          },
          {
            "title": "(GMT -03:30) America/St_Johns",
            "timezone": "America/St_Johns"
          },
          {
            "title": "(GMT -04:00) America/St_Kitts",
            "timezone": "America/St_Kitts"
          },
          {
            "title": "(GMT -04:00) America/St_Lucia",
            "timezone": "America/St_Lucia"
          },
          {
            "title": "(GMT -04:00) America/St_Thomas",
            "timezone": "America/St_Thomas"
          },
          {
            "title": "(GMT -04:00) America/St_Vincent",
            "timezone": "America/St_Vincent"
          },
          {
            "title": "(GMT -06:00) America/Swift_Current",
            "timezone": "America/Swift_Current"
          },
          {
            "title": "(GMT -06:00) America/Tegucigalpa",
            "timezone": "America/Tegucigalpa"
          },
          {
            "title": "(GMT -04:00) America/Thule",
            "timezone": "America/Thule"
          },
          {
            "title": "(GMT -05:00) America/Thunder_Bay",
            "timezone": "America/Thunder_Bay"
          },
          {
            "title": "(GMT -08:00) America/Tijuana",
            "timezone": "America/Tijuana"
          },
          {
            "title": "(GMT -05:00) America/Toronto",
            "timezone": "America/Toronto"
          },
          {
            "title": "(GMT -04:00) America/Tortola",
            "timezone": "America/Tortola"
          },
          {
            "title": "(GMT -08:00) America/Vancouver",
            "timezone": "America/Vancouver"
          },
          {
            "title": "(GMT -07:00) America/Whitehorse",
            "timezone": "America/Whitehorse"
          },
          {
            "title": "(GMT -06:00) America/Winnipeg",
            "timezone": "America/Winnipeg"
          },
          {
            "title": "(GMT -09:00) America/Yakutat",
            "timezone": "America/Yakutat"
          },
          {
            "title": "(GMT -07:00) America/Yellowknife",
            "timezone": "America/Yellowknife"
          },
          {
            "title": "(GMT +11:00) Antarctica/Casey",
            "timezone": "Antarctica/Casey"
          },
          {
            "title": "(GMT +07:00) Antarctica/Davis",
            "timezone": "Antarctica/Davis"
          },
          {
            "title": "(GMT +10:00) Antarctica/DumontDUrville",
            "timezone": "Antarctica/DumontDUrville"
          },
          {
            "title": "(GMT +11:00) Antarctica/Macquarie",
            "timezone": "Antarctica/Macquarie"
          },
          {
            "title": "(GMT +05:00) Antarctica/Mawson",
            "timezone": "Antarctica/Mawson"
          },
          {
            "title": "(GMT +13:00) Antarctica/McMurdo",
            "timezone": "Antarctica/McMurdo"
          },
          {
            "title": "(GMT -03:00) Antarctica/Palmer",
            "timezone": "Antarctica/Palmer"
          },
          {
            "title": "(GMT -03:00) Antarctica/Rothera",
            "timezone": "Antarctica/Rothera"
          },
          {
            "title": "(GMT +03:00) Antarctica/Syowa",
            "timezone": "Antarctica/Syowa"
          },
          {
            "title": "(GMT +00:00) Antarctica/Troll",
            "timezone": "Antarctica/Troll"
          },
          {
            "title": "(GMT +06:00) Antarctica/Vostok",
            "timezone": "Antarctica/Vostok"
          },
          {
            "title": "(GMT +01:00) Arctic/Longyearbyen",
            "timezone": "Arctic/Longyearbyen"
          },
          {
            "title": "(GMT +03:00) Asia/Aden",
            "timezone": "Asia/Aden"
          },
          {
            "title": "(GMT +06:00) Asia/Almaty",
            "timezone": "Asia/Almaty"
          },
          {
            "title": "(GMT +02:00) Asia/Amman",
            "timezone": "Asia/Amman"
          },
          {
            "title": "(GMT +12:00) Asia/Anadyr",
            "timezone": "Asia/Anadyr"
          },
          {
            "title": "(GMT +05:00) Asia/Aqtau",
            "timezone": "Asia/Aqtau"
          },
          {
            "title": "(GMT +05:00) Asia/Aqtobe",
            "timezone": "Asia/Aqtobe"
          },
          {
            "title": "(GMT +05:00) Asia/Ashgabat",
            "timezone": "Asia/Ashgabat"
          },
          {
            "title": "(GMT +05:00) Asia/Atyrau",
            "timezone": "Asia/Atyrau"
          },
          {
            "title": "(GMT +03:00) Asia/Baghdad",
            "timezone": "Asia/Baghdad"
          },
          {
            "title": "(GMT +03:00) Asia/Bahrain",
            "timezone": "Asia/Bahrain"
          },
          {
            "title": "(GMT +04:00) Asia/Baku",
            "timezone": "Asia/Baku"
          },
          {
            "title": "(GMT +07:00) Asia/Bangkok",
            "timezone": "Asia/Bangkok"
          },
          {
            "title": "(GMT +07:00) Asia/Barnaul",
            "timezone": "Asia/Barnaul"
          },
          {
            "title": "(GMT +02:00) Asia/Beirut",
            "timezone": "Asia/Beirut"
          },
          {
            "title": "(GMT +06:00) Asia/Bishkek",
            "timezone": "Asia/Bishkek"
          },
          {
            "title": "(GMT +08:00) Asia/Brunei",
            "timezone": "Asia/Brunei"
          },
          {
            "title": "(GMT +09:00) Asia/Chita",
            "timezone": "Asia/Chita"
          },
          {
            "title": "(GMT +08:00) Asia/Choibalsan",
            "timezone": "Asia/Choibalsan"
          },
          {
            "title": "(GMT +05:30) Asia/Colombo",
            "timezone": "Asia/Colombo"
          },
          {
            "title": "(GMT +02:00) Asia/Damascus",
            "timezone": "Asia/Damascus"
          },
          {
            "title": "(GMT +06:00) Asia/Dhaka",
            "timezone": "Asia/Dhaka"
          },
          {
            "title": "(GMT +09:00) Asia/Dili",
            "timezone": "Asia/Dili"
          },
          {
            "title": "(GMT +04:00) Asia/Dubai",
            "timezone": "Asia/Dubai"
          },
          {
            "title": "(GMT +05:00) Asia/Dushanbe",
            "timezone": "Asia/Dushanbe"
          },
          {
            "title": "(GMT +02:00) Asia/Famagusta",
            "timezone": "Asia/Famagusta"
          },
          {
            "title": "(GMT +02:00) Asia/Gaza",
            "timezone": "Asia/Gaza"
          },
          {
            "title": "(GMT +02:00) Asia/Hebron",
            "timezone": "Asia/Hebron"
          },
          {
            "title": "(GMT +07:00) Asia/Ho_Chi_Minh",
            "timezone": "Asia/Ho_Chi_Minh"
          },
          {
            "title": "(GMT +08:00) Asia/Hong_Kong",
            "timezone": "Asia/Hong_Kong"
          },
          {
            "title": "(GMT +07:00) Asia/Hovd",
            "timezone": "Asia/Hovd"
          },
          {
            "title": "(GMT +08:00) Asia/Irkutsk",
            "timezone": "Asia/Irkutsk"
          },
          {
            "title": "(GMT +07:00) Asia/Jakarta",
            "timezone": "Asia/Jakarta"
          },
          {
            "title": "(GMT +09:00) Asia/Jayapura",
            "timezone": "Asia/Jayapura"
          },
          {
            "title": "(GMT +02:00) Asia/Jerusalem",
            "timezone": "Asia/Jerusalem"
          },
          {
            "title": "(GMT +04:30) Asia/Kabul",
            "timezone": "Asia/Kabul"
          },
          {
            "title": "(GMT +12:00) Asia/Kamchatka",
            "timezone": "Asia/Kamchatka"
          },
          {
            "title": "(GMT +05:00) Asia/Karachi",
            "timezone": "Asia/Karachi"
          },
          {
            "title": "(GMT +05:45) Asia/Kathmandu",
            "timezone": "Asia/Kathmandu"
          },
          {
            "title": "(GMT +09:00) Asia/Khandyga",
            "timezone": "Asia/Khandyga"
          },
          {
            "title": "(GMT +05:30) Asia/Kolkata",
            "timezone": "Asia/Kolkata"
          },
          {
            "title": "(GMT +07:00) Asia/Krasnoyarsk",
            "timezone": "Asia/Krasnoyarsk"
          },
          {
            "title": "(GMT +08:00) Asia/Kuala_Lumpur",
            "timezone": "Asia/Kuala_Lumpur"
          },
          {
            "title": "(GMT +08:00) Asia/Kuching",
            "timezone": "Asia/Kuching"
          },
          {
            "title": "(GMT +03:00) Asia/Kuwait",
            "timezone": "Asia/Kuwait"
          },
          {
            "title": "(GMT +08:00) Asia/Macau",
            "timezone": "Asia/Macau"
          },
          {
            "title": "(GMT +11:00) Asia/Magadan",
            "timezone": "Asia/Magadan"
          },
          {
            "title": "(GMT +08:00) Asia/Makassar",
            "timezone": "Asia/Makassar"
          },
          {
            "title": "(GMT +08:00) Asia/Manila",
            "timezone": "Asia/Manila"
          },
          {
            "title": "(GMT +04:00) Asia/Muscat",
            "timezone": "Asia/Muscat"
          },
          {
            "title": "(GMT +02:00) Asia/Nicosia",
            "timezone": "Asia/Nicosia"
          },
          {
            "title": "(GMT +07:00) Asia/Novokuznetsk",
            "timezone": "Asia/Novokuznetsk"
          },
          {
            "title": "(GMT +07:00) Asia/Novosibirsk",
            "timezone": "Asia/Novosibirsk"
          },
          {
            "title": "(GMT +06:00) Asia/Omsk",
            "timezone": "Asia/Omsk"
          },
          {
            "title": "(GMT +05:00) Asia/Oral",
            "timezone": "Asia/Oral"
          },
          {
            "title": "(GMT +07:00) Asia/Phnom_Penh",
            "timezone": "Asia/Phnom_Penh"
          },
          {
            "title": "(GMT +07:00) Asia/Pontianak",
            "timezone": "Asia/Pontianak"
          },
          {
            "title": "(GMT +09:00) Asia/Pyongyang",
            "timezone": "Asia/Pyongyang"
          },
          {
            "title": "(GMT +03:00) Asia/Qatar",
            "timezone": "Asia/Qatar"
          },
          {
            "title": "(GMT +06:00) Asia/Qostanay",
            "timezone": "Asia/Qostanay"
          },
          {
            "title": "(GMT +05:00) Asia/Qyzylorda",
            "timezone": "Asia/Qyzylorda"
          },
          {
            "title": "(GMT +03:00) Asia/Riyadh",
            "timezone": "Asia/Riyadh"
          },
          {
            "title": "(GMT +11:00) Asia/Sakhalin",
            "timezone": "Asia/Sakhalin"
          },
          {
            "title": "(GMT +05:00) Asia/Samarkand",
            "timezone": "Asia/Samarkand"
          },
          {
            "title": "(GMT +09:00) Asia/Seoul",
            "timezone": "Asia/Seoul"
          },
          {
            "title": "(GMT +08:00) Asia/Shanghai",
            "timezone": "Asia/Shanghai"
          },
          {
            "title": "(GMT +08:00) Asia/Singapore",
            "timezone": "Asia/Singapore"
          },
          {
            "title": "(GMT +11:00) Asia/Srednekolymsk",
            "timezone": "Asia/Srednekolymsk"
          },
          {
            "title": "(GMT +08:00) Asia/Taipei",
            "timezone": "Asia/Taipei"
          },
          {
            "title": "(GMT +05:00) Asia/Tashkent",
            "timezone": "Asia/Tashkent"
          },
          {
            "title": "(GMT +04:00) Asia/Tbilisi",
            "timezone": "Asia/Tbilisi"
          },
          {
            "title": "(GMT +03:30) Asia/Tehran",
            "timezone": "Asia/Tehran"
          },
          {
            "title": "(GMT +06:00) Asia/Thimphu",
            "timezone": "Asia/Thimphu"
          },
          {
            "title": "(GMT +09:00) Asia/Tokyo",
            "timezone": "Asia/Tokyo"
          },
          {
            "title": "(GMT +07:00) Asia/Tomsk",
            "timezone": "Asia/Tomsk"
          },
          {
            "title": "(GMT +08:00) Asia/Ulaanbaatar",
            "timezone": "Asia/Ulaanbaatar"
          },
          {
            "title": "(GMT +06:00) Asia/Urumqi",
            "timezone": "Asia/Urumqi"
          },
          {
            "title": "(GMT +10:00) Asia/Ust-Nera",
            "timezone": "Asia/Ust-Nera"
          },
          {
            "title": "(GMT +07:00) Asia/Vientiane",
            "timezone": "Asia/Vientiane"
          },
          {
            "title": "(GMT +10:00) Asia/Vladivostok",
            "timezone": "Asia/Vladivostok"
          },
          {
            "title": "(GMT +09:00) Asia/Yakutsk",
            "timezone": "Asia/Yakutsk"
          },
          {
            "title": "(GMT +06:30) Asia/Yangon",
            "timezone": "Asia/Yangon"
          },
          {
            "title": "(GMT +05:00) Asia/Yekaterinburg",
            "timezone": "Asia/Yekaterinburg"
          },
          {
            "title": "(GMT +04:00) Asia/Yerevan",
            "timezone": "Asia/Yerevan"
          },
          {
            "title": "(GMT -01:00) Atlantic/Azores",
            "timezone": "Atlantic/Azores"
          },
          {
            "title": "(GMT -04:00) Atlantic/Bermuda",
            "timezone": "Atlantic/Bermuda"
          },
          {
            "title": "(GMT +00:00) Atlantic/Canary",
            "timezone": "Atlantic/Canary"
          },
          {
            "title": "(GMT -01:00) Atlantic/Cape_Verde",
            "timezone": "Atlantic/Cape_Verde"
          },
          {
            "title": "(GMT +00:00) Atlantic/Faroe",
            "timezone": "Atlantic/Faroe"
          },
          {
            "title": "(GMT +00:00) Atlantic/Madeira",
            "timezone": "Atlantic/Madeira"
          },
          {
            "title": "(GMT +00:00) Atlantic/Reykjavik",
            "timezone": "Atlantic/Reykjavik"
          },
          {
            "title": "(GMT -02:00) Atlantic/South_Georgia",
            "timezone": "Atlantic/South_Georgia"
          },
          {
            "title": "(GMT +00:00) Atlantic/St_Helena",
            "timezone": "Atlantic/St_Helena"
          },
          {
            "title": "(GMT -03:00) Atlantic/Stanley",
            "timezone": "Atlantic/Stanley"
          },
          {
            "title": "(GMT +10:30) Australia/Adelaide",
            "timezone": "Australia/Adelaide"
          },
          {
            "title": "(GMT +10:00) Australia/Brisbane",
            "timezone": "Australia/Brisbane"
          },
          {
            "title": "(GMT +10:30) Australia/Broken_Hill",
            "timezone": "Australia/Broken_Hill"
          },
          {
            "title": "(GMT +09:30) Australia/Darwin",
            "timezone": "Australia/Darwin"
          },
          {
            "title": "(GMT +08:45) Australia/Eucla",
            "timezone": "Australia/Eucla"
          },
          {
            "title": "(GMT +11:00) Australia/Hobart",
            "timezone": "Australia/Hobart"
          },
          {
            "title": "(GMT +10:00) Australia/Lindeman",
            "timezone": "Australia/Lindeman"
          },
          {
            "title": "(GMT +11:00) Australia/Lord_Howe",
            "timezone": "Australia/Lord_Howe"
          },
          {
            "title": "(GMT +11:00) Australia/Melbourne",
            "timezone": "Australia/Melbourne"
          },
          {
            "title": "(GMT +08:00) Australia/Perth",
            "timezone": "Australia/Perth"
          },
          {
            "title": "(GMT +11:00) Australia/Sydney",
            "timezone": "Australia/Sydney"
          },
          {
            "title": "(GMT +01:00) Europe/Amsterdam",
            "timezone": "Europe/Amsterdam"
          },
          {
            "title": "(GMT +01:00) Europe/Andorra",
            "timezone": "Europe/Andorra"
          },
          {
            "title": "(GMT +04:00) Europe/Astrakhan",
            "timezone": "Europe/Astrakhan"
          },
          {
            "title": "(GMT +02:00) Europe/Athens",
            "timezone": "Europe/Athens"
          },
          {
            "title": "(GMT +01:00) Europe/Belgrade",
            "timezone": "Europe/Belgrade"
          },
          {
            "title": "(GMT +01:00) Europe/Berlin",
            "timezone": "Europe/Berlin"
          },
          {
            "title": "(GMT +01:00) Europe/Bratislava",
            "timezone": "Europe/Bratislava"
          },
          {
            "title": "(GMT +01:00) Europe/Brussels",
            "timezone": "Europe/Brussels"
          },
          {
            "title": "(GMT +02:00) Europe/Bucharest",
            "timezone": "Europe/Bucharest"
          },
          {
            "title": "(GMT +01:00) Europe/Budapest",
            "timezone": "Europe/Budapest"
          },
          {
            "title": "(GMT +01:00) Europe/Busingen",
            "timezone": "Europe/Busingen"
          },
          {
            "title": "(GMT +02:00) Europe/Chisinau",
            "timezone": "Europe/Chisinau"
          },
          {
            "title": "(GMT +01:00) Europe/Copenhagen",
            "timezone": "Europe/Copenhagen"
          },
          {
            "title": "(GMT +00:00) Europe/Dublin",
            "timezone": "Europe/Dublin"
          },
          {
            "title": "(GMT +01:00) Europe/Gibraltar",
            "timezone": "Europe/Gibraltar"
          },
          {
            "title": "(GMT +00:00) Europe/Guernsey",
            "timezone": "Europe/Guernsey"
          },
          {
            "title": "(GMT +02:00) Europe/Helsinki",
            "timezone": "Europe/Helsinki"
          },
          {
            "title": "(GMT +00:00) Europe/Isle_of_Man",
            "timezone": "Europe/Isle_of_Man"
          },
          {
            "title": "(GMT +03:00) Europe/Istanbul",
            "timezone": "Europe/Istanbul"
          },
          {
            "title": "(GMT +00:00) Europe/Jersey",
            "timezone": "Europe/Jersey"
          },
          {
            "title": "(GMT +02:00) Europe/Kaliningrad",
            "timezone": "Europe/Kaliningrad"
          },
          {
            "title": "(GMT +02:00) Europe/Kiev",
            "timezone": "Europe/Kiev"
          },
          {
            "title": "(GMT +03:00) Europe/Kirov",
            "timezone": "Europe/Kirov"
          },
          {
            "title": "(GMT +00:00) Europe/Lisbon",
            "timezone": "Europe/Lisbon"
          },
          {
            "title": "(GMT +01:00) Europe/Ljubljana",
            "timezone": "Europe/Ljubljana"
          },
          {
            "title": "(GMT +00:00) Europe/London",
            "timezone": "Europe/London"
          },
          {
            "title": "(GMT +01:00) Europe/Luxembourg",
            "timezone": "Europe/Luxembourg"
          },
          {
            "title": "(GMT +01:00) Europe/Madrid",
            "timezone": "Europe/Madrid"
          },
          {
            "title": "(GMT +01:00) Europe/Malta",
            "timezone": "Europe/Malta"
          },
          {
            "title": "(GMT +02:00) Europe/Mariehamn",
            "timezone": "Europe/Mariehamn"
          },
          {
            "title": "(GMT +03:00) Europe/Minsk",
            "timezone": "Europe/Minsk"
          },
          {
            "title": "(GMT +01:00) Europe/Monaco",
            "timezone": "Europe/Monaco"
          },
          {
            "title": "(GMT +03:00) Europe/Moscow",
            "timezone": "Europe/Moscow"
          },
          {
            "title": "(GMT +01:00) Europe/Oslo",
            "timezone": "Europe/Oslo"
          },
          {
            "title": "(GMT +01:00) Europe/Paris",
            "timezone": "Europe/Paris"
          },
          {
            "title": "(GMT +01:00) Europe/Podgorica",
            "timezone": "Europe/Podgorica"
          },
          {
            "title": "(GMT +01:00) Europe/Prague",
            "timezone": "Europe/Prague"
          },
          {
            "title": "(GMT +02:00) Europe/Riga",
            "timezone": "Europe/Riga"
          },
          {
            "title": "(GMT +01:00) Europe/Rome",
            "timezone": "Europe/Rome"
          },
          {
            "title": "(GMT +04:00) Europe/Samara",
            "timezone": "Europe/Samara"
          },
          {
            "title": "(GMT +01:00) Europe/San_Marino",
            "timezone": "Europe/San_Marino"
          },
          {
            "title": "(GMT +01:00) Europe/Sarajevo",
            "timezone": "Europe/Sarajevo"
          },
          {
            "title": "(GMT +04:00) Europe/Saratov",
            "timezone": "Europe/Saratov"
          },
          {
            "title": "(GMT +03:00) Europe/Simferopol",
            "timezone": "Europe/Simferopol"
          },
          {
            "title": "(GMT +01:00) Europe/Skopje",
            "timezone": "Europe/Skopje"
          },
          {
            "title": "(GMT +02:00) Europe/Sofia",
            "timezone": "Europe/Sofia"
          },
          {
            "title": "(GMT +01:00) Europe/Stockholm",
            "timezone": "Europe/Stockholm"
          },
          {
            "title": "(GMT +02:00) Europe/Tallinn",
            "timezone": "Europe/Tallinn"
          },
          {
            "title": "(GMT +01:00) Europe/Tirane",
            "timezone": "Europe/Tirane"
          },
          {
            "title": "(GMT +04:00) Europe/Ulyanovsk",
            "timezone": "Europe/Ulyanovsk"
          },
          {
            "title": "(GMT +02:00) Europe/Uzhgorod",
            "timezone": "Europe/Uzhgorod"
          },
          {
            "title": "(GMT +01:00) Europe/Vaduz",
            "timezone": "Europe/Vaduz"
          },
          {
            "title": "(GMT +01:00) Europe/Vatican",
            "timezone": "Europe/Vatican"
          },
          {
            "title": "(GMT +01:00) Europe/Vienna",
            "timezone": "Europe/Vienna"
          },
          {
            "title": "(GMT +02:00) Europe/Vilnius",
            "timezone": "Europe/Vilnius"
          },
          {
            "title": "(GMT +03:00) Europe/Volgograd",
            "timezone": "Europe/Volgograd"
          },
          {
            "title": "(GMT +01:00) Europe/Warsaw",
            "timezone": "Europe/Warsaw"
          },
          {
            "title": "(GMT +01:00) Europe/Zagreb",
            "timezone": "Europe/Zagreb"
          },
          {
            "title": "(GMT +02:00) Europe/Zaporozhye",
            "timezone": "Europe/Zaporozhye"
          },
          {
            "title": "(GMT +01:00) Europe/Zurich",
            "timezone": "Europe/Zurich"
          },
          {
            "title": "(GMT +03:00) Indian/Antananarivo",
            "timezone": "Indian/Antananarivo"
          },
          {
            "title": "(GMT +06:00) Indian/Chagos",
            "timezone": "Indian/Chagos"
          },
          {
            "title": "(GMT +07:00) Indian/Christmas",
            "timezone": "Indian/Christmas"
          },
          {
            "title": "(GMT +06:30) Indian/Cocos",
            "timezone": "Indian/Cocos"
          },
          {
            "title": "(GMT +03:00) Indian/Comoro",
            "timezone": "Indian/Comoro"
          },
          {
            "title": "(GMT +05:00) Indian/Kerguelen",
            "timezone": "Indian/Kerguelen"
          },
          {
            "title": "(GMT +04:00) Indian/Mahe",
            "timezone": "Indian/Mahe"
          },
          {
            "title": "(GMT +05:00) Indian/Maldives",
            "timezone": "Indian/Maldives"
          },
          {
            "title": "(GMT +04:00) Indian/Mauritius",
            "timezone": "Indian/Mauritius"
          },
          {
            "title": "(GMT +03:00) Indian/Mayotte",
            "timezone": "Indian/Mayotte"
          },
          {
            "title": "(GMT +04:00) Indian/Reunion",
            "timezone": "Indian/Reunion"
          },
          {
            "title": "(GMT +14:00) Pacific/Apia",
            "timezone": "Pacific/Apia"
          },
          {
            "title": "(GMT +13:00) Pacific/Auckland",
            "timezone": "Pacific/Auckland"
          },
          {
            "title": "(GMT +11:00) Pacific/Bougainville",
            "timezone": "Pacific/Bougainville"
          },
          {
            "title": "(GMT +13:45) Pacific/Chatham",
            "timezone": "Pacific/Chatham"
          },
          {
            "title": "(GMT +10:00) Pacific/Chuuk",
            "timezone": "Pacific/Chuuk"
          },
          {
            "title": "(GMT -05:00) Pacific/Easter",
            "timezone": "Pacific/Easter"
          },
          {
            "title": "(GMT +11:00) Pacific/Efate",
            "timezone": "Pacific/Efate"
          },
          {
            "title": "(GMT +13:00) Pacific/Enderbury",
            "timezone": "Pacific/Enderbury"
          },
          {
            "title": "(GMT +13:00) Pacific/Fakaofo",
            "timezone": "Pacific/Fakaofo"
          },
          {
            "title": "(GMT +12:00) Pacific/Fiji",
            "timezone": "Pacific/Fiji"
          },
          {
            "title": "(GMT +12:00) Pacific/Funafuti",
            "timezone": "Pacific/Funafuti"
          },
          {
            "title": "(GMT -06:00) Pacific/Galapagos",
            "timezone": "Pacific/Galapagos"
          },
          {
            "title": "(GMT -09:00) Pacific/Gambier",
            "timezone": "Pacific/Gambier"
          },
          {
            "title": "(GMT +11:00) Pacific/Guadalcanal",
            "timezone": "Pacific/Guadalcanal"
          },
          {
            "title": "(GMT +10:00) Pacific/Guam",
            "timezone": "Pacific/Guam"
          },
          {
            "title": "(GMT -10:00) Pacific/Honolulu",
            "timezone": "Pacific/Honolulu"
          },
          {
            "title": "(GMT +14:00) Pacific/Kiritimati",
            "timezone": "Pacific/Kiritimati"
          },
          {
            "title": "(GMT +11:00) Pacific/Kosrae",
            "timezone": "Pacific/Kosrae"
          },
          {
            "title": "(GMT +12:00) Pacific/Kwajalein",
            "timezone": "Pacific/Kwajalein"
          },
          {
            "title": "(GMT +12:00) Pacific/Majuro",
            "timezone": "Pacific/Majuro"
          },
          {
            "title": "(GMT -09:30) Pacific/Marquesas",
            "timezone": "Pacific/Marquesas"
          },
          {
            "title": "(GMT -11:00) Pacific/Midway",
            "timezone": "Pacific/Midway"
          },
          {
            "title": "(GMT +12:00) Pacific/Nauru",
            "timezone": "Pacific/Nauru"
          },
          {
            "title": "(GMT -11:00) Pacific/Niue",
            "timezone": "Pacific/Niue"
          },
          {
            "title": "(GMT +12:00) Pacific/Norfolk",
            "timezone": "Pacific/Norfolk"
          },
          {
            "title": "(GMT +11:00) Pacific/Noumea",
            "timezone": "Pacific/Noumea"
          },
          {
            "title": "(GMT -11:00) Pacific/Pago_Pago",
            "timezone": "Pacific/Pago_Pago"
          },
          {
            "title": "(GMT +09:00) Pacific/Palau",
            "timezone": "Pacific/Palau"
          },
          {
            "title": "(GMT -08:00) Pacific/Pitcairn",
            "timezone": "Pacific/Pitcairn"
          },
          {
            "title": "(GMT +11:00) Pacific/Pohnpei",
            "timezone": "Pacific/Pohnpei"
          },
          {
            "title": "(GMT +10:00) Pacific/Port_Moresby",
            "timezone": "Pacific/Port_Moresby"
          },
          {
            "title": "(GMT -10:00) Pacific/Rarotonga",
            "timezone": "Pacific/Rarotonga"
          },
          {
            "title": "(GMT +10:00) Pacific/Saipan",
            "timezone": "Pacific/Saipan"
          },
          {
            "title": "(GMT -10:00) Pacific/Tahiti",
            "timezone": "Pacific/Tahiti"
          },
          {
            "title": "(GMT +12:00) Pacific/Tarawa",
            "timezone": "Pacific/Tarawa"
          },
          {
            "title": "(GMT +13:00) Pacific/Tongatapu",
            "timezone": "Pacific/Tongatapu"
          },
          {
            "title": "(GMT +12:00) Pacific/Wake",
            "timezone": "Pacific/Wake"
          },
          {
            "title": "(GMT +12:00) Pacific/Wallis",
            "timezone": "Pacific/Wallis"
          },
          {
            "title": "(GMT +00:00) UTC",
            "timezone": "UTC"
          }
        ];
    }

    static languages() {
        return [
            {
                fullName: "English",
                short: "en"
            },
            {
                fullName: "French (Coming Soon)",
                short: "fr"
            }
        ]
    }

    static allLanguages(){
        return [
        {"code":"ab","name":"Abkhaz","nativeName":"аҧсуа"},
        {"code":"aa","name":"Afar","nativeName":"Afaraf"},
        {"code":"af","name":"Afrikaans","nativeName":"Afrikaans"},
        {"code":"ak","name":"Akan","nativeName":"Akan"},
        {"code":"sq","name":"Albanian","nativeName":"Shqip"},
        {"code":"am","name":"Amharic","nativeName":"አማርኛ"},
        {"code":"ar","name":"Arabic","nativeName":"العربية"},
        {"code":"an","name":"Aragonese","nativeName":"Aragonés"},
        {"code":"hy","name":"Armenian","nativeName":"Հայերեն"},
        {"code":"as","name":"Assamese","nativeName":"অসমীয়া"},
        {"code":"av","name":"Avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
        {"code":"ae","name":"Avestan","nativeName":"avesta"},
        {"code":"ay","name":"Aymara","nativeName":"aymar aru"},
        {"code":"az","name":"Azerbaijani","nativeName":"azərbaycan dili"},
        {"code":"bm","name":"Bambara","nativeName":"bamanankan"},
        {"code":"ba","name":"Bashkir","nativeName":"башҡорт теле"},
        {"code":"eu","name":"Basque","nativeName":"euskara, euskera"},
        {"code":"be","name":"Belarusian","nativeName":"Беларуская"},
        {"code":"bn","name":"Bengali","nativeName":"বাংলা"},
        {"code":"bh","name":"Bihari","nativeName":"भोजपुरी"},
        {"code":"bi","name":"Bislama","nativeName":"Bislama"},
        {"code":"bs","name":"Bosnian","nativeName":"bosanski jezik"},
        {"code":"br","name":"Breton","nativeName":"brezhoneg"},
        {"code":"bg","name":"Bulgarian","nativeName":"български език"},
        {"code":"my","name":"Burmese","nativeName":"ဗမာစာ"},
        {"code":"ca","name":"Catalan; Valencian","nativeName":"Català"},
        {"code":"ch","name":"Chamorro","nativeName":"Chamoru"},
        {"code":"ce","name":"Chechen","nativeName":"нохчийн мотт"},
        {"code":"ny","name":"Chichewa; Chewa; Nyanja","nativeName":"chiCheŵa, chinyanja"},
        {"code":"zh","name":"Chinese","nativeName":"中文 (Zhōngwén), 汉语, 漢語"},
        {"code":"cv","name":"Chuvash","nativeName":"чӑваш чӗлхи"},
        {"code":"kw","name":"Cornish","nativeName":"Kernewek"},
        {"code":"co","name":"Corsican","nativeName":"corsu, lingua corsa"},
        {"code":"cr","name":"Cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
        {"code":"hr","name":"Croatian","nativeName":"hrvatski"},
        {"code":"cs","name":"Czech","nativeName":"česky, čeština"},
        {"code":"da","name":"Danish","nativeName":"dansk"},
        {"code":"dv","name":"Divehi; Dhivehi; Maldivian;","nativeName":"ދިވެހި"},
        {"code":"nl","name":"Dutch","nativeName":"Nederlands, Vlaams"},
        {"code":"en","name":"English","nativeName":"English"},
        {"code":"eo","name":"Esperanto","nativeName":"Esperanto"},
        {"code":"et","name":"Estonian","nativeName":"eesti, eesti keel"},
        {"code":"ee","name":"Ewe","nativeName":"Eʋegbe"},
        {"code":"fo","name":"Faroese","nativeName":"føroyskt"},
        {"code":"fj","name":"Fijian","nativeName":"vosa Vakaviti"},
        {"code":"fi","name":"Finnish","nativeName":"suomi, suomen kieli"},
        {"code":"fr","name":"French","nativeName":"français, langue française"},
        {"code":"ff","name":"Fula; Fulah; Pulaar; Pular","nativeName":"Fulfulde, Pulaar, Pular"},
        {"code":"gl","name":"Galician","nativeName":"Galego"},
        {"code":"ka","name":"Georgian","nativeName":"ქართული"},
        {"code":"de","name":"German","nativeName":"Deutsch"},
        {"code":"el","name":"Greek, Modern","nativeName":"Ελληνικά"},
        {"code":"gn","name":"Guaraní","nativeName":"Avañeẽ"},
        {"code":"gu","name":"Gujarati","nativeName":"ગુજરાતી"},
        {"code":"ht","name":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
        {"code":"ha","name":"Hausa","nativeName":"Hausa, هَوُسَ"},
        {"code":"he","name":"Hebrew (modern)","nativeName":"עברית"},
        {"code":"hz","name":"Herero","nativeName":"Otjiherero"},
        {"code":"hi","name":"Hindi","nativeName":"हिन्दी, हिंदी"},
        {"code":"ho","name":"Hiri Motu","nativeName":"Hiri Motu"},
        {"code":"hu","name":"Hungarian","nativeName":"Magyar"},
        {"code":"ia","name":"Interlingua","nativeName":"Interlingua"},
        {"code":"id","name":"Indonesian","nativeName":"Bahasa Indonesia"},
        {"code":"ie","name":"Interlingue","nativeName":"Originally called Occidental; then Interlingue after WWII"},
        {"code":"ga","name":"Irish","nativeName":"Gaeilge"},
        {"code":"ig","name":"Igbo","nativeName":"Asụsụ Igbo"},
        {"code":"ik","name":"Inupiaq","nativeName":"Iñupiaq, Iñupiatun"},
        {"code":"io","name":"Ido","nativeName":"Ido"},
        {"code":"is","name":"Icelandic","nativeName":"Íslenska"},
        {"code":"it","name":"Italian","nativeName":"Italiano"},
        {"code":"iu","name":"Inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
        {"code":"ja","name":"Japanese","nativeName":"日本語 (にほんご／にっぽんご)"},
        {"code":"jv","name":"Javanese","nativeName":"basa Jawa"},
        {"code":"kl","name":"Kalaallisut, Greenlandic","nativeName":"kalaallisut, kalaallit oqaasii"},
        {"code":"kn","name":"Kannada","nativeName":"ಕನ್ನಡ"},
        {"code":"kr","name":"Kanuri","nativeName":"Kanuri"},
        {"code":"ks","name":"Kashmiri","nativeName":"कश्मीरी, كشميري‎"},
        {"code":"kk","name":"Kazakh","nativeName":"Қазақ тілі"},
        {"code":"km","name":"Khmer","nativeName":"ភាសាខ្មែរ"},
        {"code":"ki","name":"Kikuyu, Gikuyu","nativeName":"Gĩkũyũ"},
        {"code":"rw","name":"Kinyarwanda","nativeName":"Ikinyarwanda"},
        {"code":"ky","name":"Kirghiz, Kyrgyz","nativeName":"кыргыз тили"},
        {"code":"kv","name":"Komi","nativeName":"коми кыв"},
        {"code":"kg","name":"Kongo","nativeName":"KiKongo"},
        {"code":"ko","name":"Korean","nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"},
        {"code":"ku","name":"Kurdish","nativeName":"Kurdî, كوردی‎"},
        {"code":"kj","name":"Kwanyama, Kuanyama","nativeName":"Kuanyama"},
        {"code":"la","name":"Latin","nativeName":"latine, lingua latina"},
        {"code":"lb","name":"Luxembourgish, Letzeburgesch","nativeName":"Lëtzebuergesch"},
        {"code":"lg","name":"Luganda","nativeName":"Luganda"},
        {"code":"li","name":"Limburgish, Limburgan, Limburger","nativeName":"Limburgs"},
        {"code":"ln","name":"Lingala","nativeName":"Lingála"},
        {"code":"lo","name":"Lao","nativeName":"ພາສາລາວ"},
        {"code":"lt","name":"Lithuanian","nativeName":"lietuvių kalba"},
        {"code":"lu","name":"Luba-Katanga","nativeName":""},
        {"code":"lv","name":"Latvian","nativeName":"latviešu valoda"},
        {"code":"gv","name":"Manx","nativeName":"Gaelg, Gailck"},
        {"code":"mk","name":"Macedonian","nativeName":"македонски јазик"},
        {"code":"mg","name":"Malagasy","nativeName":"Malagasy fiteny"},
        {"code":"ms","name":"Malay","nativeName":"bahasa Melayu, بهاس ملايو‎"},
        {"code":"ml","name":"Malayalam","nativeName":"മലയാളം"},
        {"code":"mt","name":"Maltese","nativeName":"Malti"},
        {"code":"mi","name":"Māori","nativeName":"te reo Māori"},
        {"code":"mr","name":"Marathi (Marāṭhī)","nativeName":"मराठी"},
        {"code":"mh","name":"Marshallese","nativeName":"Kajin M̧ajeļ"},
        {"code":"mn","name":"Mongolian","nativeName":"монгол"},
        {"code":"na","name":"Nauru","nativeName":"Ekakairũ Naoero"},
        {"code":"nv","name":"Navajo, Navaho","nativeName":"Diné bizaad, Dinékʼehǰí"},
        {"code":"nb","name":"Norwegian Bokmål","nativeName":"Norsk bokmål"},
        {"code":"nd","name":"North Ndebele","nativeName":"isiNdebele"},
        {"code":"ne","name":"Nepali","nativeName":"नेपाली"},
        {"code":"ng","name":"Ndonga","nativeName":"Owambo"},
        {"code":"nn","name":"Norwegian Nynorsk","nativeName":"Norsk nynorsk"},
        {"code":"no","name":"Norwegian","nativeName":"Norsk"},
        {"code":"ii","name":"Nuosu","nativeName":"ꆈꌠ꒿ Nuosuhxop"},
        {"code":"nr","name":"South Ndebele","nativeName":"isiNdebele"},
        {"code":"oc","name":"Occitan","nativeName":"Occitan"},
        {"code":"oj","name":"Ojibwe, Ojibwa","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
        {"code":"cu","name":"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic","nativeName":"ѩзыкъ словѣньскъ"},
        {"code":"om","name":"Oromo","nativeName":"Afaan Oromoo"},
        {"code":"or","name":"Oriya","nativeName":"ଓଡ଼ିଆ"},
        {"code":"os","name":"Ossetian, Ossetic","nativeName":"ирон æвзаг"},
        {"code":"pa","name":"Panjabi, Punjabi","nativeName":"ਪੰਜਾਬੀ, پنجابی‎"},
        {"code":"pi","name":"Pāli","nativeName":"पाऴि"},
        {"code":"fa","name":"Persian","nativeName":"فارسی"},
        {"code":"pl","name":"Polish","nativeName":"polski"},
        {"code":"ps","name":"Pashto","nativeName":"پښتو"},
        {"code":"pt","name":"Portuguese","nativeName":"Português"},
        {"code":"qu","name":"Quechua","nativeName":"Runa Simi, Kichwa"},
        {"code":"rm","name":"Romansh","nativeName":"rumantsch grischun"},
        {"code":"rn","name":"Kirundi","nativeName":"kiRundi"},
        {"code":"ro","name":"Romanian, Moldavian, Moldovan","nativeName":"română"},
        {"code":"ru","name":"Russian","nativeName":"русский язык"},
        {"code":"sa","name":"Sanskrit (Saṁskṛta)","nativeName":"संस्कृतम्"},
        {"code":"sc","name":"Sardinian","nativeName":"sardu"},
        {"code":"sd","name":"Sindhi","nativeName":"सिन्धी, سنڌي، سندھی‎"},
        {"code":"se","name":"Northern Sami","nativeName":"Davvisámegiella"},
        {"code":"sm","name":"Samoan","nativeName":"gagana faa Samoa"},
        {"code":"sg","name":"Sango","nativeName":"yângâ tî sängö"},
        {"code":"sr","name":"Serbian","nativeName":"српски језик"},
        {"code":"gd","name":"Scottish Gaelic; Gaelic","nativeName":"Gàidhlig"},
        {"code":"sn","name":"Shona","nativeName":"chiShona"},
        {"code":"si","name":"Sinhala, Sinhalese","nativeName":"සිංහල"},
        {"code":"sk","name":"Slovak","nativeName":"slovenčina"},
        {"code":"sl","name":"Slovene","nativeName":"slovenščina"},
        {"code":"so","name":"Somali","nativeName":"Soomaaliga, af Soomaali"},
        {"code":"st","name":"Southern Sotho","nativeName":"Sesotho"},
        {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"},
        {"code":"su","name":"Sundanese","nativeName":"Basa Sunda"},
        {"code":"sw","name":"Swahili","nativeName":"Kiswahili"},
        {"code":"ss","name":"Swati","nativeName":"SiSwati"},
        {"code":"sv","name":"Swedish","nativeName":"svenska"},
        {"code":"ta","name":"Tamil","nativeName":"தமிழ்"},
        {"code":"te","name":"Telugu","nativeName":"తెలుగు"},
        {"code":"tg","name":"Tajik","nativeName":"тоҷикӣ, toğikī, تاجیکی‎"},
        {"code":"th","name":"Thai","nativeName":"ไทย"},
        {"code":"ti","name":"Tigrinya","nativeName":"ትግርኛ"},
        {"code":"bo","name":"Tibetan Standard, Tibetan, Central","nativeName":"བོད་ཡིག"},
        {"code":"tk","name":"Turkmen","nativeName":"Türkmen, Түркмен"},
        {"code":"tl","name":"Tagalog","nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
        {"code":"tn","name":"Tswana","nativeName":"Setswana"},
        {"code":"to","name":"Tonga (Tonga Islands)","nativeName":"faka Tonga"},
        {"code":"tr","name":"Turkish","nativeName":"Türkçe"},
        {"code":"ts","name":"Tsonga","nativeName":"Xitsonga"},
        {"code":"tt","name":"Tatar","nativeName":"татарча, tatarça, تاتارچا‎"},
        {"code":"tw","name":"Twi","nativeName":"Twi"},
        {"code":"ty","name":"Tahitian","nativeName":"Reo Tahiti"},
        {"code":"ug","name":"Uighur, Uyghur","nativeName":"Uyƣurqə, ئۇيغۇرچە‎"},
        {"code":"uk","name":"Ukrainian","nativeName":"українська"},
        {"code":"ur","name":"Urdu","nativeName":"اردو"},
        {"code":"uz","name":"Uzbek","nativeName":"zbek, Ўзбек, أۇزبېك‎"},
        {"code":"ve","name":"Venda","nativeName":"Tshivenḓa"},
        {"code":"vi","name":"Vietnamese","nativeName":"Tiếng Việt"},
        {"code":"vo","name":"Volapük","nativeName":"Volapük"},
        {"code":"wa","name":"Walloon","nativeName":"Walon"},
        {"code":"cy","name":"Welsh","nativeName":"Cymraeg"},
        {"code":"wo","name":"Wolof","nativeName":"Wollof"},
        {"code":"fy","name":"Western Frisian","nativeName":"Frysk"},
        {"code":"xh","name":"Xhosa","nativeName":"isiXhosa"},
        {"code":"yi","name":"Yiddish","nativeName":"ייִדיש"},
        {"code":"yo","name":"Yoruba","nativeName":"Yorùbá"},
        {"code":"za","name":"Zhuang, Chuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"}
      ];
    }

    static currencies() {
        return [
            {
                "USD": {
                    "symbol": "$",
                    "name": "US Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "USD",
                    "name_plural": "US dollars"
                },
                "CAD": {
                    "symbol": "CA$",
                    "name": "Canadian Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "CAD",
                    "name_plural": "Canadian dollars"
                },
                "EUR": {
                    "symbol": "€",
                    "name": "Euro",
                    "symbol_native": "€",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "EUR",
                    "name_plural": "euros"
                },
                "AED": {
                    "symbol": "AED",
                    "name": "United Arab Emirates Dirham",
                    "symbol_native": "د.إ.‏",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "AED",
                    "name_plural": "UAE dirhams"
                },
                "AFN": {
                    "symbol": "Af",
                    "name": "Afghan Afghani",
                    "symbol_native": "؋",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "AFN",
                    "name_plural": "Afghan Afghanis"
                },
                "ALL": {
                    "symbol": "ALL",
                    "name": "Albanian Lek",
                    "symbol_native": "Lek",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "ALL",
                    "name_plural": "Albanian lekë"
                },
                "AMD": {
                    "symbol": "AMD",
                    "name": "Armenian Dram",
                    "symbol_native": "դր.",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "AMD",
                    "name_plural": "Armenian drams"
                },
                "ANG": {
                    "symbol": "ƒ",
                    "name": "Netherlands Antillean guilder",
                    "symbol_native": "ƒ",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "ANG",
                    "name_plural": "Netherlands Antillean guilder"
                },
                "AOA": {
                    "symbol": "Kz",
                    "name": "Angolan Kwanza",
                    "symbol_native": "Kz",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "AOA",
                    "name_plural": "Angolan Kwanzas"
                },
                "ARS": {
                    "symbol": "AR$",
                    "name": "Argentine Peso",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "ARS",
                    "name_plural": "Argentine pesos"
                },
                "AUD": {
                    "symbol": "AU$",
                    "name": "Australian Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "AUD",
                    "name_plural": "Australian dollars"
                },
                "AZN": {
                    "symbol": "man.",
                    "name": "Azerbaijani Manat",
                    "symbol_native": "ман.",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "AZN",
                    "name_plural": "Azerbaijani manats"
                },
                "BAM": {
                    "symbol": "KM",
                    "name": "Bosnia-Herzegovina Convertible Mark",
                    "symbol_native": "KM",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BAM",
                    "name_plural": "Bosnia-Herzegovina convertible marks"
                },
                "BDT": {
                    "symbol": "Tk",
                    "name": "Bangladeshi Taka",
                    "symbol_native": "৳",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BDT",
                    "name_plural": "Bangladeshi takas"
                },
                "BGN": {
                    "symbol": "BGN",
                    "name": "Bulgarian Lev",
                    "symbol_native": "лв.",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BGN",
                    "name_plural": "Bulgarian leva"
                },
                "BHD": {
                    "symbol": "BD",
                    "name": "Bahraini Dinar",
                    "symbol_native": "د.ب.‏",
                    "decimal_digits": 3,
                    "rounding": 0,
                    "code": "BHD",
                    "name_plural": "Bahraini dinars"
                },
                "BIF": {
                    "symbol": "FBu",
                    "name": "Burundian Franc",
                    "symbol_native": "FBu",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "BIF",
                    "name_plural": "Burundian francs"
                },
                "BND": {
                    "symbol": "BN$",
                    "name": "Brunei Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BND",
                    "name_plural": "Brunei dollars"
                },
                "BMD": {
                    "symbol": "$",
                    "name": "Bermudian Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BMD",
                    "name_plural": "Bermudian Dollar"
                },
                "BOB": {
                    "symbol": "Bs",
                    "name": "Bolivian Boliviano",
                    "symbol_native": "Bs",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BOB",
                    "name_plural": "Bolivian bolivianos"
                },
                "BRL": {
                    "symbol": "R$",
                    "name": "Brazilian Real",
                    "symbol_native": "R$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BRL",
                    "name_plural": "Brazilian reals"
                },
                "BTN": {
                    "symbol": "Nu.",
                    "name": "Bhutanese Ngultrum",
                    "symbol_native": "Nu.",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BTN",
                    "name_plural": "Bhutanese Ngultrum"
                },
                "BWP": {
                    "symbol": "BWP",
                    "name": "Botswanan Pula",
                    "symbol_native": "P",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BWP",
                    "name_plural": "Botswanan pulas"
                },
                "BYR": {
                    "symbol": "BYR",
                    "name": "Belarusian Ruble",
                    "symbol_native": "BYR",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "BYR",
                    "name_plural": "Belarusian rubles"
                },
                "BZD": {
                    "symbol": "BZ$",
                    "name": "Belize Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "BZD",
                    "name_plural": "Belize dollars"
                },
                "CDF": {
                    "symbol": "CDF",
                    "name": "Congolese Franc",
                    "symbol_native": "FrCD",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "CDF",
                    "name_plural": "Congolese francs"
                },
                "CHF": {
                    "symbol": "CHF",
                    "name": "Swiss Franc",
                    "symbol_native": "CHF",
                    "decimal_digits": 2,
                    "rounding": 0.05,
                    "code": "CHF",
                    "name_plural": "Swiss francs"
                },
                "CLP": {
                    "symbol": "CL$",
                    "name": "Chilean Peso",
                    "symbol_native": "$",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "CLP",
                    "name_plural": "Chilean pesos"
                },
                "CNY": {
                    "symbol": "CN¥",
                    "name": "Chinese Yuan",
                    "symbol_native": "CN¥",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "CNY",
                    "name_plural": "Chinese yuan"
                },
                "COP": {
                    "symbol": "CO$",
                    "name": "Colombian Peso",
                    "symbol_native": "$",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "COP",
                    "name_plural": "Colombian pesos"
                },
                "CRC": {
                    "symbol": "₡",
                    "name": "Costa Rican Colón",
                    "symbol_native": "₡",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "CRC",
                    "name_plural": "Costa Rican colóns"
                },
                "CVE": {
                    "symbol": "CV$",
                    "name": "Cape Verdean Escudo",
                    "symbol_native": "CV$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "CVE",
                    "name_plural": "Cape Verdean escudos"
                },
                "CZK": {
                    "symbol": "Kč",
                    "name": "Czech Republic Koruna",
                    "symbol_native": "Kč",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "CZK",
                    "name_plural": "Czech Republic korunas"
                },
                "DJF": {
                    "symbol": "Fdj",
                    "name": "Djiboutian Franc",
                    "symbol_native": "Fdj",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "DJF",
                    "name_plural": "Djiboutian francs"
                },
                "DKK": {
                    "symbol": "Dkr",
                    "name": "Danish Krone",
                    "symbol_native": "kr",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "DKK",
                    "name_plural": "Danish kroner"
                },
                "DOP": {
                    "symbol": "RD$",
                    "name": "Dominican Peso",
                    "symbol_native": "RD$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "DOP",
                    "name_plural": "Dominican pesos"
                },
                "DZD": {
                    "symbol": "DA",
                    "name": "Algerian Dinar",
                    "symbol_native": "د.ج.‏",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "DZD",
                    "name_plural": "Algerian dinars"
                },
                "EEK": {
                    "symbol": "Ekr",
                    "name": "Estonian Kroon",
                    "symbol_native": "kr",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "EEK",
                    "name_plural": "Estonian kroons"
                },
                "EGP": {
                    "symbol": "EGP",
                    "name": "Egyptian Pound",
                    "symbol_native": "ج.م.‏",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "EGP",
                    "name_plural": "Egyptian pounds"
                },
                "ERN": {
                    "symbol": "Nfk",
                    "name": "Eritrean Nakfa",
                    "symbol_native": "Nfk",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "ERN",
                    "name_plural": "Eritrean nakfas"
                },
                "ETB": {
                    "symbol": "Br",
                    "name": "Ethiopian Birr",
                    "symbol_native": "Br",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "ETB",
                    "name_plural": "Ethiopian birrs"
                },
                "FKP": {
                    "symbol": "£",
                    "name": "Falkland Island Pound",
                    "symbol_native": "£",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "FKP",
                    "name_plural": "Falkland Island Pounds"
                },
                "GBP": {
                    "symbol": "£",
                    "name": "British Pound Sterling",
                    "symbol_native": "£",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "GBP",
                    "name_plural": "British pounds sterling"
                },
                "GEL": {
                    "symbol": "GEL",
                    "name": "Georgian Lari",
                    "symbol_native": "GEL",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "GEL",
                    "name_plural": "Georgian laris"
                },
                "GHS": {
                    "symbol": "GH₵",
                    "name": "Ghanaian Cedi",
                    "symbol_native": "GH₵",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "GHS",
                    "name_plural": "Ghanaian cedis"
                },
                "GIP": {
                    "symbol": "£",
                    "name": "Gibraltar Pound",
                    "symbol_native": "£",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "GHS",
                    "name_plural": "Gibraltar pound"
                },
                "GNF": {
                    "symbol": "FG",
                    "name": "Guinean Franc",
                    "symbol_native": "FG",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "GNF",
                    "name_plural": "Guinean francs"
                },
                "GTQ": {
                    "symbol": "GTQ",
                    "name": "Guatemalan Quetzal",
                    "symbol_native": "Q",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "GTQ",
                    "name_plural": "Guatemalan quetzals"
                },
                "HKD": {
                    "symbol": "HK$",
                    "name": "Hong Kong Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "HKD",
                    "name_plural": "Hong Kong dollars"
                },
                "HNL": {
                    "symbol": "HNL",
                    "name": "Honduran Lempira",
                    "symbol_native": "L",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "HNL",
                    "name_plural": "Honduran lempiras"
                },
                "HRK": {
                    "symbol": "kn",
                    "name": "Croatian Kuna",
                    "symbol_native": "kn",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "HRK",
                    "name_plural": "Croatian kunas"
                },
                "HUF": {
                    "symbol": "Ft",
                    "name": "Hungarian Forint",
                    "symbol_native": "Ft",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "HUF",
                    "name_plural": "Hungarian forints"
                },
                "IDR": {
                    "symbol": "Rp",
                    "name": "Indonesian Rupiah",
                    "symbol_native": "Rp",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "IDR",
                    "name_plural": "Indonesian rupiahs"
                },
                "ILS": {
                    "symbol": "₪",
                    "name": "Israeli New Sheqel",
                    "symbol_native": "₪",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "ILS",
                    "name_plural": "Israeli new sheqels"
                },
                "INR": {
                    "symbol": "Rs",
                    "name": "Indian Rupee",
                    "symbol_native": "₹",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "INR",
                    "name_plural": "Indian rupees"
                },
                "IQD": {
                    "symbol": "IQD",
                    "name": "Iraqi Dinar",
                    "symbol_native": "د.ع.‏",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "IQD",
                    "name_plural": "Iraqi dinars"
                },
                "IRR": {
                    "symbol": "IRR",
                    "name": "Iranian Rial",
                    "symbol_native": "﷼",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "IRR",
                    "name_plural": "Iranian rials"
                },
                "ISK": {
                    "symbol": "Ikr",
                    "name": "Icelandic Króna",
                    "symbol_native": "kr",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "ISK",
                    "name_plural": "Icelandic krónur"
                },
                "JMD": {
                    "symbol": "J$",
                    "name": "Jamaican Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "JMD",
                    "name_plural": "Jamaican dollars"
                },
                "JOD": {
                    "symbol": "JD",
                    "name": "Jordanian Dinar",
                    "symbol_native": "د.أ.‏",
                    "decimal_digits": 3,
                    "rounding": 0,
                    "code": "JOD",
                    "name_plural": "Jordanian dinars"
                },
                "JPY": {
                    "symbol": "¥",
                    "name": "Japanese Yen",
                    "symbol_native": "￥",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "JPY",
                    "name_plural": "Japanese yen"
                },
                "KES": {
                    "symbol": "с",
                    "name": "Kyrgyzstani som",
                    "symbol_native": "с",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "KES",
                    "name_plural": "Kyrgyzstani som"
                },
                "KGS": {
                    "symbol": "Ksh",
                    "name": "Kyrgyzstani Som",
                    "symbol_native": "Ksh",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "KES",
                    "name_plural": "Kenyan shillings"
                },
                "KHR": {
                    "symbol": "KHR",
                    "name": "Cambodian Riel",
                    "symbol_native": "៛",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "KHR",
                    "name_plural": "Cambodian riels"
                },
                "KMF": {
                    "symbol": "CF",
                    "name": "Comorian Franc",
                    "symbol_native": "FC",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "KMF",
                    "name_plural": "Comorian francs"
                },
                "KRW": {
                    "symbol": "₩",
                    "name": "South Korean Won",
                    "symbol_native": "₩",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "KRW",
                    "name_plural": "South Korean won"
                },
                "KWD": {
                    "symbol": "KD",
                    "name": "Kuwaiti Dinar",
                    "symbol_native": "د.ك.‏",
                    "decimal_digits": 3,
                    "rounding": 0,
                    "code": "KWD",
                    "name_plural": "Kuwaiti dinars"
                },
                "KYD": {
                    "symbol": "$",
                    "name": "Cayman Islands dollar",
                    "symbol_native": "$‏",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "KYD",
                    "name_plural": "Cayman Islands dollarS"
                },
                "KZT": {
                    "symbol": "KZT",
                    "name": "Kazakhstani Tenge",
                    "symbol_native": "тңг.",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "KZT",
                    "name_plural": "Kazakhstani tenges"
                },
                "LAK": {
                    "symbol": "₭",
                    "name": "Lao kip",
                    "symbol_native": "₭‏",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "LAK",
                    "name_plural": "Lao kip"
                },
                "LBP": {
                    "symbol": "LB£",
                    "name": "Lebanese Pound",
                    "symbol_native": "ل.ل.‏",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "LBP",
                    "name_plural": "Lebanese pounds"
                },
                "LKR": {
                    "symbol": "SLRs",
                    "name": "Sri Lankan Rupee",
                    "symbol_native": "SL Re",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "LKR",
                    "name_plural": "Sri Lankan rupees"
                },
                "LRD": {
                    "symbol": "$",
                    "name": "Liberian Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "LRD",
                    "name_plural": "Liberian Dollars"
                },
                "LTL": {
                    "symbol": "Lt",
                    "name": "Lithuanian Litas",
                    "symbol_native": "Lt",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "LTL",
                    "name_plural": "Lithuanian litai"
                },
                "LVL": {
                    "symbol": "Ls",
                    "name": "Latvian Lats",
                    "symbol_native": "Ls",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "LVL",
                    "name_plural": "Latvian lati"
                },
                "LYD": {
                    "symbol": "LD",
                    "name": "Libyan Dinar",
                    "symbol_native": "د.ل.‏",
                    "decimal_digits": 3,
                    "rounding": 0,
                    "code": "LYD",
                    "name_plural": "Libyan dinars"
                },
                "MAD": {
                    "symbol": "MAD",
                    "name": "Moroccan Dirham",
                    "symbol_native": "د.م.‏",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "MAD",
                    "name_plural": "Moroccan dirhams"
                },
                "MDL": {
                    "symbol": "MDL",
                    "name": "Moldovan Leu",
                    "symbol_native": "MDL",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "MDL",
                    "name_plural": "Moldovan lei"
                },
                "MGA": {
                    "symbol": "MGA",
                    "name": "Malagasy Ariary",
                    "symbol_native": "MGA",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "MGA",
                    "name_plural": "Malagasy Ariaries"
                },
                "MKD": {
                    "symbol": "MKD",
                    "name": "Macedonian Denar",
                    "symbol_native": "MKD",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "MKD",
                    "name_plural": "Macedonian denari"
                },
                "MMK": {
                    "symbol": "MMK",
                    "name": "Myanma Kyat",
                    "symbol_native": "K",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "MMK",
                    "name_plural": "Myanma kyats"
                },
                "MOP": {
                    "symbol": "MOP$",
                    "name": "Macanese Pataca",
                    "symbol_native": "MOP$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "MOP",
                    "name_plural": "Macanese patacas"
                },
                "MUR": {
                    "symbol": "MURs",
                    "name": "Mauritian Rupee",
                    "symbol_native": "MURs",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "MUR",
                    "name_plural": "Mauritian rupees"
                },
                "MWK": {
                    "symbol": "MK",
                    "name": "Malawian Kwacha",
                    "symbol_native": "MK",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "MWK",
                    "name_plural": "Malawian Kwacha"
                },
                "MXN": {
                    "symbol": "MX$",
                    "name": "Mexican Peso",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "MXN",
                    "name_plural": "Mexican pesos"
                },
                "MYR": {
                    "symbol": "RM",
                    "name": "Malaysian Ringgit",
                    "symbol_native": "RM",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "MYR",
                    "name_plural": "Malaysian ringgits"
                },
                "MZN": {
                    "symbol": "MTn",
                    "name": "Mozambican Metical",
                    "symbol_native": "MTn",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "MZN",
                    "name_plural": "Mozambican meticals"
                },
                "NAD": {
                    "symbol": "N$",
                    "name": "Namibian Dollar",
                    "symbol_native": "N$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "NAD",
                    "name_plural": "Namibian dollars"
                },
                "NGN": {
                    "symbol": "₦",
                    "name": "Nigerian Naira",
                    "symbol_native": "₦",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "NGN",
                    "name_plural": "Nigerian nairas"
                },
                "NIO": {
                    "symbol": "C$",
                    "name": "Nicaraguan Córdoba",
                    "symbol_native": "C$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "NIO",
                    "name_plural": "Nicaraguan córdobas"
                },
                "NOK": {
                    "symbol": "Nkr",
                    "name": "Norwegian Krone",
                    "symbol_native": "kr",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "NOK",
                    "name_plural": "Norwegian kroner"
                },
                "NPR": {
                    "symbol": "NPRs",
                    "name": "Nepalese Rupee",
                    "symbol_native": "नेरू",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "NPR",
                    "name_plural": "Nepalese rupees"
                },
                "NZD": {
                    "symbol": "NZ$",
                    "name": "New Zealand Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "NZD",
                    "name_plural": "New Zealand dollars"
                },
                "OMR": {
                    "symbol": "OMR",
                    "name": "Omani Rial",
                    "symbol_native": "ر.ع.‏",
                    "decimal_digits": 3,
                    "rounding": 0,
                    "code": "OMR",
                    "name_plural": "Omani rials"
                },
                "PAB": {
                    "symbol": "B/.",
                    "name": "Panamanian Balboa",
                    "symbol_native": "B/.",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "PAB",
                    "name_plural": "Panamanian balboas"
                },
                "PEN": {
                    "symbol": "S/.",
                    "name": "Peruvian Nuevo Sol",
                    "symbol_native": "S/.",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "PEN",
                    "name_plural": "Peruvian nuevos soles"
                },
                "PHP": {
                    "symbol": "₱",
                    "name": "Philippine Peso",
                    "symbol_native": "₱",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "PHP",
                    "name_plural": "Philippine pesos"
                },
                "PKR": {
                    "symbol": "PKRs",
                    "name": "Pakistani Rupee",
                    "symbol_native": "₨",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "PKR",
                    "name_plural": "Pakistani rupees"
                },
                "PLN": {
                    "symbol": "zł",
                    "name": "Polish Zloty",
                    "symbol_native": "zł",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "PLN",
                    "name_plural": "Polish zlotys"
                },
                "PYG": {
                    "symbol": "₲",
                    "name": "Paraguayan Guarani",
                    "symbol_native": "₲",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "PYG",
                    "name_plural": "Paraguayan guaranis"
                },
                "QAR": {
                    "symbol": "QR",
                    "name": "Qatari Rial",
                    "symbol_native": "ر.ق.‏",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "QAR",
                    "name_plural": "Qatari rials"
                },
                "RON": {
                    "symbol": "RON",
                    "name": "Romanian Leu",
                    "symbol_native": "RON",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "RON",
                    "name_plural": "Romanian lei"
                },
                "RSD": {
                    "symbol": "din.",
                    "name": "Serbian Dinar",
                    "symbol_native": "дин.",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "RSD",
                    "name_plural": "Serbian dinars"
                },
                "RUB": {
                    "symbol": "RUB",
                    "name": "Russian Ruble",
                    "symbol_native": "руб.",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "RUB",
                    "name_plural": "Russian rubles"
                },
                "RWF": {
                    "symbol": "RWF",
                    "name": "Rwandan Franc",
                    "symbol_native": "FR",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "RWF",
                    "name_plural": "Rwandan francs"
                },
                "SAR": {
                    "symbol": "SR",
                    "name": "Saudi Riyal",
                    "symbol_native": "ر.س.‏",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "SAR",
                    "name_plural": "Saudi riyals"
                },
                "SBD": {
                    "symbol": "$",
                    "name": "Solomon Islander Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "SBD",
                    "name_plural": "Solomon Islander Dollars"
                },
                "SDG": {
                    "symbol": "SDG",
                    "name": "Sudanese Pound",
                    "symbol_native": "SDG",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "SDG",
                    "name_plural": "Sudanese pounds"
                },
                "SEK": {
                    "symbol": "Skr",
                    "name": "Swedish Krona",
                    "symbol_native": "kr",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "SEK",
                    "name_plural": "Swedish kronor"
                },
                "SGD": {
                    "symbol": "S$",
                    "name": "Singapore Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "SGD",
                    "name_plural": "Singapore dollars"
                },
                "SLL": {
                    "symbol": "Le",
                    "name": "Sierra Leonean Leone",
                    "symbol_native": "Le",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "SLL",
                    "name_plural": "Sierra Leonean Leone"
                },
                "SOS": {
                    "symbol": "Ssh",
                    "name": "Somali Shilling",
                    "symbol_native": "Ssh",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "SOS",
                    "name_plural": "Somali shillings"
                },
                "SSP": {
                    "symbol": "£",
                    "name": "South Sudanese pound",
                    "symbol_native": "£",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "SSP",
                    "name_plural": "South Sudanese pound"
                },
                "STD": {
                    "symbol": "Db",
                    "name": "Sao Tomean Dobra",
                    "symbol_native": "Db",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "STD",
                    "name_plural": "Sao Tomean Dobra"
                },
                "STN": {
                    "symbol": "Db",
                    "name": "Sao Tomean Dobra",
                    "symbol_native": "Db",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "STN",
                    "name_plural": "Sao Tomean Dobra"
                },
                "SYP": {
                    "symbol": "SY£",
                    "name": "Syrian Pound",
                    "symbol_native": "ل.س.‏",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "SYP",
                    "name_plural": "Syrian pounds"
                },
                "SZL": {
                    "symbol": "L",
                    "name": "Swazi Lilangeni",
                    "symbol_native": "L‏",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "SZL",
                    "name_plural": "Swazi Lilangeni"
                },
                "THB": {
                    "symbol": "฿",
                    "name": "Thai Baht",
                    "symbol_native": "฿",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "THB",
                    "name_plural": "Thai baht"
                },
                "TJS": {
                    "symbol": "ЅМ",
                    "name": "Tajikistani Somoni",
                    "symbol_native": "ЅМ",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "THB",
                    "name_plural": "Tajikistani Somoni"
                },
                "TND": {
                    "symbol": "DT",
                    "name": "Tunisian Dinar",
                    "symbol_native": "د.ت.‏",
                    "decimal_digits": 3,
                    "rounding": 0,
                    "code": "TND",
                    "name_plural": "Tunisian dinars"
                },
                "TOP": {
                    "symbol": "T$",
                    "name": "Tongan Paʻanga",
                    "symbol_native": "T$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "TOP",
                    "name_plural": "Tongan paʻanga"
                },
                "TRY": {
                    "symbol": "TL",
                    "name": "Turkish Lira",
                    "symbol_native": "TL",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "TRY",
                    "name_plural": "Turkish Lira"
                },
                "TTD": {
                    "symbol": "TT$",
                    "name": "Trinidad and Tobago Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "TTD",
                    "name_plural": "Trinidad and Tobago dollars"
                },
                "TWD": {
                    "symbol": "NT$",
                    "name": "New Taiwan Dollar",
                    "symbol_native": "NT$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "TWD",
                    "name_plural": "New Taiwan dollars"
                },
                "TZS": {
                    "symbol": "TSh",
                    "name": "Tanzanian Shilling",
                    "symbol_native": "TSh",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "TZS",
                    "name_plural": "Tanzanian shillings"
                },
                "UAH": {
                    "symbol": "₴",
                    "name": "Ukrainian Hryvnia",
                    "symbol_native": "₴",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "UAH",
                    "name_plural": "Ukrainian hryvnias"
                },
                "UGX": {
                    "symbol": "USh",
                    "name": "Ugandan Shilling",
                    "symbol_native": "USh",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "UGX",
                    "name_plural": "Ugandan shillings"
                },
                "UYU": {
                    "symbol": "$U",
                    "name": "Uruguayan Peso",
                    "symbol_native": "$",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "UYU",
                    "name_plural": "Uruguayan pesos"
                },
                "UZS": {
                    "symbol": "UZS",
                    "name": "Uzbekistan Som",
                    "symbol_native": "UZS",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "UZS",
                    "name_plural": "Uzbekistan som"
                },
                "VEF": {
                    "symbol": "Bs.F.",
                    "name": "Venezuelan Bolívar",
                    "symbol_native": "Bs.F.",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "VEF",
                    "name_plural": "Venezuelan bolívars"
                },
                "VND": {
                    "symbol": "₫",
                    "name": "Vietnamese Dong",
                    "symbol_native": "₫",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "VND",
                    "name_plural": "Vietnamese dong"
                },
                "VUV": {
                    "symbol": "Vt",
                    "name": "Ni-Vanuatu Vatu",
                    "symbol_native": "Vt",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "VUV",
                    "name_plural": "Ni-Vanuatu Vatu"
                },
                "XAF": {
                    "symbol": "FCFA",
                    "name": "CFA Franc BEAC",
                    "symbol_native": "FCFA",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "XAF",
                    "name_plural": "CFA francs BEAC"
                },
                "XCD": {
                    "symbol": "$",
                    "name": "East Caribbean Dollar",
                    "symbol_native": "$",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "XCD",
                    "name_plural": "East Caribbean Dollars"
                },
                "XOF": {
                    "symbol": "CFA",
                    "name": "CFA Franc BCEAO",
                    "symbol_native": "CFA",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "XOF",
                    "name_plural": "CFA francs BCEAO"
                },
                "XPF": {
                    "symbol": "Fr",
                    "name": "CFP franc",
                    "symbol_native": "Fr",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "XPF",
                    "name_plural": "CFP franc"
                },
                "YER": {
                    "symbol": "YR",
                    "name": "Yemeni Rial",
                    "symbol_native": "ر.ي.‏",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "YER",
                    "name_plural": "Yemeni rials"
                },
                "ZAR": {
                    "symbol": "R",
                    "name": "South African Rand",
                    "symbol_native": "R",
                    "decimal_digits": 2,
                    "rounding": 0,
                    "code": "ZAR",
                    "name_plural": "South African rand"
                },
                "ZMK": {
                    "symbol": "ZK",
                    "name": "Zambian Kwacha",
                    "symbol_native": "ZK",
                    "decimal_digits": 0,
                    "rounding": 0,
                    "code": "ZMK",
                    "name_plural": "Zambian kwachas"
                }
            }
        ];
    }

    static getCurrencyInfoByCode(currencyCode) {
        let currencies = this.currencies()[0];
        if (currencies[currencyCode]) {
            return currencies[currencyCode];
        }
        return false;
    }

    static isSet(data) {
        return data != undefined && data != '' ? data : "";
    }

    static getNotEmptyValue(data, defaultValue) {
        return data != undefined && data != '' ? data : defaultValue;
    }

    static arrayChunk(array, chunk) {
        let i, j, temparray = [];
        for (i = 0, j = array.length; i < j; i += chunk) {
            temparray.push(array.slice(i, i + chunk));
            // do whatever
        }
        return temparray;
    }

    static arrayUnique($a) {
        return [...new Set($a)];
    }

    static ucFirst(str) {
      var firstLetter = str.substr(0, 1);
      return firstLetter.toUpperCase() + str.substr(1);
    }

    static titleFirstWord(title){
      if(title){
        return title.split(' ')[0];
      }
      return '';
    }

    static titleMaxLen(max, title, ending = '...'){
        if(title && title.length > max){
            return title.substring(0, max)+ending;
        }
        return title;
    }

    static abbreviateNumber(number){

        let SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
        // what tier? (determines SI symbol)
        let tier = Math.log10(Math.abs(number)) / 3 | 0;

        // if zero, we don't need a suffix
        if(tier == 0) return number;

        // get suffix and determine scale
        var suffix = SI_SYMBOL[tier];
        var scale = Math.pow(10, tier * 3);

        // scale the number
        var scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(1) + suffix;
    }

    static isScrolledIntoView(elem){
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset() ? $(elem).offset().top : null;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    /**
     * Replace old data by new data object
     * @param oldData
     * @param newData
     * @return {*}
     */
     static replaceOldDataByNewData(oldData, newData, takeOldDataIfEmpty = false) {
        // console.log("oldData", oldData, "newData", newData);
        if (newData) {
            Object.keys(oldData).map(item => {
                let emptyData = takeOldDataIfEmpty ? oldData[item] : '';
                if (newData[item] || newData[item] == 0) {
                    oldData[item] = newData[item];
                } else {
                    oldData[item] = emptyData
                }
            });
        }
        // console.log("return ", oldData);
        return oldData;
    }


    static generateFormData(data, mediaFields){
        let formData = new FormData();
        Object.keys(data).map((item) => {
            if(mediaFields.indexOf(item) != -1 && typeof data[item] == 'object'){
                $.each(data[item], function (i, file) {
                    formData.append(item, file);
                });
            } else {
                let val = data[item];
                if(typeof val == "object"){
                    for (var i = 0; i < val.length; i++) {
                        formData.append(item+'[]', val[i]);
                    }
                } else {
                    formData.append(item, val);
                }
            }
        });
        return formData;
    }

    static processErrorAlert(err) {
      return this.showErrorSweetAlert(err);
    }

    static processErrorSweetAlert(err) {
      return this.showErrorSweetAlert(err);
    }

    static processErrorString(err){
      return this.showErrorSweetAlert(err, true);
    }

    static processError(thisClass, err, errorMessageField, errorName = 'errors', validator = false) {
        const {errors} = validator ? validator : thisClass.validator;
        let statusCode, error;
        if (err.response && err.response.data.errors) {
            statusCode = err.response.status;
            error = err.response.data.errors;
        } else if (err.response && err.response.data) {
            statusCode = err.response.status;
            error = err.response.data;
        } else {
            // console.log("processError", err);
            statusCode = 'unknown';
            error = err.response;
        }
        // console.log("processError", statusCode, error, errors);
        if (statusCode === 422) {
            let errorItems = [];
            _.forOwn(error, (message, field) => {
                errorItems.push({field:field, msg: message});
            });
            errors.add(errorItems);
        } else if(error && error.message){
          errors.add([{field: 'password', msg: error.message}]);
        } else {
            errors.add([{field: errorMessageField, msg: error}]);
        }
        thisClass.setState({
            isProcessing: false,
            [errorName]: errors
        });
    }

    static addParamsToUrl(url, params, removeHash = false) {
        let oldParams = {};
        if (url && url.indexOf('?') !== -1) {
            let urlArray = url.split("?");
            if (urlArray[1]) {
                let temp = urlArray[1].split("&");
                if (temp) {
                    for (let i = 0; i < temp.length; i++) {
                        let param = temp[i].split("=");
                        if (param) {
                            oldParams[param[0]] = param[1];
                        }
                    }
                }
            }
            url = urlArray[0] + "?";
            oldParams = JSON.parse(JSON.stringify(oldParams));
            Object.keys(params).map(key => {
                oldParams[key] = params[key];
            });
        } else {
            url += "?";
            oldParams = params;
        }
        if (oldParams) {          
            let loop = 1;
            Object.keys(oldParams).map(key => {
                if (loop > 1) {
                    url += "&";
                }
                let value = oldParams[key];
                if(removeHash && value && value.indexOf && value.indexOf('#') != -1){
                  value = '';
                }
                url += key + "=" + (encodeURI(value));
                loop++;
            });
        }
        return url;
    }


    static getJsonFromUrl(url, isWithSegment = false) {
      if (!url) url = window.location.search;
      let segments = [];
      if (url.indexOf('/') != -1) {
        let urlA = url.split('/');
        let urlLen = urlA.length;
        let urlLastIndex = urlLen - 1;
        if (urlLen > 0) {
          urlA.map((segment, index) => {
            if (segment != '') {
              if (index == urlLastIndex) {
                if (segment.indexOf('?') !== -1) {
                  url = segment;
                } else {
                  segments.push(segment);
                  url = '';
                }
              } else {
                segments.push(segment);
              }
            }
          });
        } else {
          url = '';
        }
      }
      let oldParams = {};
      if (url != '') {
        let urlString = url;
        if (url && url.indexOf('?') !== -1) {
          let urlArray = url.split('?');
          segments.push(urlArray[0]);
          if (urlArray[1]) {
            urlString = urlArray[1];
          }
        }
        if (urlString) {
          let temp = urlString.split('&');
          if (temp) {
            for (let i = 0; i < temp.length; i++) {
              let param = temp[i].split('=');
              if (param) {
                oldParams[param[0]] = decodeURIComponent(param[1]);
              }
            }
          }
        }
      }
      return !isWithSegment ? oldParams : {
        segments: segments,
        query: oldParams,
      };
    }

    /**
     * set browser url
     */
    static setBrowserUrl(thisClass, slug, search) {
        if (thisClass && thisClass.props && search) {
            let url = this.addParamsToUrl(slug, search);
            thisClass.props.history.replace(url);
        }
    }

    static setSearchParamsAndBrowserUrl(thisClass, slug, url, search) {
        let urlSearchParams = this.getJsonFromUrl(url);
        search = search ? search : thisClass.state.search;
        search.page = urlSearchParams.page && urlSearchParams.page != undefined ? urlSearchParams.page : 0;
        thisClass.setState({
            search: {
                ...thisClass.state.search,
                ...search
            }
        });
        this.setBrowserUrl(thisClass, slug, search);
    }

    static generateResponseData(thisClass, key, res){
      let items = res.data[key];
      let data = thisClass.state[key].data;
      res.data[key].data.map(item=>{
          data.push(item);
      })
      items.data = data;
      return items;
    }

    static loadNextPageData(thisClass, key, url, functionName){
      if(thisClass.state[key] && thisClass.state[key].last_page > thisClass.state[key].current_page && !thisClass.state.isProcessing){
          let search = thisClass.state.search;
          search.page = thisClass.state[key].current_page + 1;
          let newLink = this.addParamsToUrl(url, search);
          console.log('newLink', key, newLink, thisClass.state[key].last_page, thisClass.state[key].current_page);
          
          functionName(newLink);
      }
    }


    static pagination(thisClass, key, url, functionName, id = 'loadMore'){
      if(thisClass.state[key] && thisClass.state[key].last_page > thisClass.state[key].current_page && !thisClass.state.isProcessing){
          return (
              <div className="text-center">
                  <a
                  id={id}
                  ref={e => thisClass[id] = e}
                  onClick={(e) =>{
                    this.loadNextPageData(thisClass, key, url, functionName);
                  }}
                  className="btn btn-completed">Load More</a>
              </div>
          )
      }
    }

    static fileImageUrl(ext){
      let hasFileImage = [
        'ae',
        'ai',
        'css',
        'file',
        'folder',
        'img',
        'm4a',
        'mp3',
        'ogg',
        'pdf',
        'php',
        'ppt',
        'pptx',
        'psd',
        'rar',
        'txt',
        'mp4',
        'webm',
        'vtt',
        'word',
        'doc',
        'docx',
        'xl',
        'xlsx',
        'xip',
        'zip'
      ];
      let extension = 'file';
      if(hasFileImage.indexOf(ext) != -1){
        extension = ext;
        if(ext == 'pptx'){
          extension = 'ppt';
        } else if(ext == 'xlsx'){
          extension = 'xl';
        } else if(ext == 'doc' || ext == 'docx'){
          extension = 'word';
        }
      }

      return '/file_img_112/'+extension+'_112.png';
    }

    static jsonString(str) {
      try {
          return JSON.parse(str);
      } catch (e) {
          return false;
      }
    }


  static showErrorSweetAlert(err, isStringWant = false){
    let errStr = 'Unexceptional error!';
    if (err.response && err.response.data && (typeof err.response.data.errors === "object" || typeof err.response.data.errors === 'function') && (err.response.data.errors !== null)) {
      errStr ='';
      Object.keys(err.response.data.errors).map((key, index) => {
            if (index > 0) {
                errStr += ', ';
            }
            errStr += err.response.data.errors[key][0];
        })
    } else if(err.response && err.response.data && err.response.data.message != undefined){
      errStr = err.response.data.message;
    } else if(err.response && err.response.data && err.response.data.errors != undefined){
        errStr = err.response.data.errors;
    } else if(err.response && err.response.data && err.response.data != undefined){
      errStr = err.response.data+'';
    } else if(err.response && err.response != undefined){
      errStr = err.response+'';
    } else if(err != undefined){
      errStr = err+'';
    }
    
    if(!isStringWant){
      Swal.fire({
          type: "warning",
          icon: "warning",
          title: "Warning!",
          text: errStr,
      });
    }
    return errStr;
  }

  static stringTruncate(title = "", length ){
        return title.length > length ? title.slice(0,length)+"..." : title;
  }

  static generateAddress(add){
    let fullAddress = '';
    if (add.address1 != '') {
        fullAddress += add.address1;
        fullAddress += ', ';
    }
    if (add.address2 && add.address2 != '') {
        fullAddress += add.address2;
        fullAddress += ', ';
    }
    if (add.city && add.city != '') {
        fullAddress += add.city;
        fullAddress += ', ';
    }
    if (add.state && add.state != '') {
        fullAddress += add.state;
        fullAddress += ', ';
    }
    if (add.country && add.country != '') {
        fullAddress += add.country;
    }
    if (add.zip && add.zip != '') {
        fullAddress += " - " + add.zip;
    }
    return fullAddress;
  }
  
  static _e(key, lan = 'en') {
    let tl = en;
    if (lan !== 'en') {
      switch (lan) {
        // case 'bn':
        //   tl = bn;
        //   break;
        // case 'ps':
        //   tl = ps;
        //   break;
      }
    }
    let string = key + '';
    let tlIndexA = string.split('.');
    let tlData = this.getTranslateData(tl, tlIndexA);
    if (tlData === '' && lan != 'en') {
      tlData = this.getTranslateData(en, tlIndexA);
    }
    return tlData;
  }

  static getTranslateData(tl, tlIndexA) {
    let tlData = '';
    tlIndexA.map((key, index) => {
      if (index === 0) {
        if (tl[key] !== undefined) {
          tlData = tl[key];
        } else {
          tlData = '';
        }
      } else {
        if (tlData[key] !== undefined) {
          tlData = tlData[key];
        } else {
          tlData = '';
        }
      }
    });
    return tlData;
  }

  static numTranslate(num, lan = 'en') {
    let string = num + '';
    let numA = string.split('');
    let tl = '';
    numA.map(key => {
      tl += this._e('num.' + key, lan);
    });
    return tl;
  }
  static browserName(){
    var ua= navigator.userAgent;
    var tem; 
    var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }
  
  static secondToTime(seconds){
      let sec = parseInt(seconds);
      let time = sec < 3600 ? new Date(sec * 1000).toISOString().substring(14, 19) : new Date(sec * 1000).toISOString().substring(11, 16);
      return time;
  }

  static getFlagUrl(countryName){
    if(countryName){
      let slug = countryName.toLowerCase();
      slug = slug.replaceAll(' ', '_');
      return `/flags/${slug}.png`
    }
    return '';
  }

  static dateFormatValidate(name, value) {
    let isValid = false;
    let validate = /^(\d{4})-(\d{2})-(\d{2})$/;
    let tr = validate.test(value);    
    if (tr) {
      return false;
    } else {
      return 'The ' + name + ' invalid date format. Format: YYYY-MM-DD';
    }
  }
}
