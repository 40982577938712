import React from 'react';
import Cookies from "universal-cookie";
import * as moment from 'moment';
const fat = ['mrks_fat', 'mrks_fyk'];
const frt = ['mrks_fat2', 'mrks_fyk2'];
const fexp = 'mrks_fexp';

export function storeInfo(payload) {
    storeToken(payload.accessToken, fat);
    // console.log('storeInfo remember',payload.remember, payload);
    if(payload.remember){
        storeToken(payload.refreshToken, frt);
        // console.log('storeInfo remembered');
    } else {
        removeToken(frt);
    }

    let now = parseInt(moment().format('X'));
    let exp = parseInt(payload.expiresIn);
    /**
     * set expire time in second less 40 second
     * @type {number}
     */
    let storeExp = now + exp - 60;
    const cookies = new Cookies();
    cookies.set(fexp, storeExp, {path: '/'});
}

export function getStoreInfo() {
    let ac = getToken(fat);
    let rt = getToken(frt);
    const cookies = new Cookies();
    let exp = cookies.get(fexp);
    return {
        ac,
        rt,
        exp
    }
}

export function removeStoreInfo() {
    removeToken(fat);
    const cookies = new Cookies();
    cookies.remove(fexp, {path: '/'});
}
export function removeRefreshToken() {
    removeToken(frt);
}

function storeToken(token, keys = fat) {
    const tt = processTT(token);
    const ttLen = tt.length;
    const tt1 = tt.substr(0, 500);
    const tt2Len = ttLen - 500;
    const tt2 = tt.substr(500, tt2Len);

    localStorage.setItem(keys[0], tt1);
    const cookies = new Cookies();
    cookies.set(keys[1], tt2, {path: '/'});
}

export function getToken(keys = fat) {
    const tt1 = localStorage.getItem(keys[0]);
    const cookies = new Cookies();
    const tt2 = cookies.get(keys[1]);
    const tt = processRTT(tt1 + "" + tt2);
    return tt;
}

export function removeToken(keys = fat) {
    localStorage.removeItem(keys[0]);
    const cookies = new Cookies();
    cookies.remove(keys[1], {path: '/'});
}

function processTT(tt) {
    let ttLen = tt.length;
    let genTT = '';
    for (let i = 0; i < ttLen; i = i + 100) {
        let ntt = tt.substr(i, 100);
        if (ntt.length === 100) {
            genTT += ntt + "tt";
        } else {

            genTT += ntt;
        }
    }

    return genTT;
}

function processRTT(tt) {
    let ttLen = tt.length;
    let genTT = '';
    for (let i = 0; i < ttLen; i = i + 102) {
        let ntt = tt.substr(i, 100);
        if (ntt.length === 4) {
            genTT += ntt.substr(0, 102);
        } else {
            genTT += ntt;
        }
    }
    return genTT;
}
