import React from 'react';
import Cookies from 'universal-cookie';
import {Helper} from "../../../utils/Helper";
import Http from '../../../utils/Http';
import {storeInfo, getStoreInfo, getToken, removeStoreInfo, removeRefreshToken} from '../../../utils/TokenInfo';
import {
    AUTH_CHECK,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_REFRESH_TOKEN,
    AUTH_RESET_PASSWORD, AUTH_USER,
} from './action-types';

const initialState = {
  isAuthenticated: true,
  user: {
    email: 'engr.mrksohag@gmail.com'
  }
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch(type) {
    case AUTH_CHECK:
      return checkAuth(state);
    case AUTH_USER:
          return authUser(state, payload);
    case AUTH_REFRESH_TOKEN:
    case AUTH_LOGIN:
      return login(state, payload);
    case AUTH_LOGOUT:
      return logout(state);
    case AUTH_RESET_PASSWORD:
      return resetPassword(state);
    default:
      return state;
  }
};
function checkAuth(state) {
    let act = getToken();
    state = Object.assign({}, state, {
        isAuthenticated: act !== undefined && act !== 'nullundefined' && act !== ''
    });
    if (state.isAuthenticated) {
        Http.defaults.headers.common['Authorization'] = `Bearer ${act}`;
    }
    return state;
}


function authUser(state, payload) {
    return {
        ...state,
        user: payload
    }
}

function login(state, payload) {
    storeInfo(payload);
    Http.defaults.headers.common['Authorization'] = `Bearer ${payload.accessToken}`;

    return {
        ...state, isAuthenticated: true,
    }
}

function logout(state) {
    removeStoreInfo();
    removeRefreshToken();
    return {
        ...state, isAuthenticated: false, user: null
    }
}

function resetPassword(state) {
    return {
        ...state, resetPassword: true,
    }
}

export const getAuth = state => state.auth.isAuthenticated;
export default reducer;
