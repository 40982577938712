import React from 'react';

export const ErrorComponent = (props) =>{
    return (
        <div id="master-container" className="main-container container">
            <div className="row">
                <div id="content" className="col-sm-12">

                    <div className="about-us about-demo-1 text-center">
                        <h2>404!</h2>
                        <p>Page Not Found, Please browse by selecting category!</p>
                    </div>
                </div>
            </div>
        </div>
    )
};