import React from 'react';
import {
    SETTINGS,
    FLASH,
    ONLINE_USERS,
    ADD_ONLINE_USER,
    REMOVE_ONLINE_USER,
    UPDATE_SITE_LANGUAGE
} from "./action-types";

const initialState = {
    settings: {},
    flash: {
        error: "",
        success: ""
    },
    onlineUsers: [],
    siteLanguage: {
        title: "English",
        code: "en",
    }
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case SETTINGS:
            return updateSettings(state, payload);
        case FLASH:
            return updateFlash(state, payload);
        case ONLINE_USERS:
            return onlineUsers(state, payload);
        case ADD_ONLINE_USER:
            return addOnlineUser(state, payload);
        case REMOVE_ONLINE_USER:
            return removeOnlineUser(state, payload);
        case UPDATE_SITE_LANGUAGE:
            return updateSiteLanguage(state, payload);
        default:
            return state;
    }
};

function updateSettings(state, payload) {
    return {
        ...state,
        settings: payload
    }
}

function updateFlash(state, payload) {
    return {
        ...state,
        flash: {
            ...state.flash,
            payload
        }
    }
}

function onlineUsers(state, payload) {
    return {
        ...state,
        onlineUsers: payload
    }
};

function addOnlineUser(state, payload) {
    let onlineUsers = JSON.parse(JSON.stringify(state.onlineUsers));
    if (onlineUsers.indexOf(payload) == -1) {
        onlineUsers.push(payload)
    }
    return {
        ...state,
        onlineUsers: onlineUsers
    }
};

function removeOnlineUser(state, payload) {
    let onlineUsers = JSON.parse(JSON.stringify(state.onlineUsers));
    let index = onlineUsers.indexOf(payload);
    if (index != -1) {
        onlineUsers.splice(index, 1)
    }
    return {
        ...state,
        onlineUsers: onlineUsers
    }
};

function updateSiteLanguage(state, payload) {
    return {
        ...state,
        siteLanguage: {...payload }
    }
};

export default reducer;
