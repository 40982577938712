/* eslint-disable no-console */
import {Helper} from "./Helper";
import axios from 'axios';
import store from '../store';
import {authLogout} from '../modules/auth/store/actions';
import {removeRefreshToken} from "./TokenInfo";

/**
 * set axios header
 */
// axios.defaults.baseURL = Helper.getConstant("API_URL"); // this set on layout render
axios.defaults.headers.common.Accept = 'application/json';

axios.interceptors.request.use(async config =>{
    // Helper.checkRefreshToken();

    return config;
});
axios.interceptors.response.use(response => {
    if(response.data.seoMetaInfo){
        Helper.setMeta(response.data.seoMetaInfo, response.data.seoHtml)
    } else if(response.data.data && response.data.data.seoMetaInfo){
        Helper.setMeta(response.data.data.seoMetaInfo, response.data.data.seoHtml)
    }
    return Promise.resolve(response);
}, (error)=>{
    if(error.response && error.response.data){
        let message = error.response.data.message;
        if (error.response.status === 401) {
            if(message === "Unauthenticated."){
                console.log("unauth");
                store.dispatch(authLogout());
            } else if (message === "The refresh token is invalid.") {
                removeRefreshToken();
            }
        }
        if (error.response.status === 403) {
            store.dispatch(authLogout());
            removeRefreshToken();
        }
    }
    return Promise.reject(error);
});

export default axios;
