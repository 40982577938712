import React, { Component } from 'react';

class Dashboard extends Component {

  render() {
    return (
      <div>
        <h1>Customer Dashboard</h1>
      </div>
    );
  }
}

export default Dashboard;