import React from 'react';
import { Constant } from "./Constant";
import * as moment from "moment";
import _ from "lodash";
import {CommonHelper} from "../../Common/CommonHelper";
import $ from "jquery";
import store from '../store';

export class Helper extends CommonHelper{
    static formatTitle(title) {
        return title + ' - ' + this.getConstant("APP_NAME");
    }

    static setTitle(title) {
        if (title === undefined) {
            document.title = this.getConstant("APP_NAME");
        } else {
            document.title = this.formatTitle(title);
        }
    }

    static url(segment){
        return this.getConstant("APP_URL")+'/'+segment;
    }

    static setMeta(meta, html = '') {
        /**
         * Schema.org markup for Google+
         */
        $("meta[itemprop=name]").attr("content", meta.title);
        $("meta[name=keywords]").attr("content", meta.meta_keywords);
        $("meta[itemprop=description]").attr("content", meta.meta_description);
        $("meta[itemprop=image]").attr("content", meta.image);

        /**
         * Twitter Card data
         */
        $("meta[name='twitter:title']").attr("content", meta.title);
        $("meta[name='twitter:url']").attr("content", meta.url);
        $("meta[name='twitter:description']").attr("content", meta.meta_description);
        $("meta[name='twitter:image']").attr("content", meta.image);

        /**
         * Open Graph data
         */
        $("meta[property='og:type']").attr("content", meta.site_name);
        $("meta[property='og:url']").attr("content", meta.url);
        $("meta[property='og:title']").attr("content", meta.title);
        $("meta[property='og:description']").attr("content", meta.meta_description);
        $("meta[property='og:image']").attr("content", meta.image);

        /**
         * json + ld
         */
        if(meta.jsonLd && meta.jsonLd.length > 0){
            meta.jsonLd.map((jsonLd, index) => {
                if(index > 0){
                    let ltSelector = $("#jsonLd"+index);
                    if(ltSelector.length > 0){
                        ltSelector.text(JSON.stringify(jsonLd));
                    } else {
                        $('head').append('<script type="application/ld+json" id="jsonLd'+index+'">'+JSON.stringify(jsonLd)+'</script>')
                    }
                }
            })
        }
    }

    static getConstant(constantName) {
        let hostname = window.location.hostname;
        let constantValue = '';
        let con = Constant.HOSTS[hostname];
        if (con && con[constantName]) {
            constantValue = con[constantName];
        } else if (Constant[constantName]) {
            constantValue = Constant[constantName];
        }

        return constantValue;
    }

    static errorCodes(errorIndex) {
        let errorCodes = [
            100001
        ];
        if (errorCodes[errorIndex]) {
            return errorCodes[errorIndex];
        }
        return 100404;
    }
    /**
     * generate image src by width and height
     * @param image
     * @return {string}
     */
    static getImageSrc(image) {
        if(image) {
            let url = this.getConstant("ASSETS_URL");
            let host = this.getConstant("HOST");
            if(store.getState() && store.getState().web.settings.fs ==="cloud"){
                return this.getCdnUrlFromUrl(store.getState().web.settings.cdn, image);
            } else {
                let rep = image.indexOf('/storage') === -1 ? '/storage' : '';
                if(image.indexOf('/uploads') != -1 && image.indexOf('/storage/uploads') == -1 && image.indexOf('/public') == -1){
                    image = rep + image;
                } else if(image.indexOf('/public') != -1){
                    image = image.replace('/public', rep);
                }
            }
            if (host == "yk.ca" || host == "yk.com" || host == "192.168.0.100") {
                url = url.replaceAll(host, 'pashtya.com');
            }


            if(image.indexOf('/www') != -1){
                image = image.replace('/www', '');
            }

            return image.indexOf('http') === -1 ? url + image : image;
        }
    }

    /**
     * generate image src by width and height
     * @param image
     * @param width
     * @param height
     * @return {string}
     */
    static generateImageSrc(image, width = null, height = null) {
        if(image) {
            if (width && height) {
                let filename = image.substring(0, image.lastIndexOf('.'));
                let extension = image.substring(image.lastIndexOf('.'));
                return this.getImageSrc(filename + "_" + width + "x" + height + extension);
            } else {
                return this.getImageSrc(image);
            }
        }
    }

    static generateFlagSrc(ship_to) {
        if(ship_to) {
            return "/flags/compressed/" + Helper.replaceAll(' ', '_', ship_to.toLowerCase()) + ".png";
        }
    }

    static dateFormatForFrontEnd(date) {
        return moment(date, "YYYY-MM-DD HH:mm:SS").format('HH:mm DD-MM-YYYY');
    }

    static dateFormatForFrontEnd2(date) {
        return moment(date, "YYYY-MM-DD HH:mm:SS").format('DD-MM-YYYY');
    }
    static dateFormatForFrontEnd3(date) {
        return moment(date, "YYYY-MM-DD HH:mm:SS").format('YYYY-MM-DD');
    }
    static dateFormatForFrontEnd4(date) {
        return moment(date, "YYYY-MM-DD HH:mm:SS").format('DD MMM YYYY HH:mm');
    }
    static dateFormatForFrontEnd5(date) {
        return moment(date, "YYYY-MM-DD HH:mm:SS").format('DD/MM/YYYY, HH:mm');
    }
    static dateFormatForFrontEnd6(date) {
        return moment(date, "YYYY-MM-DD").format('DD-MM-YYYY');
    }
    static dateFormatForFrontEnd7(date) {
        return moment(date, "YYYY-MM-DD").format('MMM DD, YYYY');
    }
    static dateFormatForFrontEnd8(date) {
        return moment(date, "YYYY-MM-DD").format('DD/MM/YYYY');
    }

    static agoCalculate(msqlDateTime) {
        // let now = moment();
        // let dateInfo = moment(msqlDateTime, "YYYY-MM-DD HH:mm:ss");
        //
        // let year = now.diff(dateInfo, 'years');
        // if(year>1){
        //     return year+this.createSuffix(year, "Year Ago", "Years  Ago");
        // }
        // let months = now.diff(dateInfo, 'months');
        // if(months>1){
        //     return months+this.createSuffix(months, "Month Ago", "Month  Ago");
        // }
        // let weeks = now.diff(dateInfo, 'weeks');
        // if(weeks>1){
        //     return weeks+this.createSuffix(weeks, "Week Ago", "Weeks  Ago");
        // }
        // let days = now.diff(dateInfo, 'days');
        // if(days>1){
        //     return days+this.createSuffix(days, "Day Ago", "Days  Ago");
        // }
        // let hours = now.diff(dateInfo, 'hours');
        // if(hours>1){
        //     return hours+this.createSuffix(hours, "Hour Ago", "Hours  Ago");
        // }
        // let minutes = now.diff(dateInfo, 'minutes');
        // if(minutes>1){
        //     return minutes+this.createSuffix(minutes, "Minute Ago", "Minutes Ago");
        // } else {
        //     return "Less then a Minute Ago";
        // }

        let t = msqlDateTime.indexOf('T') != -1 ?  msqlDateTime.split(/[-T:]/) : msqlDateTime.split(/[- :]/);
        let t5 = t[5];
        if(t5.indexOf('Z') != -1){
            t5 = t5.replace('.000000Z', '')
        }
        let d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t5));
        // console.log('agoCalculate 1', msqlDateTime);
        // console.log('agoCalculate 2', d);
        // console.log('agoCalculate 3', new Date());
        // console.log('\n\n');
        let seconds = Math.floor((new Date() - d) / 1000);

        var interval = Math.floor(seconds / 31536000);
        if (interval > 1) {
            return interval + this.createSuffix(interval, "Year Ago", "Years  Ago");
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + this.createSuffix(interval, "Month Ago", "Month  Ago");
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + this.createSuffix(interval, "Day Ago", "Days  Ago");
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + this.createSuffix(interval, "Hour Ago", "Hours  Ago");
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + this.createSuffix(interval, "Minute Ago", "Minutes Ago");
        }
        interval = Math.floor(seconds);
        return interval + this.createSuffix(interval, "Second Ago", "Seconds Ago");
    }

    static createSuffix(value, suffixSingular, suffixPlural) {
        let valueF = parseFloat(value);
        let suffixFormat = " " + suffixSingular;
        if (valueF > 1) {
            suffixFormat = " " + suffixPlural;
        }
        return suffixFormat;
    }


    static getArryKeys(arr, key) {
        let newArray = [];
        if (arr.length) {
            arr.map((item, index) => {
                if (newArray.indexOf(item[key]) === -1) {
                    newArray.push(item[key]);
                }
            });
        }
        return newArray;
    }

    /**
     *  Helper.getIpInfo((res)=>{
            console.log(res);
        });
     * @param callback
     */
    static getIpInfo(callback) {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                function success(position) {
                    if (position && position.coords) {
                        console.log("position", position);
                        Helper.getAddress(position.coords.latitude,
                            position.coords.longitude, callback)
                    }
                },
                function error(error_message) {
                    // for when getting location results in an error
                    console.error('An error has occured while retrieving location', error_message);

                },
                { enableHighAccuracy: true, maximumAge: 600000, timeout: 10000 }
            );
        } else {
            alert('Please update your browser, geolocation is not supported. We recommend to use Google Chrome latest version.')
        }
    }


    static getAddress(latitude, longitude, callback) {
        let key = Helper.getConstant('GOOGLE_API_KEY');
        $.ajax('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&key=' + key)
            .then(
                function success(response) {
                    // console.log('User\'s Address Data is ', response)
                    callback(response);
                },
                function fail(status) {
                    // console.log('Request failed.  Returned status of',status)
                }
            )
    }

    static isSet(data) {
        return data != undefined && data != '' ? data : "";
    }

    static loader(isLoading) {
        if (isLoading) {
            // $('html, body').css({ 'overflow': 'hidden' });
            return (
                <div className="mrks-loader-wrapper" key={"processing-product"}>
                    <img src="/images/lazy-loader.gif" />
                </div>
            )
        } else {
            // $('html, body').css({ 'overflow': '' });
        }
    }

    static appendZero(number){
        number += "";
        return number.length == 1 ? "0" + number + "" : number;
    }

    static generateCharter(word, type) {
        word = this.appendZero(word);
        let wordArray = [];
        for (let i = 0; i < word.length; i++) {
            wordArray.push(<span className="mrks-letter" key={type + "-" + i}>{word[i]}</span>)
        }
        return wordArray;
    }

    static flashCounter(thisClass, countDownDate){
        let now = new Date().getTime();
        if(!isNaN(countDownDate)) {
            let distance = countDownDate - now;
            // console.log('flashCounter', now, countDownDate, distance);
            if (distance > 0) {
                var x = setInterval(function () {

                    // Get today's date and time
                    var now = new Date().getTime();

                    // Find the distance between now and the count down date
                    var distance = countDownDate - now;

                    // Time calculations for days, hours, minutes and seconds
                    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    let flashText = (<span>
                        <span className="mrks-word">{Helper.generateCharter(days, 'd')}</span>
                        <span className="mrks-word-separtor">&nbsp;</span>
                        <span className="mrks-word">{Helper.generateCharter(hours, 'h')}</span>
                        <span className="mrks-word-separtor">:</span>
                        <span className="mrks-word">{Helper.generateCharter(minutes, 'm')}</span>
                        <span className="mrks-word-separtor">:</span>
                        <span className="mrks-word">{Helper.generateCharter(seconds, 's')}</span>
                    </span>);
                    thisClass.setState({
                        flashText:flashText
                    })
                }, 1000);
            }
        }
    }

    static arrayPluck(arr, key){
        let na = arr.map(item => item[key]);
        return [...new Set(na)].sort((a,b)=>a-b);
    }

    static carouseJs(){
        $('.yakoun-top-sale-product').each(function () {
            var $slider = $(this),
                $panels = $slider.children('div'),
                data = $slider.data();
            $slider.owlCarousel2({
                responsiveClass: true,
                mouseDrag: true,
                video: true,

                lazyLoad: (data.lazyload == 'yes') ? true : false,
                autoplay: (data.autoplay == 'yes') ? true : false,
                autoHeight: (data.autoheight == 'yes') ? true : false,
                autoplayTimeout: data.delay * 1000,
                smartSpeed: data.speed * 1000,
                autoplayHoverPause: (data.hoverpause == 'yes') ? true : false,
                center: (data.center == 'yes') ? true : false,
                loop: (data.loop == 'yes') ? true : false,
                dots: (data.pagination == 'yes') ? true : false,
                nav: (data.arrows == 'yes') ? true : false,
                dotClass: "owl-dot",
                dotsClass: "owl-dots",
                margin: data.margin,
                navText: ['', ''],

                responsive: {
                    0: {
                        items: data.items_column4
                    },
                    480: {
                        items: data.items_column3
                    },
                    768: {
                        items: data.items_column2
                    },
                    992: {
                        items: data.items_column1
                    },
                    1200: {
                        items: data.items_column0
                    },
                    1300: {
                        items: 6
                    },
                    1600: {
                        items: 7
                    }
                }
            });

        });
    }

    static showPriceWithCurrency(currencyCode='USD', price, showCurrencyCode = false) {
        let cInfo = Helper.getCurrencyInfoByCode(currencyCode);
        let currency = cInfo.symbol_native;
        if(showCurrencyCode){
            currency = currencyCode + " "+currency;
        }
        if (price) {
            price = parseFloat(price);
            return currency+ price.toFixed(2);
        } else {
            return currency + "0";
        }
    }

    static imagesTypes = [
        "jpg",
        "jpeg",
        "png",
        "gif"
    ];

    static getValidFileFormatForDropzone(validation) {
        // console.log("validation", validation);
        let validFileFormat = false;
        if (validation == "image") {
            validFileFormat = "";
            this.imagesTypes.map((type, i) => {
                if (i > 0) {
                    validFileFormat += ","
                }
                validFileFormat += "." + type;
            });
        } else if (validation) {
            validFileFormat = "." + validation;
        }
        return validFileFormat;
    }

    static isShippingIdFlexShipping(shippingId)
    {
        return shippingId > 6 && shippingId < 13;
    }

    static ratingStars(rtn, key = Math.random(), style=1){
        let rating = parseInt(rtn);
        let nonRating = 5 - rating;
        let ratingJsx = [];
        if(style == 2){
            ratingJsx.push(<i key={key+'-rtn-'} className={'fa fa-star'} />)
            ratingJsx.push(<span key={key+'-rtn-'} className={'star-text'}>{rtn}</span>)
        } else {
            for(let i = 1; i <= rating; i++){
                ratingJsx.push(<i key={key+'-rtn-'+i} className={'fa fa-star'} />)
            }
            for(let i = 1; i <= nonRating; i++){
                ratingJsx.push(<i key={key+'-nonrtn-'+i} className={'far fa-star'} />)
            }
        }
        return (ratingJsx);
    }

    /**
     * Convert thousand to k
     * @param  {number} number Number to convert
     * @return {string}  Converted to k formate
    */
    static formatCash(number) {
        if (number < 1e3) return number;
        if (number >= 1e3) return +(number / 1e3).toFixed(1) + "K";
    }

    static scrollToId(id){
        const element = document.getElementById(id);
        if (element)
            element.scrollIntoView({ behavior: "smooth" });

    }
}
