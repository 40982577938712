import React from 'react';
import Cookies from "universal-cookie";

export class Storage {
    static prefix = "mrks_";
    static storageType = "ls";

    static getData(key, type = this.storageType) {
        key = this.prefix + key;
        if (type == "ls") {
            return localStorage.getItem(key);
        } else if (type == "cookie") {
            const cookies = new Cookies();
            return cookies.get(key);
        } else {
            return false;
        }
    }

    static setData(key, value, type = this.storageType) {
        key = this.prefix + key;
        if (type == "ls") {
            localStorage.setItem(key, value);
        } else if (type == "cookie") {
            const cookies = new Cookies();
            cookies.set(key, value, {path: '/'});
        } else {
            return false;
        }
    }

    static removeData(key, type = this.storageType) {
        key = this.prefix + key;
        if (type == "ls") {
            localStorage.removeItem(key);
        } else if (type == "cookie") {
            const cookies = new Cookies();
            cookies.remove(key, {path: '/'});
        } else {
            return false;
        }
    }
}
