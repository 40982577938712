import React from 'react';
import LoadingComponent from "../../elements/loader";
import Dashboard from './dashboard';

const routes = [{
        path: "/customer",
        auth: true,
        exact: true,
        title: "Customer Dashboard",
        // element: <Dashboard/>
        // element: Loadable({
        //     loader: () => import ("../customer/dashboard"),
        //     loading: LoadingComponent
        // })
        // lazy: () => import ("../customer/dashboard")
        // lazy: () => "../customer/dashboard"
        // lazy: () => "../modules/customer/dashboard"
        element: React.lazy(() => import ("../customer/dashboard"))
    },
];

export default routes;
