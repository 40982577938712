let conf = {
    APP_NAME: 'MRKSohag',
    HOSTS: {},
};
let hosts = ["mrksohag.com", "localhost:3000", "localhost:3001"];
let hostProtocol = window.location.protocol !== "" ? window.location.protocol : "http:";

hosts.map((host, index) => {
    let hostName = "";
    if (typeof host == "object") {
        hostName = host.host;
    } else {
        hostName = host;
    }
    conf.HOSTS[host] = {
        HOST: hostName,
        APP_URL: hostProtocol + '//' + hostName,
        API_URL: hostProtocol + '//' + hostName + '/api/',
        ASSETS_URL: hostProtocol + '//' + hostName
    }
});
export const Constant = conf;
