import {getStoreInfo} from "./TokenInfo";
import * as moment from "moment";
import {refreshToken} from "../modules/auth/service";

/**
 * Check refresh token
 * @type {boolean}
 */
var checkRefreshTokenInterval;
var isCheckRefreshTokenRunning = false;
export class DbHelper {

    /**
     * check refresh token
     * @param store
     */
    static checkRefreshToken(dispatch) {
        let sInfo = getStoreInfo();
        // console.log("checkRefreshToken ", sInfo);
        if (sInfo.rt !== undefined && sInfo.rt !== 'nullundefined' && sInfo.rt !== '') {
            // console.log("checkRefreshToken defined");
            // clearInterval(checkRefreshTokenInterval);
            let now = parseInt(moment().format('X'));
            let exp = parseInt(sInfo.exp);
            // console.log("checkRefreshToken is expired", now > exp, now, exp);
            if (now > exp || isNaN(exp)) {
                // console.log("checkRefreshToken expired and new request process");
                isCheckRefreshTokenRunning = false;
                dispatch(refreshToken(sInfo.rt));
                setTimeout(() => {
                    this.checkRefreshToken(dispatch)
                }, 60 * 1000);
            } else if (!isCheckRefreshTokenRunning) {
                isCheckRefreshTokenRunning = true;
                let timeRemaining = (exp - now) * 1000 + 1000;
                // console.log("checkRefreshToken time set to " + timeRemaining);
                setTimeout(() => {
                    this.checkRefreshToken(dispatch)
                }, timeRemaining);
            }
        } else {
            // let thisClass = this;
            // checkRefreshTokenInterval = setInterval(function () {
            //     console.log("\n\ncheckRefreshTokenInterval");
            //     thisClass.checkRefreshToken(dispatch);
            // }, 60 * 1000);
        }
    }
}
