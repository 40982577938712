
import React from 'react';
import LoadingComponent from "../../elements/loader";
import Home from './home';

const routes = [{
        path: "/",
        exact: true,
        title: "Home",
        // element: <Home/>  
        // element: Loadable({
        //     loader: () => import ("../web/home"),
        //     loading: LoadingComponent
        // })        
        element: React.lazy(() => import ("../web/home"))
    },
];

export default routes;
