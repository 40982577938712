import React from 'react';

const LoadingComponent = (props) => {
    if (props.error) {
        return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
      } else if (props.pastDelay) {
        return <div>Loading...</div>;
      } else {
        return null;
      }
    // if (isLoading) {
    //     return (
    //         <div id="modelLoader" className="loader15">
    //             <span></span><span></span><span></span><span></span>
    //         </div>
    //     )
    // }
    // else if (error) {
    //     console.log("error", error);
    //     return <div id="master-container" className="main-container container">
    //         <a onClick={(e) => { e.preventDefault(); window.location.reload(true) }}>Please reload the page by click here, there was an error to load the page!</a>
    //     </div>
    // }
    // else {
    //     return null;
    // }
};

export default LoadingComponent;
