import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';

/**
 * Import Local lib
 */
import {DbHelper} from './front/utils/DbHelper';
import Routes from "./front/routes";
import store from './front/store';
import {authCheck} from "./front/modules/auth/store/actions";
/**
 * dispatch store
 */
// store.dispatch(authCheck());
// DbHelper.checkRefreshToken(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <Routes/>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
