import React, {useState} from 'react';
import { BrowserRouter as Router, Routes as Switch, Route, Navigate, useLocation } from 'react-router-dom';
import {connect} from 'react-redux';
import {Helper} from "../utils/Helper";
import { createBrowserHistory } from 'history';

import LoadingComponent from "../elements/loader";

import routes from './routes';
import {ErrorComponent} from "../modules/web/error";

const history = createBrowserHistory();
// const Layout = Loadable({
//     loader: () => import("../layouts/Layout"),
//     loading: LoadingComponent
// });
const Layout = React.lazy( () => import("../layouts/Layout"));

function RequireAuth({ children , isAuthenticated, user}) {
    const location = useLocation();
  
    if (!isAuthenticated || user == null) {        
      return <Navigate to="/" state={{ from: location }} />;
    }  
    return children;
}

const Routes = (props) => {    
    // console.log('Routes prop', routes);
    
    const [socket, setSocket] = useState(null);
    const [peer, setPeer] = useState(null);
    return (
        <Router history={history}>
            <Layout  
                setSocket = { (socket) => {
                    setSocket(socket);         
                }}
                setPeer = { (peer) => {
                    setPeer(peer);         
                }}>
                <Switch>
                    {routes.map((route, i) => {
                        if (route.auth){
                            return (
                                <Route 
                                    key={i} 
                                    path={route.path} 
                                    exact={route.exact} 
                                    title={route.title} 
                                    socket={socket} 
                                    peer={peer}
                                    element={(
                                        <RequireAuth isAuthenticated={props.isAuthenticated} user={props.user}>
                                            <React.Suspense fallback={<LoadingComponent/>}>
                                                <route.element />
                                            </React.Suspense>
                                        </RequireAuth>
                                    )}
                                    />
                            )
                        }
                        return <Route 
                                    key={i} 
                                    path={route.path} 
                                    exact={route.exact} 
                                    title={route.title} 
                                    element={
                                        <React.Suspense fallback={<LoadingComponent/>}>
                                            <route.element />
                                        </React.Suspense>
                                    }
                                    socket={socket} 
                                    peer={peer}
                                    />
                    })}
                    <Route component={ErrorComponent} socket={socket} peer={peer}/>
                </Switch>
            </Layout>
        </Router>
    );
}

function mapStateToProps(store) {    
    return {
        isAuthenticated: store.auth.isAuthenticated,
        user: store.auth.user,
    }
}

export default connect(mapStateToProps)(Routes);
